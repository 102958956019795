import {css}                       from "styled-components";
import {defaultPrimaryButtonStyle} from "./DefaultPrimaryButtonStyle";


export const defaultPrimaryButtonIconLeftStyles = css`
    ${defaultPrimaryButtonStyle};
    text-align: right;
    padding-left: 56px;
    background-position: 12px 50%;
    background-repeat: no-repeat;
`;
