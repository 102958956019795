import {FunctionComponent}     from "react";
import React                   from "react";
import {Link}                  from "react-router-dom";
import styled                  from "styled-components";
import {DefaultPageBackButton} from "../../styles/DefaultPageBackButton";


const Container = styled.div<{desktopHide?: number}>`
    max-width: 100vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 48px;
    height: ${props => props.theme.layout_part_size.mobile.page_header};
    position: relative;
    background-color: ${props => props.theme.colors.white};

    &.with-shadow {
        box-shadow: 0 4px 20px rgba(42, 183, 248, 0.15);
    }

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        position: fixed;
        top: 0;
        height: ${props => props.theme.layout_part_size.tablet.page_header};
        width: calc(100vw - ${({theme}) => theme.layout_part_size.tablet.menu});
        left: ${({theme}) => theme.layout_part_size.tablet.menu};
        align-items: flex-start;
        z-index: 10;
        padding: 4px 24px 4px 56px;
        box-shadow: 0 -4px 24px -8px rgb(42, 183, 248, 0.54);
        display: ${props => props.desktopHide ? 'none' : 'flex'};
    }

    @media screen and (min-width: ${({theme}) => theme.breakpoints.laptop_hd}) {
        width: calc(100vw - ${({theme}) => theme.layout_part_size.laptop_hd.menu});
        left: ${({theme}) => theme.layout_part_size.laptop_hd.menu};
    }
`;

const PageTitle = styled.h1`
    font-size: ${props => props.theme.fonts.mobile.xl};
    font-weight: ${props => props.theme.weights.semiBold};
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: ${props => props.theme.fonts.tablet.lg};
    }
`;

const BackButton = styled(Link)`
    ${DefaultPageBackButton};
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        left: 48px;
    }
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        top: 50%;
        transform: translateY(-50%);
        left: 24px;
    }
`;

const Step = styled.div`
    margin-top: 24px;
    font-size: ${props => props.theme.fonts.mobile.md};
    font-weight: ${props => props.theme.weights.semiBold};
`;

interface Props {
  backUrl?: string;
  step?: string;
  shadow?: boolean;
  desktopHide?: number;
}

const PageHeader: FunctionComponent<Props> = ({children, backUrl, shadow, step, desktopHide}) => {
  return (
    <Container className={shadow ? "with-shadow" : ""} desktopHide={desktopHide}>
      {
        backUrl && (<BackButton to={backUrl}/>)
      }
      <PageTitle>{children}</PageTitle>
      {
        step && (<Step>{step}</Step>)
      }
    </Container>
  );
};

export default PageHeader;
