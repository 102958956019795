import React from 'react';
import {useLocation, matchRoutes} from 'react-router';
import styled from 'styled-components';
import {useAppSelector} from '../../hooks/useAppSelector';
import {ROUTE_REGISTER, ROUTE_LOGIN, ROUTE_HOME} from '../../routing/routes';
import Hamburger from '../hamburger/Hamburger';
import Logo from './Logo';

import HistoryIcon from '../../assets/icons/hisotry.svg';
import {useAppDispatch} from '../../hooks/useAppDispatch';
import {showHistoryModal} from '../../store/ModalHistoryReducer';


const Container = styled.div`
    width: 100vw;
    height: ${props => props.theme.layout_part_size.mobile.navbar};
    background-color: ${props => props.theme.colors.secondary};
    padding: 2px 18px;
    display: flex;
    align-items: center;
    color: #fff;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    visibility: visible;
    transition: .3s all ease;

    &.active {
        transform: translateX(${({theme}) => theme.layout_part_size.mobile.menu});
    }

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        &.active {
            transform: translateX(${({theme}) => theme.layout_part_size.tablet_small.menu});
        }
    }
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: ${({theme}) => theme.layout_part_size.tablet.menu};
        height: ${props => props.theme.layout_part_size.tablet.navbar};
    }
    @media screen and (min-width: ${({theme}) => theme.breakpoints.laptop_hd}) {
        width: ${({theme}) => theme.layout_part_size.laptop_hd.menu};
    }
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const HistoryButton = styled.div`
    width: 40px;
    height: 24px;
    text-align: right;

    img {
        width: 24px;
        height: 24px;
        object-fit: contain;
    }

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        display: none;
    }
`;

const Navbar = () => {
  const location = useLocation();
  const matchLogin = matchRoutes([{path: ROUTE_LOGIN}, {path: ROUTE_REGISTER}], location.pathname);
  const status = useAppSelector(state => state.menu.status);
  const dispatch = useAppDispatch();

  return (
    <Container className={status ? 'active' : ''}>
      {!matchLogin && <Hamburger/>}
      <Wrapper>
        <Logo to={ROUTE_HOME}/>
      </Wrapper>
      {!matchLogin && (
        <HistoryButton onClick={() => dispatch(showHistoryModal())}>
          <img src={HistoryIcon} alt=""/>
        </HistoryButton>
      )}
    </Container>
  );
};

export default Navbar;
