import {useState}             from "react";
import {useEffect}            from "react";
import React                  from "react";
import styled                 from "styled-components";
import ButtonPrimary          from "../buttons/ButtonPrimary";
import ButtonSecondary        from "../buttons/ButtonSecondary";
import Header                 from "../typography/Header";
import Paragraph              from "../typography/Paragraph";
import {defaultInfoHeader}    from "./defaultStyles";
import {defaultInfoWrapper}   from "./defaultStyles";
import {defaultInfoContainer} from "./defaultStyles";


const InfoContainer = styled.div`
    ${defaultInfoContainer};
    @media screen and (min-width: 860px) {
        display: none;
    }
`;

const InfoWrapper = styled.div`
    ${defaultInfoWrapper};
`;

const StyledHeaderInfo = styled(Header)<{ center?: boolean }>`
    ${defaultInfoHeader};
    line-height: 1.4;
    max-width: 200px;
    margin-bottom: 12px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;

    button {
        margin: 0 4px;
        min-width: 120px;
    }
`;


const InfoAddAppToHomeScreen = () => {
  const [appInstallable, setInstallable] = useState(true);
  let deferredPrompt: any;

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      setInstallable(true);
      return false;
    });

    window.addEventListener("appinstalled", () => {
      // Log install to analytics
      console.log("INSTALL: Success");
    });
  }, []);


  const handleInstallClick = (e: () => void) => {
    // Hide the app provided install promotion
    setInstallable(false);
    // Show the install prompt
    deferredPrompt.prompt();

    // Wait for the user to respond to the p rompt
    deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
      }

      deferredPrompt = null;

    });
  };

  const handleCancel = () => {
    setInstallable(false);
  };

  return (
    <>
      {
        appInstallable && (
          <InfoContainer>
            <InfoWrapper>
              <StyledHeaderInfo>Uwaga!</StyledHeaderInfo>
              <Paragraph>Korzystasz z przeglądarkowej wersji aplikacji! Pobierz aplikację klikając poniższy przycisk lub zrób to później: </Paragraph>
              <ButtonsWrapper>
                <ButtonSecondary type="button" onClick={handleCancel}>Anuluj</ButtonSecondary>
                {/*@ts-ignore*/}
                <ButtonPrimary type="button" onClick={handleInstallClick}>Pobierz</ButtonPrimary>
              </ButtonsWrapper>
            </InfoWrapper>
          </InfoContainer>
        )
      }
    </>
  );
};

export default InfoAddAppToHomeScreen;
