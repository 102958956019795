import {FunctionComponent}    from "react";
import React                  from "react";
import styled                 from "styled-components";
import RocketImage            from "../../assets/images/rocket.png";
import ButtonPrimaryBack      from "../buttons/ButtonPrimaryBack";
import Header                 from "../typography/Header";
import {defaultInfoHeader}    from "./defaultStyles";
import {defaultInfoContainer} from "./defaultStyles";
import {defaultInfoWrapper}   from "./defaultStyles";


const InfoContainer = styled.div`
    ${defaultInfoContainer}
`;

const InfoWrapper = styled.div`
    ${defaultInfoWrapper};
`;

const StyledHeaderInfo = styled(Header)<{ center?: boolean }>`
    ${defaultInfoHeader};
    line-height: 1.4;
    max-width: 200px;
`;

interface Props {
  handleClose: () => void;
}

const InfoBuildingStatus: FunctionComponent<Props> = ({children, handleClose}) => {
  return (
    <InfoContainer>
      <InfoWrapper>
        <img src={RocketImage} alt=""/>
        <StyledHeaderInfo center>{children}</StyledHeaderInfo>
        <ButtonPrimaryBack onClick={() => handleClose()}>Powrót do listy</ButtonPrimaryBack>
      </InfoWrapper>
    </InfoContainer>
  );
};

export default InfoBuildingStatus;
