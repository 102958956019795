import {useEffect}         from "react";
import React               from "react";
import {matchRoutes}       from "react-router";
import {useLocation}       from "react-router-dom";
import {useParams}         from "react-router-dom";
import {useAppDispatch}    from "../../hooks/useAppDispatch";
import {ROUTE_THREAD_SHOW} from "../../routing/routes";
import {showSubpage}       from "../../store/SubpageReducer";
import ThreadForm          from "./ThreadForm";


const ThreadShowPage = () => {
  const {id}        = useParams();
  const location    = useLocation();
  const match       = matchRoutes([{path: ROUTE_THREAD_SHOW}], location.pathname);
  const dispatch    = useAppDispatch();
  let subpageStatus = location.state as { subpage: boolean | undefined };

  useEffect(() => {
    if (!subpageStatus) {
      location.state = {subpage: true};
    }
  }, []);
  
  useEffect(() => {
    if (match || subpageStatus) {
      dispatch(showSubpage());
    }
  }, [location]);

  return <ThreadForm id={id}/>;

};

export default ThreadShowPage;
