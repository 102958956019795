import {useEffect}          from "react";
import {useState}           from "react";
import React                from "react";
import InfiniteScroll       from "react-infinite-scroll-component";
import {useSwipeable}       from "react-swipeable";
import styled               from "styled-components";
import ButtonPrimaryContact from "../../components/buttons/ButtonPrimaryContact";
import InfoEmptyContent     from "../../components/infobox/InfoEmptyContent";
import LoadingData          from "../../components/loading-data/LoadingData";
import ModalCreateThread    from "../../components/modal/ModalCreateThread";
import PageHeader           from "../../components/page-header/PageHeader";
import ThreadCard           from "../../components/thread-card/ThreadCard";
import Header               from "../../components/typography/Header";
import Paragraph            from "../../components/typography/Paragraph";
import Wrapper              from "../../components/wrapper/Wrapper";
import {useAppDispatch}      from "../../hooks/useAppDispatch";
import {useAppSelector}      from "../../hooks/useAppSelector";
import Subpage               from "../../layout/Subpage";
import {ROUTE_THREAD}        from "../../routing/routes";
import {clearMessage}        from "../../store/MessageReducer";
import {fetchThreadSearch}   from "../../store/ThreadReducer";
import {showModal}           from "../../store/ThreadReducer";
import {fetchThreadInfinite} from "../../store/ThreadReducer";
import {fetchThread}         from "../../store/ThreadReducer";


const ThreadIndexWrapper = styled(Wrapper)`
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
`;

const ContactFilter = styled.div`
    box-shadow: 0 4px 4px -2px rgba(42, 183, 248, 0.15);
    padding: 12px 24px 16px 24px;
    margin: 0 auto 24px auto;
    background-color: #fff;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin-bottom: 0;
        margin-top: 24px;
        padding: 24px;
        box-shadow: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;
        position: relative;
        z-index: 2;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% - 72px);
            height: 1px;
            background-color: ${({theme}) => theme.colors.border};
        }
    }
`;

const ContactFilterWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        justify-content: flex-end;
    }
`;

const ContactFilterItem = styled.div`
    font-size: ${props => props.theme.fonts.mobile.sm};
    text-decoration: underline;
    color: ${props => props.theme.colors.secondary};
    margin: 0 8px;
    opacity: 0.4;
    cursor: pointer;
    transition: .3s all ease;

    &:hover {
        opacity: 1;
    }

    &.active {
        color: ${props => props.theme.colors.primary};
        opacity: 1;
    }
`;

const StyledParagraph = styled(Paragraph)`
    margin: 12px 0 24px 0;
`;

const InfoEmptyInbox = styled.div`
    text-align: center;
`;

const Page = styled.div`
    width: 100%;
    //height: 100%;
    transition: .3s all ease;

    &.active {
        transform: translate3d(-100%, 0, 0);
    }

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        &.active {
            transform: none;
        }
    }
`;


const filterTypes = {
  FILTER_ALL:      "all",
  FILTER_ACTIVE:   "active",
  FILTER_INACTIVE: "inactive",
};

const filterItems = [
  {
    title: "Wszystkie",
    type:  filterTypes.FILTER_ALL,
  },
  {
    title: "Aktywne",
    type:  filterTypes.FILTER_ACTIVE,
  },
  {
    title: "Zamknięte",
    type:  filterTypes.FILTER_INACTIVE,
  },
];

const ThreadIndexPage = () => {
  const [activeFiler, setActiveFiler] = useState<string>(filterTypes.FILTER_ALL);
  const threads                       = useAppSelector(state => state.thread);
  const dispatch                      = useAppDispatch();
  const subpage                       = useAppSelector(state => state.subpage.status);
  const [loading, setLoading]         = useState<boolean>(true);
  const createThreadModal             = useAppSelector(state => state.thread.modalCreate);
  const globalSwipe                   = useAppSelector(state => state.modal.status);

  useEffect(() => {
    if (!subpage) {
      dispatch(clearMessage());
    }
  }, [subpage]);

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      setTimeout(() => {
        dispatch(fetchThread());
        setLoading(false);
      }, 500);
    }
    return () => {
      isSubscribed = false;
    };
  }, [fetchThread]);


  const renderContactFilterItem = (title: string, type: string) => {
    const setFilterData = () => {
      setActiveFiler(type);

      if (type === filterTypes.FILTER_ALL) {
        dispatch(fetchThread());
      } else if (type === filterTypes.FILTER_ACTIVE) {
        dispatch(fetchThreadSearch({active: 1}));
      } else {
        dispatch(fetchThreadSearch({active: 0}));
      }
    };

    return (
      <ContactFilterItem onClick={setFilterData} className={type === activeFiler ? "active" : ""}>{title}</ContactFilterItem>
    );
  };

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => {
      if (window.innerWidth < 860 && document.documentElement.scrollTop === 0) {
        setLoading(true);
        setTimeout(() => {
          dispatch(fetchThread());
          setLoading(false);
        }, 400);

      }
    },
    delta:       40,
    trackTouch:   !globalSwipe,
    trackMouse:   true,
  });

  const handleClick = () => {
    dispatch(showModal());
    dispatch(clearMessage());
  };

  return (
    <>
      <Page className={subpage ? "active" : ""}>
        <PageHeader>Kontakt</PageHeader>
        {
          !loading ? (
            <>
              {
                threads.total > 0 ? (
                  <>
                    <ContactFilter>
                      {/*<StyledButtonPrimaryAdd to={ROUTE_THREAD_CREATE}>Rozpocznij wątek</StyledButtonPrimaryAdd>*/}
                      <ContactFilterWrapper>
                        {
                          filterItems.map((filter, i) => (
                            <div key={i}>
                              {renderContactFilterItem(filter.title, filter.type)}
                            </div>
                          ))
                        }
                      </ContactFilterWrapper>
                    </ContactFilter>
                    {
                      !loading ? (
                        <>
                          {
                            threads.data && (
                              <ThreadIndexWrapper {...swipeHandlers}>
                                <InfiniteScroll
                                  dataLength={threads.data.length}
                                  next={() => dispatch(fetchThreadInfinite(threads.next_page))}
                                  hasMore={true}
                                  loader={null}
                                >
                                  {
                                    threads.data.length > 0 ? (
                                      threads.data.map((data, i) => (
                                        <ThreadCard data={data} key={i}/>
                                      ))
                                    ) : (
                                      <InfoEmptyContent>
                                        Lista wątków jest pusta
                                      </InfoEmptyContent>
                                    )
                                  }
                                </InfiniteScroll>
                              </ThreadIndexWrapper>
                            )
                          }
                        </>
                      ) : (
                        <LoadingData/>
                      )
                    }
                  </>
                ) : (
                  <ThreadIndexWrapper>
                    <InfoEmptyInbox>
                      <Header>Witaj w Twojej skrzynce pocztowej!</Header>
                      <StyledParagraph>Tutaj znajdziesz listę swoich wszystkich wiadomości, jeżeli potrzebujesz skontaktować się z administratorem serwisu, rozpocznij nowy wątek!</StyledParagraph>
                      <ButtonPrimaryContact onClick={handleClick}>Utwórz nowy wątek</ButtonPrimaryContact>
                    </InfoEmptyInbox>
                  </ThreadIndexWrapper>
                )
              }
            </>
          ) : (
            <LoadingData/>
          )
        }
        <ModalCreateThread state={createThreadModal}/>
      </Page>
      <Subpage parentRoute={ROUTE_THREAD}/>
    </>
  );
};

export default ThreadIndexPage;
