import styled from "styled-components";


const InputError = styled.p`
    color: ${props => props.theme.colors.danger};
    margin-bottom: 5px;
    font-size: ${props => props.theme.fonts.mobile.sm};
    margin-top: 8px;
`;

export default InputError;
