import axios             from "axios";
import UserSource        from "../entities/UserSource";
import Entrypoint        from "../Entrypoint";
import {ResponsePromise} from "../ResponsePromise";


class UserSourceApi {
  Get(): ResponsePromise<UserSource[]> {
    return axios.get(Entrypoint.Api.UserSource.Index);
  }
}

export default UserSourceApi;
