import React            from "react";
import {Navigate}       from "react-router-dom";
import styled           from "styled-components";
import PenImage         from "../../assets/images/pen.png";
import ButtonPrimary    from "../../components/buttons/ButtonPrimary";
import NavbarAuth       from "../../components/navbar/NavbarAuth";
import Header           from "../../components/typography/Header";
import Paragraph        from "../../components/typography/Paragraph";
import Wrapper          from "../../components/wrapper/Wrapper";
import {useAppSelector} from "../../hooks/useAppSelector";
import {ROUTE_HOME}     from "../../routing/routes";


const StyledWrapper = styled(Wrapper)`
    text-align: center;
    max-width: 560px;
    background-color: transparent;
    box-shadow: none;
`;

const StyledHeader = styled(Header)`
    margin: 12px 0 24px 0;
    font-size: ${({theme}) => theme.fonts.mobile.xl};
`;

const StyledParagraph = styled(Paragraph)`
    text-align: center;
`;

const AccountAcceptance = () => {
  const user = useAppSelector(state => state.newUser.user);

  if (user && user.activated_at) {
    return <Navigate to={ROUTE_HOME}/>;
  }

  return (
    <>
      <NavbarAuth/>
      <StyledWrapper>
        <img src={PenImage} alt=""/>
        <StyledHeader>Twoje konto nie zostało jeszcze aktywowane</StyledHeader>
        <StyledParagraph>Powiadomimy cię w mailu</StyledParagraph>
        <br/>
        <ButtonPrimary onClick={() => window.location.reload()}>Odśwież aplikację</ButtonPrimary>
      </StyledWrapper>
    </>
  );
};

export default AccountAcceptance;
