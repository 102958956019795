import {FunctionComponent}                   from "react";
import React                                 from "react";
import {Link}                                from "react-router-dom";
import styled                                from "styled-components";
import ChevronRightIcon                      from "../../assets/icons/chevron-right-with-white-bg.svg";
import {defaultPrimaryButtonIconRightStyles} from "../../styles/DefaultPrimaryButtonIconRightStyles";
import {ButtonType}                          from "../../types/ButtonType";


const StyledButton = styled.button`
    ${defaultPrimaryButtonIconRightStyles};
    background-image: url(${ChevronRightIcon});
`;

const StyledLink = styled(Link)`
    ${defaultPrimaryButtonIconRightStyles};
    background-image: url(${ChevronRightIcon});
`;

const StyledHref = styled.a`
    ${defaultPrimaryButtonIconRightStyles};
    background-image: url(${ChevronRightIcon});
`;


const ButtonPrimaryForward: FunctionComponent<ButtonType> = (props) => {

  const RenderButton = (props: any) => {
    if (props.to) {
      return <StyledLink to={props.to} {...props}>{props.children}</StyledLink>;
    } else if (props.href) {
      return <StyledHref href={props.href} {...props}>{props.children}</StyledHref>;
    } else {
      return <StyledButton type={props.type} {...props}>{props.children}</StyledButton>;
    }
  };

  return (
    <>
      {
        RenderButton(props)
      }
    </>
  );
};

StyledButton.defaultProps = {
  type: "button",
};

export default ButtonPrimaryForward;
