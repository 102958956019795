import React               from "react";
import {FunctionComponent} from "react";
import styled              from "styled-components";
import FormGroup           from "./FormGroup";


const StyledFormGroup = styled(FormGroup)`
    display: flex;
    justify-content: center;
    padding-top: 18px;
`;

const FormSubmit: FunctionComponent = ({children}) => {
  return (
    <StyledFormGroup>{children}</StyledFormGroup>
  );
};

export default FormSubmit;
