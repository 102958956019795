import {useRef}            from "react";
import {FunctionComponent} from "react";
import React               from "react";
import styled              from "styled-components";
import useApi              from "../../hooks/useApi";
import {useAppDispatch}    from "../../hooks/useAppDispatch";
import {fetchUser}         from "../../store/UserReducerNew";


const AddAvatarForm = styled.div`
    width: 100px;
    text-align: center;
    margin-top: 14px;
    overflow: hidden;
`;

const HiddenFileInput = styled.input`
    display: none;
    opacity: 0;
    visibility: hidden;
`;

const StyledSpan = styled.span`
    width: 100px;
    display: inline-block;
    background-color: transparent;
    color: ${({theme}) => theme.colors.primary};
    text-decoration: underline;
    font-size: 14px;
    overflow: hidden;
    cursor: pointer;
    transition: .3s color ease;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_big}) {
        &:hover {
            color: ${({theme}) => theme.colors.secondary};
        }
    }
`;

const StyledLabel = styled.label``;

interface Props {
  isAvatar: boolean;
}

const UserAvatarInput: FunctionComponent<Props> = ({isAvatar}) => {
  const inputRef = useRef<any>();
  const Api      = useApi();
  const dispatch = useAppDispatch();

  const handleImage = () => {
    let data = new FormData();
    data.append("avatar", inputRef.current.files[0]);

    Api.Profile.Avatar(data).then(() => {
      dispatch(fetchUser());
    }).catch(error => console.error(error));
  };

  return (
    <AddAvatarForm>
      <StyledLabel>
        <HiddenFileInput type="file" accept=".jpeg, .jpg, .gif, .png" ref={inputRef} onChange={() => handleImage()}/>
        <StyledSpan> {isAvatar ? "Edytuj zdjęcie" : "Dodaj zdjęcie"}</StyledSpan>
      </StyledLabel>
    </AddAvatarForm>
  );
};

export default UserAvatarInput;
