import axios             from "axios";
import {generatePath}    from "react-router";
import Article           from "../entities/Article";
import Entrypoint        from "../Entrypoint";
import {ResponsePromise} from "../ResponsePromise";
import AbstractModule    from "./AbstractModule";


class ArticleApi extends AbstractModule {

  List(page: any): ResponsePromise<{ last_page: number; data: Article[] }> {
    return axios.get(Entrypoint.Api.Article.Index, {
      params: {
        ...page,
      },
    });
  }

  Get(article: string) {
    return axios.get(generatePath(Entrypoint.Api.Article.Show, {article}));
  }
}

export default ArticleApi;
