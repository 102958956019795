import {useEffect}          from "react";
import {useState}           from "react";
import React                from "react";
import {matchRoutes}        from "react-router";
import {useLocation}        from "react-router-dom";
import {useParams}          from "react-router-dom";
import Article              from "../../api/entities/Article";
import useApi               from "../../hooks/useApi";
import {useAppDispatch}     from "../../hooks/useAppDispatch";
import ArticleLayout        from "../../layout/ArticleLayout";
import {ROUTE_ARTICLE_SHOW} from "../../routing/routes";
import {ROUTE_ARTICLES}     from "../../routing/routes";
import {showSubpage}        from "../../store/SubpageReducer";


const ArticleShowPage = () => {
  const {id}            = useParams();
  const Api             = useApi();
  const [data, setData] = useState<Article>();
  const location        = useLocation();
  const match           = matchRoutes([{path: ROUTE_ARTICLE_SHOW}], location.pathname);
  const dispatch        = useAppDispatch();
  let subpage           = location.state as { subpage: boolean | undefined };


  useEffect(() => {
    if (id) {
      Api.Article.Get(id).then(({data}) => {
        setData(data);
        if (match || subpage) {
          dispatch(showSubpage());
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="children">
      {
        data && (
          <ArticleLayout data={data} parentUrl={ROUTE_ARTICLES}/>
        )
      }
    </div>
  );
};

export default ArticleShowPage;
