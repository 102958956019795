import {useEffect}                 from "react";
import {useState}                  from "react";
import React                       from "react";
import {CSSTransition}             from "react-transition-group";
import styled                      from "styled-components";
import {useAppDispatch}            from "../../hooks/useAppDispatch";
import {useServiceWorker}          from "../../hooks/useServiceWorker";
import {hideUpdate}                from "../../store/UpdateReducer";
import {showUpdate}                from "../../store/UpdateReducer";
import ButtonPrimaryForward        from "../buttons/ButtonPrimaryForward";
import ButtonSecondaryClose        from "../buttons/ButtonSecondaryClose";
import Paragraph                   from "../typography/Paragraph";
import {defaultModalWrapperStyles} from "./defaultModalStyles";
import {defaultModalStyles}        from "./defaultModalStyles";


const Container = styled.div`
    ${defaultModalStyles};
    opacity: 1;
    visibility: visible;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        align-items: center;
    }
`;

const Wrapper = styled.div`
    ${defaultModalWrapperStyles}
`;

const ModalButtonsWrapper = styled.div`
    display: flex;
    padding-top: 18px;
    width: 100%;
    justify-content: center;

    button {
        width: 140px;
        margin: 0 4px;
    }
`;

const StyledParagraph = styled(Paragraph)`
    width: 100%;
    text-align: center;
    line-height: 1.6;
    font-size: ${({theme}) => theme.fonts.mobile.md};
    font-weight: ${({theme}) => theme.weights.medium};
`;


const ModalToast = () => {
  const [toast, setToast]                       = useState<boolean>(false);
  const {waitingWorker, showReload, reloadPage} = useServiceWorker();
  const dispatch                                = useAppDispatch();

  useEffect(() => {
    if (showReload && waitingWorker) {
      setToast(true);
    } else {
      setToast(false);
    }
  }, [waitingWorker, showReload, reloadPage]);


  const handleClick = () => {
    dispatch(showUpdate());
    setToast(false);
  };

  const handleClickReload = () => {
    dispatch(hideUpdate());
    reloadPage();
  };

  return (
    <CSSTransition
      in={toast}
      timeout={200}
      classNames="modal-fade"
      unmountOnExit
    >
      <Container>
        <Wrapper className="modal-wrapper">
          <StyledParagraph>
            Pojawiła się nowa aktualizacja! Odśwież aplikację teraz lub możesz to zrobić później w klikając odpowiedni przycisk w ustawieniach.
          </StyledParagraph>
          <ModalButtonsWrapper>
            <ButtonPrimaryForward type="button" onClick={() => handleClickReload()}>Aktualizuj</ButtonPrimaryForward>
            <ButtonSecondaryClose onClick={() => handleClick()}>Później</ButtonSecondaryClose>
          </ModalButtonsWrapper>
        </Wrapper>
      </Container>
    </CSSTransition>
  );
};

export default ModalToast;
