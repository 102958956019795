import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Article from '../api/entities/Article';
import { defaultModalCloseStyles } from '../components/modal/defaultModalStyles';
import Date from '../components/typography/Date';
import Header from '../components/typography/Header';
import Paragraph from '../components/typography/Paragraph';
import Wrapper from '../components/wrapper/Wrapper';
import useHtmlParser from '../hooks/useHtmlParser';
import useMomentConverter from '../hooks/useMomentConverter';


const ArticleWrapper = styled(Wrapper)`
    position: relative;
    padding-bottom: ${({ theme }) => theme.layout_part_size.mobile.menu_bottom_mobile};
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_small}) {
        margin: 0 auto 48px 0;
        padding-bottom: 0;
    }
`;

const HeroImage = styled.div`
    width: 100%;
    height: 140px;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 14px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_small}) {
        height: 200px;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
        height: 300px;
    }
`;

const DescriptionBox = styled(Paragraph)`
    margin-top: 18px;
`;

const CloseButton = styled(Link)`
    display: none;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        ${defaultModalCloseStyles};
        display: block;
    }
`;

const ArticleLayout: FunctionComponent<{ data: Article; parentUrl: string; }> = ({ data, parentUrl }) => {

  return (
    <ArticleWrapper>
      <CloseButton to={parentUrl}/>
      {
        data.photos?.length > 0 && (
          <HeroImage>
            <picture>
              <source srcSet={data.photos[0].asset_path} type="image/webp"/>
              <img src={data.photos[0].asset_path} alt=""/>
            </picture>
          </HeroImage>
        )
      }
      {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
      <Date> {useMomentConverter(data.created_at)}</Date>
      <Header>{data.name}</Header>
      <DescriptionBox dangerouslySetInnerHTML={{__html: data.description}}></DescriptionBox>
    </ArticleWrapper>
  );
};

export default ArticleLayout;
