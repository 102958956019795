import {useField} from 'formik';
import React, {FunctionComponent} from 'react';
import {useAppDispatch} from '../../hooks/useAppDispatch';
import {showNavbar, hideNavbar} from '../../store/NavbarReducer';
import FormGroup from '../internals/FormGroup';
import Input from '../internals/Input';
import Label from '../internals/Label';
import InputError from '../validation/inputError';


interface Props {
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

const InputEmail: FunctionComponent<Props> = (props) => {
  const [field, meta] = useField(props.name);
  const dispatch      = useAppDispatch();

  const handleFocus = () => {
    dispatch(hideNavbar());
  };

  const handleBlur = () => {
    dispatch(showNavbar());
  };

  return (
    <FormGroup className={meta.error && 'validation-error'}>
      <Label required={props.required}>{props.label}</Label>
      <Input onFocus={handleFocus} onBlur={handleBlur} type="email" name={props.name} placeholder={props.placeholder} disabled={props.disabled}/>
      {(meta.error) && <InputError>{meta.error}</InputError>}
    </FormGroup>
  );
};

InputEmail.defaultProps = {
  required: false,
};

export default InputEmail;
