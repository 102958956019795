import axios from 'axios';
import Entrypoint from '../Entrypoint';
import {ResponsePromise} from '../ResponsePromise';
import History from '../entities/History';


class HistoryApi {
  Get(): ResponsePromise<History[]> {
    return axios.get(Entrypoint.Api.History.Index);
  }
}

export default HistoryApi;
