import React, {FunctionComponent, useRef, useEffect} from 'react';
import GoogleMapComponent from '../google-map/GoogleMapComponent';
import styled from 'styled-components';
import Wrapper from '../wrapper/Wrapper';
import HeaderSmall from '../typography/HeaderSmall';
import {defaultModalCloseStyles} from './defaultModalStyles';
import Building from '../../api/entities/Building';
import {enableBodyScroll, clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';
import {useAppDispatch} from '../../hooks/useAppDispatch';
import {hideModalBuildingMap} from '../../store/ModalBuildingMapReducer';


const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    max-width: 100vw;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    padding-top: 24px;
    z-index: 100000000;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        padding-top: 0;
    }
`;

const StyledWrapper = styled(Wrapper)`
    border-radius: 5px;
    position: relative;
    padding: 24px 0 0 0;
    max-width: 100%;
    width: 100%;
    height: calc(100% - 36px);
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        height: 100%;
        margin-top: 0;
        border-radius: 0;
    }
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${({theme}) => theme.colors.border};
    padding: 0 24px 24px 24px;
`;

const CloseButton = styled.button`
    ${defaultModalCloseStyles};
    position: relative;
    top: inherit;
    right: inherit;
`;

interface Props {
  data: Building[] | undefined;
}

const ModalBuildingMap: FunctionComponent<Props> = ({data}) => {
  const mapContainerRef = useRef<any>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    disableBodyScroll(mapContainerRef.current);
  }, []);

  const handleClose = () => {
    enableBodyScroll(mapContainerRef.current);
    clearAllBodyScrollLocks();
    dispatch(hideModalBuildingMap());
  };

  return (
    <Container ref={mapContainerRef}>
      <StyledWrapper>
        <ModalHeader>
          <HeaderSmall>
            Widok mapy
          </HeaderSmall>
          <CloseButton onClick={handleClose}/>
        </ModalHeader>
        <GoogleMapComponent buildings={data}/>
      </StyledWrapper>
    </Container>
  );
};

export default ModalBuildingMap;
