import {Form, Formik}             from "formik";
import {FunctionComponent}        from "react";
import {useState}                 from "react";
import {useRef}                   from "react";
import React                      from "react";
import {generatePath}             from "react-router";
import {useNavigate}              from "react-router";
import TextareaAutosize           from "react-textarea-autosize";
import {css}                      from "styled-components";
import styled                     from "styled-components";
import ThreadMessageCreateRequest from "../../api/request/thread/ThreadMessageCreateRequest";
import AttachmentIcon             from "../../assets/icons/attachment.svg";
import CloseGrayIcon              from "../../assets/icons/close-gray.svg";
import FileIcon                   from "../../assets/icons/file-icon.svg";
import PdfIcon                    from "../../assets/icons/icon-pdf.svg";
import ZipIcon                    from "../../assets/icons/icon-zip.svg";
import useApi                     from "../../hooks/useApi";
import {useAppDispatch}           from "../../hooks/useAppDispatch";
import {useAppSelector}           from "../../hooks/useAppSelector";
import {ROUTE_THREAD_SHOW}        from "../../routing/routes";
import {showErrorAppModal}        from "../../store/ModalErrorApp";
import {fetchThread}              from "../../store/ThreadReducer";
import {ButtonSend}               from "../buttons/ButtonSend";


const AttachmentPreviewWrapper = styled.div<{ createForm?: number }>`
    display: flex;
    align-items: flex-end;
    height: 80px;
    padding: 8px 24px 12px ${props => props.createForm ? "0" : "24px"};
    overflow: auto;
    position: absolute;
    bottom: 100%;
    left: 0;
    background-color: #fff;
    width: 100%;
    box-shadow: ${props => props.createForm ? "none" : "0px -4px 4px rgba(0, 0, 0, 0.05)"};
    z-index: 4;
`;

const AttachmentPreviewItem = styled.div`
    height: 48px;
    position: relative;

    &:not(:last-child) {
        margin-right: 12px;
    }

    img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
        overflow: hidden;
    }
`;

const ButtonDelete = styled.button`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    right: -8px;
    top: -8px;
    background-color: #ccc;
    background-image: url(${CloseGrayIcon});
    border: none;
    background-repeat: no-repeat;
    background-position: center;
`;

const MessageFormContainer = styled.div<{ createForm?: number }>`
    height: ${({theme}) => theme.layout_part_size.mobile.message_form};
    width: 100%;
    box-shadow: ${props => props.createForm ? "none" : "0px -4px 4px rgba(0, 0, 0, 0.05)"};
    background-color: ${props => props.theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${props => props.createForm ? "0" : "0 24px"};
    border-top: 1px solid ${props => props.createForm ? props.theme.colors.border : "transparent"};
    // position: ${props => props.createForm ? "relative" : "sticky"};
    position: relative;
    bottom: 0;
    left: 0;
`;

const MessageFromWrapper = styled(Form)`
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 5px 8px;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin: 16px auto;
    }
`;

const StyledTextarea = styled(TextareaAutosize)`
    min-height: 30px;
    max-height: 100px;
    border: none;
    margin-left: 10px;
    width: 100%;
    padding: 6px 8px 0 0;
    line-height: 1.5;
    font-family: 'Exo', sans-serif;
    font-size: ${props => props.theme.fonts.mobile.sm};
    resize: none;
`;

const InputAttachment = styled.label`
    width: 48px;
    overflow: hidden;
    position: relative;

    span {
        background-image: url(${AttachmentIcon});
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        height: 100%;
        display: block;
        background-repeat: no-repeat;
        background-position: center;
    }

    input {
        opacity: 0;
    }
`;

const defaultFileStyle = css`
    width: 192px;
    height: 100%;
    border-radius: 5px;
    background-color: #e5e5e5;
    background-repeat: no-repeat;
    background-size: 24px 36px;
    background-position: 8px 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2px 8px 2px 38px;
    box-shadow: 0 0 7px -2px rgba(66, 68, 90, 0.4);

    span {
        font-size: 10px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const FakeImage = styled.div`
    width: 48px;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const FakePdf = styled.div`
    ${defaultFileStyle};
    background-image: url(${PdfIcon});
`;

const FakeZip = styled.div`
    ${defaultFileStyle};
    background-image: url(${ZipIcon});
`;

const FakeFile = styled.div`
    ${defaultFileStyle};
    background-image: url(${FileIcon});
`;


interface Props {
  threadId: string;
  setDataHandler: () => void;
  createForm?: number;
  handleClose?: () => void;
}

const MessageForm: FunctionComponent<Props> = ({threadId, setDataHandler, createForm, handleClose}) => {
  const inputRef                    = useRef<any>(null);
  const [filesArray, setFilesArray] = useState<any[]>([]);
  const Api                         = useApi();
  const threadName                  = useAppSelector(state => state.thread.name);
  const navigate                    = useNavigate();
  const dispatch                    = useAppDispatch();

  const handleChange = () => {
    setFilesArray(filesArray.concat(Array.from(inputRef.current.files)));
  };

  const removePreview = (file: File) => {
    const index         = filesArray.indexOf(file);
    const filteredItems = filesArray.slice(0, index).concat(filesArray.slice(index + 1, filesArray.length));

    setFilesArray(filteredItems);

    const dt = new DataTransfer();

    for (let i = 0; i < filesArray.length; i++) {
      const file = filesArray[i];
      if (index !== i) {
        dt.items.add(file);
      } // here you exclude the file. thus removing it.
    }

    inputRef.current.files = dt.files; // Assign the updates list
  };

  const handleMessageStore = (id: string, actions: any, values: any, callback?: () => void) => {
    let data = new FormData();

    Object.keys(values).forEach(key => {
      data.append(key, values[key]);
    });

    filesArray.forEach(file => data.append("files[]", file));

    Api.Thread.StoreMessage(id, data).then(() => {
      setDataHandler();
      actions.resetForm();

      if (callback) {
        callback();
      }

      setFilesArray([]);
    }).catch(error => {
      dispatch(showErrorAppModal());
      console.error(error);
      const errors = {};
      actions.setErrors(errors);
    });
  };

  const fileTypeRender = (file: File) => {
    if (file.type.includes("image")) {
      return (
        <FakeImage>
          <img src={URL.createObjectURL(file)} alt=""/>
        </FakeImage>
      );
    } else if (file.type.includes("pdf")) {
      return (
        <FakePdf>
          <span>{file.name}</span>
          <span>{file.size}</span>
        </FakePdf>
      );
    } else if (file.type.includes("zip") || file.type.includes("rar") || file.type.includes("7z")) {
      return (
        <FakeZip>
          <span>{file.name}</span>
          <span>{file.size}</span>
        </FakeZip>
      );
    } else {
      return (
        <FakeFile>
          <span>{file.name}</span>
          <span>{file.size}</span>
        </FakeFile>
      );
    }
  };

  const initialValues: ThreadMessageCreateRequest = {
    content: "",
  };

  return (
    <MessageFormContainer createForm={createForm}>
      {
        filesArray.length > 0 && (
          <AttachmentPreviewWrapper createForm={createForm}>
            {
              filesArray.map((file: any, i: number) => (
                  <AttachmentPreviewItem key={i}>
                    {fileTypeRender(file)}
                    <ButtonDelete type="button" onClick={() => removePreview(file)}/>
                  </AttachmentPreviewItem>
                ),
              )
            }
          </AttachmentPreviewWrapper>
        )
      }

      <Formik
        initialValues={initialValues}
        onSubmit={(values: ThreadMessageCreateRequest, actions) => {
          if (threadId === "0") {
            Api.Thread.Store({title: threadName}).then((response: any) => {
              if (handleClose) {
                handleClose();
              }
              handleMessageStore(response.data.id, actions, values, () => {
                dispatch(fetchThread());
                navigate(generatePath(ROUTE_THREAD_SHOW, {id: response.data.id}), {state: {subpage: true}});
              });

            }).catch(error => {
              dispatch(showErrorAppModal());
              console.error(error);
            });
          } else {
            handleMessageStore(threadId, actions, values);
          }
        }}
      >
        {(props) => (
          <MessageFromWrapper>
            <InputAttachment>
              <input type="file" multiple ref={inputRef} onChange={() => handleChange()}/>
              <span/>
            </InputAttachment>
            <StyledTextarea
              placeholder="Wyślij wiadomość"
              name="content"
              maxRows={2}
              minRows={1}
              onChange={props.handleChange}
              value={props.values.content}
            />
            <ButtonSend type="submit" disabled={props.values.content === ""}/>
          </MessageFromWrapper>
        )}
      </Formik>
    </MessageFormContainer>
  );
};

export default MessageForm;
