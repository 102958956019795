import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice}   from "@reduxjs/toolkit";


interface MenuState {
  status: boolean;
}

const initialState: MenuState = {
  status: false,
};

export const menuSlice = createSlice({
  name:     "menu",
  initialState,
  reducers: {
    showMenu:   state => {
      state.status = true;
    },
    hideMenu:   state => {
      state.status = false;
    },
    toggleMenu: (state, action: PayloadAction<boolean>) => {
      state.status = action.payload;
    },
  },
});

export const {showMenu, hideMenu, toggleMenu} = menuSlice.actions;

export default menuSlice.reducer;
