import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import React, {useRef, useEffect, useState} from 'react';
import {useSwipeable} from 'react-swipeable';
import {CSSTransition} from 'react-transition-group';
import styled from 'styled-components';
import {useAppDispatch} from '../../hooks/useAppDispatch';
import {useAppSelector} from '../../hooks/useAppSelector';
import useClickOutside from '../../hooks/useClickOutside';
import useKeyPress from '../../hooks/useKeyPress';
import {enableGlobalSwipe, blockGlobalSwipe} from '../../store/ModalReducer';
import {defaultModalCloseStyles} from './defaultModalStyles';
import Paragraph from '../typography/Paragraph';
import {hideHistoryModal} from '../../store/ModalHistoryReducer';
import useApi from '../../hooks/useApi';
import moment from 'moment';
import InfoEmptyContent from '../infobox/InfoEmptyContent';
import Wrapper from '../wrapper/Wrapper';


const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    max-width: 100vw;
    width: 100%;
    height: 100vh;
    z-index: 1000000;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
`;

const Content = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    padding-top: 24px;
`;

const StyledWrapper = styled(Wrapper)`
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    padding: 24px 18px 48px 18px;
    max-width: 650px;
    width: 100% !important;
    position: relative;
    margin-top: auto;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        margin-left: auto;
        margin-right: auto;
        padding: 24px 24px 48px 24px;
    }
`;

const CloseModal = styled.button`
    ${defaultModalCloseStyles};
`;

const ModalTitle = styled.div`
    width: 100%;
    padding-bottom: 12px;
    margin-bottom: 18px;
    border-bottom: 1px solid ${({theme}) => theme.colors.border};
    font-size: 14px;
    font-weight: 600;
`;

const ModalContent = styled.div`
    margin-top: 24px;
`;

const ModalItem = styled.div`
    display: flex;

    &:not(:last-child) {
        margin-bottom: 24px;
    }
`;

const TextBox = styled.div`
    width: calc(100% - 70px);
    font-size: 12px;
    padding-right: 24px;
`;

const PointsBox = styled.div`
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 500;
    border-left: 1px solid ${({theme}) => theme.colors.border};

    &.increase {
        color: ${({theme}) => theme.colors.success}
    }
`;

const StyledParagraph = styled(Paragraph)`
    line-height: 1.3;
    font-weight: 600;
`;

const Date = styled.div`
    font-size: 12px;
    margin-top: 4px;
`;

const ModalHistory = () => {
  const ref = useRef<any>();
  const refWrapper = useRef<any>();
  const dispatch = useAppDispatch();
  const historyModalStatus = useAppSelector(state => state.modalHistory.status);
  const [data, setData] = useState<any>([]);
  const Api = useApi();

  useEffect(() => {
    if (historyModalStatus) {
      disableBodyScroll(ref.current);
      dispatch(blockGlobalSwipe());

      Api.History.Get().then(({data}) => {
        if (data) {
          setData(data.reverse());
        }
      });
    }
  }, [historyModalStatus]);

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => {
      if (window.innerWidth < 860) {
        handleClose();
      }
    },
    delta: 10,
    trackTouch: true,
    trackMouse: true,
  });

  const refPassThrough = (el: any) => {
    swipeHandlers.ref(el);
    ref.current = el;
  };

  const handleClose = () => {
    enableBodyScroll(ref.current);
    dispatch(enableGlobalSwipe());
    dispatch(hideHistoryModal());
  };

  useClickOutside(refWrapper, handleClose);

  useKeyPress(handleClose);

  return (
    <CSSTransition
      in={historyModalStatus}
      timeout={200}
      classNames="modal-fade"
      unmountOnExit
    >
      <Container {...swipeHandlers} ref={refPassThrough}>
        <Content>
          <StyledWrapper>
            <CloseModal onClick={handleClose}/>
            <ModalTitle>
              Historia punktów
            </ModalTitle>
            <ModalContent>
              {
                !data.length ? (
                  <InfoEmptyContent>Brak historii punktów</InfoEmptyContent>
                ) : (
                  <>
                    {
                      data.map((element: any, i: number) => (
                        <ModalItem key={i}>
                          <TextBox>
                            <StyledParagraph>
                              {
                                element.note ? element.note : 'Przyznano punkty'
                              }
                            </StyledParagraph>
                            <Date>{moment(element.created_at).format('DD.MM.YYYY')}</Date>
                          </TextBox>
                          <PointsBox className={element.is_add ? 'increase' : ''}>
                            {element.points}
                          </PointsBox>
                        </ModalItem>
                      ))
                    }
                  </>
                )
              }
            </ModalContent>
          </StyledWrapper>
        </Content>
      </Container>
    </CSSTransition>
  );
};

export default ModalHistory;
