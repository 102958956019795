import React          from "react";
import styled         from "styled-components";
import LoadingIconSvg from "../../assets/icons/spinner.svg";


const LoadingBox = styled.div`
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    font-size: 14px;
    padding-top: 48px;
`;


const LoadingIcon = styled.div`
    width: 42px;
    height: 42px;
    position: relative;
    margin: auto;
`;


const LoadingData = () => {
  return (
    <LoadingBox>
      <LoadingIcon>
        <img src={LoadingIconSvg} alt=""/>
      </LoadingIcon>
    </LoadingBox>
  );
};

export default LoadingData;
