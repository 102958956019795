import styled from 'styled-components';
import Wrapper from '../../components/wrapper/Wrapper';
import Header from '../../components/typography/Header';
import Paragraph from '../../components/typography/Paragraph';
import { useAppSelector } from '../../hooks/useAppSelector';
import { Navigate } from 'react-router-dom';
import { ROUTE_HOME } from '../../routing/routes';
import NavbarAuth from '../../components/navbar/NavbarAuth';
import ConfirmImage from '../../assets/images/confirm-2.png';
import ButtonPrimary from '../../components/buttons/ButtonPrimary';
import React from 'react';


const StyledWrapper = styled(Wrapper)`
    text-align: center;
    max-width: 560px;
    background-color: transparent;
    box-shadow: none;
`;

const StyledHeader = styled(Header)`
    margin: 12px 0 24px 0;
    font-size: ${({ theme }) => theme.fonts.mobile.xl};
`;

const StyledParagraph = styled(Paragraph)`
    text-align: center;
`;

const AccessDenied = () => {
  const user = useAppSelector(state => state.newUser.user);


  if (user && !user.is_banned) {
    return <Navigate to={ROUTE_HOME}/>;
  }

  return (
    <>
      <NavbarAuth/>
      <StyledWrapper>
        <img src={ConfirmImage} alt=""/>
        <StyledHeader>Twoje konto zostało zablokowane.</StyledHeader>
        <StyledParagraph>Skontaktuj się z hurtownią w celu aktywacji konta.</StyledParagraph>
        <br/>
        <ButtonPrimary onClick={() => window.location.reload()}>Odśwież aplikację</ButtonPrimary>
      </StyledWrapper>
    </>
  );
};

export default AccessDenied;
