const Entrypoint = {
  Api: {
    Article: {
      Index: `${process.env.REACT_APP_API_URL}/api/article`,
      Show: `${process.env.REACT_APP_API_URL}/api/article/:article`,
    },
    Auth: {
      SignIn: `${process.env.REACT_APP_API_URL}/oauth/token`,
      SignUp: `${process.env.REACT_APP_API_URL}/api/register`,
      SignOut: `${process.env.REACT_APP_API_URL}/api/logout`,
      ForgotPassword: `${process.env.REACT_APP_API_URL}/api/forgot-password`,
      ResetPassword: `${process.env.REACT_APP_API_URL}/api/reset-password`,
      TokenRefresh: `${process.env.REACT_APP_API_URL}/oauth/token/refresh`,
    },
    Building: {
      Index: `${process.env.REACT_APP_API_URL}/api/construction`,
      Finish: `${process.env.REACT_APP_API_URL}/api/construction/:construction/finish`,
      OfferEdit: `${process.env.REACT_APP_API_URL}/api/construction/:construction/to_offer`,
      OfferShow: `${process.env.REACT_APP_API_URL}/api/construction/offer`,
      OfferStore: `${process.env.REACT_APP_API_URL}/api/construction/offer/:construction`,
      Show: `${process.env.REACT_APP_API_URL}/api/construction/:construction`,
      Start: `${process.env.REACT_APP_API_URL}/api/construction/:construction/start`,
      Store: `${process.env.REACT_APP_API_URL}/api/construction`,
      Update: `${process.env.REACT_APP_API_URL}/api/construction/:construction`,
    },
    History: {
      Index: `${process.env.REACT_APP_API_URL}/api/points_history`,
    },
    Notification: {
      Index: `${process.env.REACT_APP_API_URL}/api/notification`,
      Delete: `${process.env.REACT_APP_API_URL}/api/notification/:notification`,
      Get: `${process.env.REACT_APP_API_URL}/api/notification/:notification`,
      Check: `${process.env.REACT_APP_API_URL}/api/notification/:notification`,
      Patch: `${process.env.REACT_APP_API_URL}/api/notification`,
      Total: `${process.env.REACT_APP_API_URL}/api/notification_unread`,
    },
    Points: {
      All: `${process.env.REACT_APP_API_URL}/api/points`,
    },
    Profile: {
      Avatar: `${process.env.REACT_APP_API_URL}/api/profile/avatar`,
      DeleteAvatar: `${process.env.REACT_APP_API_URL}/api/profile/avatar`,
      Update: `${process.env.REACT_APP_API_URL}/api/profile`,
    },
    Promotion: {
      Index: `${process.env.REACT_APP_API_URL}/api/promotion`,
      Show: `${process.env.REACT_APP_API_URL}/api/promotion/:promotion`,
    },
    Reward: {
      Index: `${process.env.REACT_APP_API_URL}/api/prize`,
      Store: `${process.env.REACT_APP_API_URL}/api/prize/:prize/choose`,
      Update: `${process.env.REACT_APP_API_URL}/api/prize/:prize`,
    },
    Thread: {
      Index: `${process.env.REACT_APP_API_URL}/api/thread`,
      GetFile: `${process.env.REACT_APP_API_URL}/api/thread/:thread/message/:message/attachment/:attachment`,
      Show: `${process.env.REACT_APP_API_URL}/api/thread/:thread/message`,
      Store: `${process.env.REACT_APP_API_URL}/api/thread`,
      StoreMessage: `${process.env.REACT_APP_API_URL}/api/thread/:thread/message`,
      Unread: `${process.env.REACT_APP_API_URL}/api/thread_unread`,
    },
    User: {
      Fill: `${process.env.REACT_APP_API_URL}/api/profile`,
      Show: `${process.env.REACT_APP_API_URL}/api/profile`,
      Store: `${process.env.REACT_APP_API_URL}/api/register`,
      Update: `${process.env.REACT_APP_API_URL}/api/profile`,
    },
    Scope: {
      Index: `${process.env.REACT_APP_API_URL}/api/scope`,
    },
    UserAvatar: {
      Store: `${process.env.REACT_APP_API_URL}/api/user-avatar/:user/create`,
      Destroy: `${process.env.REACT_APP_API_URL}/api/user-destroy/:user/delete`,
      Update: `${process.env.REACT_APP_API_URL}/api/user-avatar/:user/edit`,
    },
    UserSource: {
      Index: `${process.env.REACT_APP_API_URL}/api/user_source`,
    },
    WorkScope: {
      Index: `${process.env.REACT_APP_API_URL}/api/work_scope`,
    },
  },
};


export default Entrypoint;
