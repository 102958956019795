import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";
import BuildingForm from "../../components/building-form/BuildingForm";
import BuildingsCard from "../../components/buildings-card/BuildingsCard";
import ButtonSecondaryForward from "../../components/buttons/ButtonSecondaryForward";
import InfoBuildingCreated from "../../components/infobox/InfoBuildingCreated";
import InfoBuildingUpdate from "../../components/infobox/InfoBuildingUpdate";
import InfoEmptyContent from "../../components/infobox/InfoEmptyContent";
import LoadingData from "../../components/loading-data/LoadingData";
import ModalBuildingFilter from "../../components/modal/ModalBuildingFilter";
import ModalBuildingFinish from "../../components/modal/ModalBuildingFinish";
import ModalBuildingStart from "../../components/modal/ModalBuildingStart";
import Wrapper from "../../components/wrapper/Wrapper";
import { DataBuildingSort } from "../../data/DataBuildingSort";
import InputSearch from "../../form/controls/InputSearch";
import Select from "../../form/internals/Select";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import Subpage from "../../layout/Subpage";
import { ROUTE_HOME } from "../../routing/routes";
import {
  setFiltersInfo,
  fetchBuildingSearch,
  fetchBuildingInfinite,
  fetchBuilding,
} from "../../store/BuildingReducer";
import debounce from "../../utilities/debounce";
import ModalBuildingMap from "../../components/modal/ModalBuildingMap";
import PageHeader from "../../components/page-header/PageHeader";
import ModalBuildingOffered from "../../components/modal/ModalBuildingOffered";

const FiltersWrapper = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_big}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    flex-direction: row;
    padding-bottom: 18px;
    margin-bottom: 18px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }
`;

const InputsFiltersWrapper = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_big}) {
    display: flex;
  }
`;

const SearchForm = styled.form`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_big}) {
    margin-right: 18px;
    width: 50%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 400px;
  }
`;

const SelectWrapper = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_big}) {
    width: 50%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 240px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  a,
  button {
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_big}) {
    padding-bottom: 18px;
    margin-bottom: 18px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    justify-content: flex-start;
    a,
    button {
      width: 200px;
      margin-right: 8px;
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
`;

const BuildingsWrapper = styled.div`
  margin-top: 24px;
  padding-bottom: 24px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_big}) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Page = styled.div`
  width: 100%;
  transition: 0.3s all ease;

  &.active {
    transform: translate3d(-100%, 0, 0);
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    &.active {
      transform: none;
    }
  }
`;

const FiltersInfo = styled.div`
  width: 16px;
  height: 16px;
  font-size: 11px;
  font-weight: 600;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 50%;
  margin-left: 8px;
  margin-bottom: 8px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const BuildingsIndexPage = () => {
  const [filtersActive, setFiltersActive] = useState<boolean>(false);
  const subpage = useAppSelector((state) => state.subpage.status);
  const buildings = useAppSelector((state) => state.building);
  const globalSwipe = useAppSelector((state) => state.modal.status);
  const startBuildingModal = useAppSelector((state) => state.modalBuilding.statusStart);
  const finishBuildingModal = useAppSelector((state) => state.modalBuilding.statusFinish);
  const offeredBuildingModal = useAppSelector((state) => state.modalBuilding.offeredBuildingModal);
  const mapBuildingModalStatus = useAppSelector((state) => state.modalBuildingMap.status);
  const buildingFormStatus = useAppSelector((state) => state.buildingForm.status);
  const filtersInfo = useAppSelector((state) => state.building.filtersInfo);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  const handleFiltersActive = () => {
    setFiltersActive(true);
  };

  const buildingSort = (value: string) => {
    const option = DataBuildingSort.find((option: any) => option.value === value);

    dispatch(setFiltersInfo(false));
    dispatch(
      fetchBuildingSearch({
        "sort[column]": value,
        "sort[direction]": option?.direction,
      })
    );
  };

  const buildingSearch = debounce((event) => {
    dispatch(fetchBuildingSearch({ search: event.target.value }));
    dispatch(setFiltersInfo(false));
  }, 500);

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      setTimeout(() => {
        dispatch(fetchBuilding());
        setLoading(false);
      }, 500);
    }

    return () => {
      isSubscribed = false;
    };
  }, [fetchBuilding]);

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => {
      if (window.innerWidth < 860 && document.documentElement.scrollTop === 0) {
        setLoading(true);
        setTimeout(() => {
          dispatch(fetchBuilding());
          setLoading(false);
        }, 400);
      }
    },
    delta: 10,
    trackTouch: !globalSwipe,
    trackMouse: true,
  });

  return (
    <>
      <Page {...swipeHandlers} className={subpage ? "active" : ""}>
        <PageHeader>Budowy</PageHeader>
        <Wrapper>
          <FiltersWrapper>
            <ButtonsWrapper>
              {/*<ButtonPrimaryAdd to={ROUTE_BUILDING_CREATE} state={{subpage: true}}>Dodaj budowę</ButtonPrimaryAdd>*/}
              <ButtonSecondaryForward type="button" onClick={handleFiltersActive}>
                Filtruj listę budów
                {filtersInfo && <FiltersInfo>!</FiltersInfo>}
              </ButtonSecondaryForward>
            </ButtonsWrapper>
            <InputsFiltersWrapper>
              <SearchForm>
                <InputSearch
                  name="search"
                  placeholder="Szukaj"
                  onChange={(event) => buildingSearch(event)}
                />
              </SearchForm>
              <SelectWrapper>
                <Select
                  onChange={(event) => buildingSort(event.target.value)}
                  defaultValue={"DEFAULT"}>
                  <option value="DEFAULT" disabled>
                    Sortuj..
                  </option>
                  {DataBuildingSort.map((option, i) => (
                    <option value={option.value} key={i}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </SelectWrapper>
            </InputsFiltersWrapper>
          </FiltersWrapper>
          {!loading ? (
            <BuildingsWrapper>
              {buildings.data && buildings.data.length > 0 ? (
                <div className="wrapper-to-block-scroll">
                  <InfiniteScroll
                    dataLength={buildings.data.length}
                    next={() => dispatch(fetchBuildingInfinite(buildings.next_page))}
                    hasMore={true}
                    loader={null}
                    style={{ display: "flex", flexWrap: "wrap" }}>
                    {buildings.data.length > 0 &&
                      buildings.data.map((building, i: number) => (
                        <BuildingsCard data={building} key={i} />
                      ))}
                  </InfiniteScroll>
                </div>
              ) : (
                <InfoEmptyContent>Brak budów</InfoEmptyContent>
              )}
            </BuildingsWrapper>
          ) : (
            <LoadingData />
          )}
        </Wrapper>
        <ModalBuildingFilter
          activeClass={filtersActive}
          closeModal={() => setFiltersActive(false)}
        />
      </Page>

      {/*subpage - create / show*/}
      <Subpage parentRoute={ROUTE_HOME} />

      {/*modal with create/edit building form*/}
      {buildingFormStatus && <BuildingForm />}

      {/*accept building modal from building show page*/}
      <ModalBuildingStart state={startBuildingModal} />

      {/*finish building modal from building show page*/}
      <ModalBuildingFinish state={finishBuildingModal} />

      {/*modal with information about offered building*/}
      <ModalBuildingOffered state={offeredBuildingModal} />

      {/*modal with information about create a building*/}
      {buildings.buildingCreateInfo && <InfoBuildingCreated />}

      {/*modal with information about update a building*/}
      {buildings.buildingUpdateInfo && <InfoBuildingUpdate />}

      {/*modal with google-maps view*/}
      {mapBuildingModalStatus && <ModalBuildingMap data={buildings.data} />}
    </>
  );
};

export default BuildingsIndexPage;
