import styled from "styled-components";


const Header = styled.h2`
    font-size: ${props => props.theme.fonts.mobile.md};
    font-weight: ${props => props.theme.weights.semiBold};
    line-height: 1.2;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: ${props => props.theme.fonts.desktop.sm};

    }
`;

export default Header;
