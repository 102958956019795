import styled from "styled-components";


const Paragraph = styled.p`
    font-size: ${props => props.theme.fonts.mobile.sm};
    font-weight: ${props => props.theme.weights.light};
    line-height: 1.8;

    a {
        color: ${props => props.theme.colors.primary};
        text-decoration: underline !important;
    }
    @media screen and (min-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: ${props => props.theme.fonts.desktop.sm};

    }
`;

export default Paragraph;
