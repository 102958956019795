import React, {FunctionComponent} from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {FieldProps, Field} from 'formik';
import FormGroup from '../internals/FormGroup';
import Label from '../internals/Label';
import styled from 'styled-components';


const CKEditorWrapper = styled.div`
    .ck {
        .ck-content {
            min-height: 120px;
            width: 100%;
            font-size: ${props => props.theme.fonts.mobile.sm};
            padding: 2px 16px;
            border-color: ${({theme}) => theme.colors.primary} !important;
            border-bottom-right-radius: 5px !important;
            border-bottom-left-radius: 5px !important;
            box-shadow: none !important;

            p,
            li {
                line-height: 1.2;
            }

            li {
                &:not(:last-child) {
                    margin-bottom: 4px;
                }
            }

            ol {
                padding-left: 10px;
            }

            ul,
            ol {
                margin: 6px 0;
            }

            ul {
                li {
                    position: relative;
                    padding-left: 10px;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 5px;
                        left: 0;
                        width: 4px;
                        height: 4px;
                        background-color: ${({theme}) => theme.colors.primary};
                        border-radius: 50%;

                    }
                }
            }
        }

        .ck-toolbar {
            border-color: ${({theme}) => theme.colors.primary} !important;
            height: 36px;
            padding-top: 6px;
            padding-bottom: 6px;
            border-top-right-radius: 5px !important;
            border-top-left-radius: 5px !important;
        }
    }
`;

interface Props {
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

const InputEditor: FunctionComponent<Props> = (props) => {
  return (
    <FormGroup>
      <Label required={props.required}>{props.label}</Label>
      <Field name={props.name}>
        {({field, form}: FieldProps<number | string>) => {
          return (
            <CKEditorWrapper>
              <CKEditor
                editor={ClassicEditor}
                data={field.value ? field.value : ''}
                config={{
                  toolbar: [
                    'bold', 'italic', '|',
                    'bulletedList', 'numberedList', '|',
                  ],
                }}
                onChange={(event: any, editor: any) => {
                  form.setFieldValue(field.name, editor.getData());
                }}
              />
            </CKEditorWrapper>
          );
        }}
      </Field>
    </FormGroup>
  );
};

export default InputEditor;
