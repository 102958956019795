import {PayloadAction}    from "@reduxjs/toolkit";
import {createSlice}      from "@reduxjs/toolkit";
import {createAsyncThunk} from "@reduxjs/toolkit";
import Api                from "../api/Api";
import Notification       from "../api/entities/Notification";


const client = new Api();

interface State {
  current_page: number;
  next_page: number;
  last_page: number;
  data: Notification[] | undefined,
  total: number;
}

const initialState: State = {
  current_page: 0,
  next_page:    0,
  last_page:    0,
  data:         [],
  total:        0,
};

export const fetchNotificationInfinite = createAsyncThunk("notifications/infinite", async (page: number, {getState}) => {
  const state: any = getState();
  const response   = await client.Notification.List({page: page});

  return {
    data:         [...state.notification.data, ...response.data.data],
    current_page: response.data.current_page,
    next_page:    response.data.current_page + 1,
  };
});

export const fetchNotificationTotal = createAsyncThunk("notifications/total", async () => {
  const response = await client.Notification.Total();

  return response.data;
});

export const fetchNotification = createAsyncThunk("notifications ", async () => {
  const response = await client.Notification.List();

  return response.data;
});

export const notificationSlice = createSlice({
  name:          "notifications",
  initialState,
  reducers:      {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchNotification.fulfilled, (state: State, action: PayloadAction<any>) => {
      state.data         = action.payload.data;
      state.current_page = action.payload.current_page;
      state.next_page    = action.payload.current_page + 1;
    });
    builder.addCase(fetchNotificationInfinite.fulfilled, (state: State, action: PayloadAction<any>) => {
      state.data         = action.payload.data;
      state.current_page = action.payload.current_page;
      state.next_page    = action.payload.current_page + 1;
    });
    builder.addCase(fetchNotificationTotal.fulfilled, (state: State, action: PayloadAction<any>) => {
      state.total = action.payload;
    });
  },
});

export default notificationSlice.reducer;
