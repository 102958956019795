import React              from "react";
import styled             from "styled-components";
import {useServiceWorker} from "../../hooks/useServiceWorker";
import ButtonPrimary      from "../buttons/ButtonPrimary";
import Paragraph          from "../typography/Paragraph";


const CardWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #BBD4FA;
    }
`;

const ResetAppButton = () => {
  const {reloadPage} = useServiceWorker();

  return (
    <CardWrapper>
      <Paragraph>Zresetuj aplikację</Paragraph>
      <ButtonPrimary type="button" onClick={reloadPage}>Zresetuj</ButtonPrimary>
    </CardWrapper>
  );
};

export default ResetAppButton;
