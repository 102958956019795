import {createSlice} from "@reduxjs/toolkit";


interface NavbarState {
  status: boolean;
}

const initialState: NavbarState = {
  status: true,
};

export const navbarSlice = createSlice({
  name:     "navbar",
  initialState,
  reducers: {
    showNavbar: state => {
      state.status = true;
    },
    hideNavbar: state => {
      state.status = false;
    },
  },
});

export const {showNavbar, hideNavbar} = navbarSlice.actions;

export default navbarSlice.reducer;
