import {useField}          from "formik";
import {FunctionComponent} from "react";
import React               from "react";
import FormGroup           from "../internals/FormGroup";
import Input               from "../internals/Input";
import Label               from "../internals/Label";
import InputError          from "../validation/inputError";


interface Props {
  name: string;
  label: string;
  required?: boolean;
}

const InputPassword: FunctionComponent<Props> = (props) => {
  const [field, meta] = useField(props.name);
  return (
    <FormGroup className={meta.error && "validation-error"}>
      <Label required={props.required}>{props.label}</Label>
      <Input type="password" name={props.name}/>
      {(meta.error) && <InputError>{meta.error}</InputError>}
    </FormGroup>
  );
};

InputPassword.defaultProps = {
  required: false,
};

export default InputPassword;
