import axios          from "axios";
import Entrypoint     from "../Entrypoint";
import AbstractModule from "./AbstractModule";


class PointApi extends AbstractModule {
  Get() {
    return axios.get(Entrypoint.Api.Points.All);
  }
}

export default PointApi;
