import {enableBodyScroll}          from "body-scroll-lock";
import {disableBodyScroll}         from "body-scroll-lock";
import {FunctionComponent}         from "react";
import {useEffect}                 from "react";
import {useRef}                    from "react";
import React                       from "react";
import {useNavigate}               from "react-router";
import {useSwipeable}              from "react-swipeable";
import {CSSTransition}             from "react-transition-group";
import styled                      from "styled-components";
import useApi                      from "../../hooks/useApi";
import {useAppDispatch}            from "../../hooks/useAppDispatch";
import {useAppSelector}            from "../../hooks/useAppSelector";
import useClickOutside             from "../../hooks/useClickOutside";
import useKeyPress                 from "../../hooks/useKeyPress";
import {ROUTE_HOME}                from "../../routing/routes";
import {fetchBuilding}             from "../../store/BuildingReducer";
import {showInfoAboutFinish}       from "../../store/ModalBuildingReducer";
import {hideFinishModal}           from "../../store/ModalBuildingReducer";
import {enableGlobalSwipe}         from "../../store/ModalReducer";
import {blockGlobalSwipe}          from "../../store/ModalReducer";
import {setSubpage}                from "../../store/SubpageReducer";
import ButtonPrimaryForward        from "../buttons/ButtonPrimaryForward";
import ButtonSecondaryClose        from "../buttons/ButtonSecondaryClose";
import InfoBuildingStatus          from "../infobox/InfoBuildingStatus";
import Header                      from "../typography/Header";
import {defaultModalCloseStyles}   from "./defaultModalStyles";
import {defaultModalWrapperStyles} from "./defaultModalStyles";
import {defaultModalStyles}        from "./defaultModalStyles";


const Container = styled.div`
    ${defaultModalStyles};
    opacity: 1;
    visibility: visible;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        align-items: center;
    }
`;

const Wrapper = styled.div`
    ${defaultModalWrapperStyles}
`;

const CloseModal = styled.button`
    ${defaultModalCloseStyles};
`;

const ModalButtonsWrapper = styled.div`
    display: flex;
    padding-top: 12px;
    width: 100%;
    justify-content: center;

    button {
        width: 140px;
        margin: 0 4px;
    }
`;

const StyledHeader = styled(Header)`
    width: 100%;
    margin: 12px 0 18px 0;
    font-size: ${props => props.theme.fonts.mobile.lg};
    font-weight: ${props => props.theme.weights.medium};
    text-align: center;
`;


interface Props {
  state: boolean;
}

const ModalBuildingFinish: FunctionComponent<Props> = ({state}) => {
  const Api         = useApi();
  const ref         = useRef<any>();
  const refWrapper  = useRef<any>();
  const dispatch    = useAppDispatch();
  const buildingId  = useAppSelector(state => state.modalBuilding.buildingId);
  const wrapperInfo = useAppSelector(state => state.modalBuilding.showInfoAboutFinish);
  const navigate    = useNavigate();

  useEffect(() => {
    if (state) {
      disableBodyScroll(ref.current);
      dispatch(blockGlobalSwipe());
    }
  }, [state]);

  const buildingFinish = () => {
    if (buildingId) {
      Api.Building.Finish(buildingId).then(() => {
        dispatch(hideFinishModal());
        dispatch(fetchBuilding());
        handleClose();
        dispatch(showInfoAboutFinish(true));
      });
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => {
      if (window.innerWidth < 860) {
        handleClose();
      }
    },
    delta:        30,
    trackTouch:   true,
    trackMouse:   true,
  });

  const refPassThrough = (el: any) => {
    swipeHandlers.ref(el);
    ref.current = el;
  };

  const handleClose = () => {
    enableBodyScroll(ref.current);
    dispatch(enableGlobalSwipe());
    dispatch(hideFinishModal());
  };

  useClickOutside(refWrapper, handleClose);

  useKeyPress(handleClose);

  const handleInfoClose = () => {
    dispatch(showInfoAboutFinish(false));
    dispatch(setSubpage(false));
    navigate(ROUTE_HOME);
  };

  return (
    <>
      {
        !wrapperInfo ? (
          <CSSTransition
            in={state}
            timeout={200}
            classNames="modal-fade"
            unmountOnExit
          >
            <Container {...swipeHandlers} ref={refPassThrough}>
              <Wrapper className="modal-wrapper" ref={refWrapper}>
                <CloseModal onClick={handleClose}/>
                <StyledHeader>
                  Czy na pewno chcesz zakończyć budowę?
                </StyledHeader>
                <ModalButtonsWrapper>
                  <ButtonPrimaryForward onClick={buildingFinish}>Tak</ButtonPrimaryForward>
                  <ButtonSecondaryClose onClick={handleClose}>Nie</ButtonSecondaryClose>
                </ModalButtonsWrapper>
              </Wrapper>
            </Container>
          </CSSTransition>
        ) : (
          <InfoBuildingStatus handleClose={handleInfoClose}>
            Budowa została zakończona
          </InfoBuildingStatus>
        )
      }
    </>
  );
};

export default ModalBuildingFinish;
