import axios             from "axios";
import {generatePath}    from "react-router";
import Reward            from "../entities/Reward";
import Entrypoint        from "../Entrypoint";
import {ResponsePromise} from "../ResponsePromise";


class RewardApi {
  List(page?: any): ResponsePromise<{ current_page: number; data: Reward[] }> {
    return axios.get(Entrypoint.Api.Reward.Index, {
      params: {
        ...page,
      },
    });
  }

  Update(prize: any) {
    return axios.patch(generatePath(Entrypoint.Api.Reward.Update, {prize}));
  }

  Store(prize: any) {
    return axios.post(generatePath(Entrypoint.Api.Reward.Store, {prize}));
  }
}

export default RewardApi;
