import {FunctionComponent} from "react";
import React               from "react";
import styled              from "styled-components";
import MagnifierIcon       from "../../assets/icons/magnifier.svg";
import FormGroup           from "../internals/FormGroup";
import {defaultStyles}     from "../internals/Input";
import Label               from "../internals/Label";


interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  onChange: (event: any) => void;
}

const StyledInput = styled.input`
    ${defaultStyles};
    padding-left: 42px;
    background-image: url(${MagnifierIcon});
    background-repeat: no-repeat;
    background-position: 16px 50%;
    background-size: 15px;

    &::placeholder {
        color: ${props => props.theme.colors.secondary};
    }
`;

const InputSearch: FunctionComponent<Props> = ({...props}) => {
  return (
    <FormGroup>
      {props.label && <Label>{props.label}</Label>}
      <StyledInput type="text" {...props}/>
    </FormGroup>
  );
};

export default InputSearch;
