import axios             from "axios";
import SignOut           from "../entities/SignOut";
import Entrypoint        from "../Entrypoint";
import {ResponsePromise} from "../ResponsePromise";


class SignOutApi {
  SignOut(): ResponsePromise<SignOut> {
    return axios.get(Entrypoint.Api.Auth.SignOut);
  }
}

export default SignOutApi;
