import styled       from "styled-components";
import CalendarIcon from "../../assets/icons/calendar.svg";
import Paragraph    from "./Paragraph";


export const Date = styled(Paragraph)`
    margin: 12px 0;
    padding-left: 24px;
    position: relative;
    line-height: 1.4;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 12px;
        height: 14px;
        background-image: url(${CalendarIcon});
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
`;

export default Date;
