import {matchRoutes}       from "react-router";
import {useNavigate}       from "react-router";
import {useLocation}       from "react-router-dom";
import styled              from "styled-components";
import {useAppDispatch}    from "../../hooks/useAppDispatch";
import {useAppSelector}    from "../../hooks/useAppSelector";
import {ROUTE_THREAD}      from "../../routing/routes";
import {ROUTE_THREAD_SHOW} from "../../routing/routes";
import {toggleMenu}        from "../../store/MenuReducer";


const HamburgerBox = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        display: none;
    }
`;

const HamburgerWrapper = styled.button`
    width: 22px;
    height: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
    border: transparent;
    transition: .2s transform ease;
    transform-origin: center;

    span {
        background-color: ${props => props.theme.colors.white};
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 10px;
        transition: .3s all ease;
    }

    &.active {
        transform: rotate(45deg);

        span {
            &:nth-child(1) {
                transform: translateY(8px);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                transform: translateY(-6px) translateX(0px) rotate(90deg);
            }
        }
    }

    &.subpage-active {
        position: relative;

        span {
            position: absolute;

            &:nth-child(1) {
                top: 1px;
                transform: rotate(-36deg);
                width: 50%;
            }

            &:nth-child(2) {
                top: 50%;
                transform: translateY(-50%);
            }

            &:nth-child(3) {
                top: 9px;
                transform: rotate(36deg);
                width: 50%;
            }
        }
    }
`;

const Hamburger = () => {
  const status        = useAppSelector(state => state.menu.status);
  const dispatch      = useAppDispatch();
  const navigate      = useNavigate();
  const location      = useLocation();
  const subpageStatus = location.state as { subpage: boolean | undefined };
  const parentRoute   = useAppSelector(state => state.parentRoute.route);
  const threadMatch   = matchRoutes([{path: ROUTE_THREAD_SHOW}], location.pathname);

  return (
    <HamburgerBox onClick={() => {
      subpageStatus ? navigate(threadMatch ? ROUTE_THREAD : parentRoute) : dispatch(toggleMenu(!status));
    }}>
      <HamburgerWrapper className={subpageStatus ? "subpage-active" : ""}>
        <span/>
        <span/>
        <span/>
      </HamburgerWrapper>
    </HamburgerBox>
  );
};

export default Hamburger;
