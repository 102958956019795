import {configureStore} from '@reduxjs/toolkit';
import buildingFormReducer from './store/BuildingForm';
import buildingJobReducer from './store/BuildingJobReducer';
import buildingReducer from './store/BuildingReducer';
import locationReducer from './store/LocationReducer';
import menuReducer from './store/MenuReducer';
import messageReducer from './store/MessageReducer';
import modalBuildingReducer from './store/ModalBuildingReducer';
import modalErrorAppReducer from './store/ModalErrorApp';
import modalReducer from './store/ModalReducer';
import NavbarReducer from './store/NavbarReducer';
import notificationReducer from './store/NotificationReducer';
import parentRouteReducer from './store/ParentRoute';
import rewardReducer from './store/RewardReducer';
import subpageReducer from './store/SubpageReducer';
import threadReducer from './store/ThreadReducer';
import tooltipReducer from './store/TooltipReducer';
import updateReducer from './store/UpdateReducer';
import userReducer from './store/UserReducer';
import userReducerNew from './store/UserReducerNew';
import modalHistoryReducer from './store/ModalHistoryReducer';
import ModalBuildingMapReducer from './store/ModalBuildingMapReducer';


const store = configureStore({
  reducer: {
    building: buildingReducer,
    buildingForm: buildingFormReducer,
    buildingJob: buildingJobReducer,
    location: locationReducer,
    newUser: userReducerNew,
    menu: menuReducer,
    message: messageReducer,
    modal: modalReducer,
    modalBuilding: modalBuildingReducer,
    modalBuildingMap: ModalBuildingMapReducer,
    modalHistory: modalHistoryReducer,
    modalErrorApp: modalErrorAppReducer,
    navbar: NavbarReducer,
    notification: notificationReducer,
    parentRoute: parentRouteReducer,
    reward: rewardReducer,
    subpage: subpageReducer,
    thread: threadReducer,
    tooltip: tooltipReducer,
    update: updateReducer,
    user: userReducer, // to remove
  },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;

export default store;
