import React                   from "react";
import styled                  from "styled-components";
import PageHeader              from "../components/page-header/PageHeader";
import InfoAboutUpdate         from "../components/settings/InfoAboutUpdate";
import PushNotificationsToggle from "../components/settings/PushNotificationsToggle";
import ResetAppButton          from "../components/settings/ResetAppButton";
import Wrapper                 from "../components/wrapper/Wrapper";



const StyledWrapper = styled(Wrapper)`
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        max-width: 600px;
        border-radius: 8px;
        height: auto;
        padding: 48px 36px;
    }
`;


const SettingsPage = () => {

  return (
    <div>
      <PageHeader>Ustawienia</PageHeader>

      <StyledWrapper>
        {/* information about new update, shows when user decline update from update info modal*/}
        <InfoAboutUpdate/>

        {/* turn on/off push notifications*/}
        {"Notification" in window && <PushNotificationsToggle/>}

        {/* button to reset application, reset service worker */}
        <ResetAppButton/>
      </StyledWrapper>
    </div>

  );
};

export default SettingsPage;
