import axios             from "axios";
import {generatePath}    from "react-router";
import Entrypoint        from "../Entrypoint";
import {ResponsePromise} from "../ResponsePromise";


class NotificationApi {
  List(page?: any): ResponsePromise<{ current_page: number, total: number, data: any }> {
    return axios.get(Entrypoint.Api.Notification.Index, {
      params: {
        ...page,
      },
    });
  }

  Delete(notification: string): ResponsePromise<{ data: any }> {
    return axios.delete(generatePath(Entrypoint.Api.Notification.Delete, {notification}));
  }

  Get(notification: string): ResponsePromise<{ data: any }> {
    return axios.get(generatePath(Entrypoint.Api.Notification.Get, {notification}));
  }

  Patch(): ResponsePromise<{ data: any }> {
    return axios.patch(generatePath(Entrypoint.Api.Notification.Patch));
  }

  Total(): ResponsePromise<number> {
    return axios.get(Entrypoint.Api.Notification.Total);
  }
}


export default NotificationApi;
