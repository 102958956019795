import axios             from "axios";
import SignIn            from "../entities/SignIn";
import Entrypoint        from "../Entrypoint";
import {ResponsePromise} from "../ResponsePromise";

class SignInApi {
  SignIn(data: any): ResponsePromise<SignIn> {
    return axios.post(Entrypoint.Api.Auth.SignIn, data);
  }

  Facebook(data: any): ResponsePromise<SignIn> {
    return axios.post(Entrypoint.Api.Auth.SignIn, data);
  }
}

export default SignInApi;
