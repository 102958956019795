const MainTheme = {
  colors:           {
    primary:        "#2AB7F8",
    primaryLight:   "rgba(3, 113, 242, 0.11)",
    secondary:      "#082858",
    secondaryLight: "rgba(8,40,88, 0.7)",
    success:        "#107c10",
    successLight:   "#dff6dd",
    danger:         "#dc3545",
    dangerLight:    "#F6CACE",
    warning:        "#d09c04",
    warningLight:   "#f6e4c0",
    gray:           "#8c8c8c",
    grayLight:      "#e8e8e8",
    offered:        "#ff5c1b",
    offeredLight:   "#ffd8c4",
    started:        "#a23eff",
    startedLight:   "#ead8f8",
    border:         "#E3EEFF",
    white:          "#ffffff",
    card:           "#f8f8f8",

  },
  shadow:           {
    primary:   "0 0 8px -3px rgba(42,168,248, 0.5)",
    secondary: "0 0 8px -3px rgba(8,40,88, 0.6)",
    success:   "0 0 8px -3px rgba(16, 124, 16. 0.5)",
    started:   "0 0 8px -3px rgba(162,62,255, 0.5)",
    danger:    "0 0 8px -3px rgba(220, 53, 69, 0.5)",
    reported:  "0 0 8px -3px rgba(208,156,4, 0.5)",
    offered:   "0 0 8px -3px rgba(255,92,27, 0.5)",
    gray:      "0 0 8px -3px rgba(140, 140, 140, 0.5)",
  },
  fonts:            {
    mobile:  {
      xl: "18px",
      lg: "16px",
      md: "14px",
      sm: "12px",
      xs: "10px",
    },
    tablet:  {
      xl: "18px",
      lg: "16px",
      md: "14px",
      sm: "12px",
      xs: "10px",
    },
    desktop: {
      xl: "20px",
      lg: "18px",
      md: "16px",
      sm: "12px",
      xs: "12px",
    },
  },
  weights:          {
    bold:     700,
    semiBold: 600,
    medium:   500,
    regular:  400,
    light:    300,

  },
  breakpoints:      {
    tablet_small: "575px",
    tablet:       "800px",
    tablet_big:   "1023px",
    laptop:       "1279px",
    laptop_hd:    "1439px",
  },
  layout_part_size: {
    mobile:       {
      menu:               "calc(100vw - 48px)",
      page_header:        "60px",
      navbar:             "60px",
      menu_bottom:        "70px",
      menu_bottom_mobile: "80px",
      message_form:       "80px",
      wrapper:            "575px",

    },
    tablet_small: {
      menu:               "380px",
      page_header:        "60px",
      navbar:             "60px",
      menu_bottom:        "60px",
      menu_bottom_mobile: "80px",
      message_form:       "60px",
    },
    tablet:       {
      menu:               "260px",
      page_header:        "60px",
      navbar:             "60px",
      menu_bottom:        "12px",
      menu_bottom_mobile: "12px",
      message_form:       "60px",
    },
    laptop_hd:    {
      menu:               "320px",
      page_header:        "60px",
      navbar:             "60px",
      menu_bottom:        "60px",
      menu_bottom_mobile: "80px",
      message_form:       "60px",
      wrapper:            "1920px",
    },
  },
};

export type MainThemeType = typeof MainTheme;
export default MainTheme;
