import {css}           from "styled-components";
import ChevronLeftIcon from "../assets/icons/chevron-left.svg";


export const DefaultPageBackButton = css`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.primary};
    background-image: url(${ChevronLeftIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 6px;
    position: absolute;
    top: 18px;
    left: 12px;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 20px;
        height: 20px;
        background-size: 5px;
    }
`;
