import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import React, { useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import ArrowTrendIcon from '../../assets/icons/arrow-treng.svg';
import BellIcon from '../../assets/icons/bell.svg';
import BlankFileIcon from '../../assets/icons/blank-file.svg';
import CloseIcon from '../../assets/icons/close.svg';
import CoinIcon from '../../assets/icons/coin.svg';
import EnvelopeIcon from '../../assets/icons/envelope.svg';
import FileIcon from '../../assets/icons/file.svg';
import PrizeIcon from '../../assets/icons/prize.svg';
import SlidersIcon from '../../assets/icons/sliders.svg';
import UserIcon from '../../assets/icons/user.svg';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { ROUTE_ARTICLES, ROUTE_BUILDING_JOBS, ROUTE_HOME, ROUTE_NOTIFICATIONS, ROUTE_POINTS, ROUTE_PROMOTIONS, ROUTE_REWARDS, ROUTE_SETTINGS, ROUTE_THREAD, ROUTE_USER } from '../../routing/routes';
import { hideMenu } from '../../store/MenuReducer';
import MenuItem from '../menu/MenuItem';
import MenuLogout from './MenuLogout';
import MenuUser from './MenuUser';


const MenuListContainer = styled.aside`
    position: fixed;
    top: 0;
    left: 0;
    width: ${({ theme }) => theme.layout_part_size.mobile.menu};
    height: 100vh;
    background-color: ${props => props.theme.colors.white};
    overflow: auto;
    padding: 24px 24px 96px 24px;
    transition: .3s transform ease;
    transform: translateX(-100%);
    z-index: 160;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.active {
        transform: translateX(0);
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_small}) {
        width: ${({ theme }) => theme.layout_part_size.tablet_small.menu};
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        transform: none !important;
        width: ${({ theme }) => theme.layout_part_size.tablet.menu};
        height: calc(100vh - ${({ theme }) => theme.layout_part_size.tablet.navbar});
        top: ${({ theme }) => theme.layout_part_size.tablet.navbar};
        padding: 36px 24px;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop_hd}) {
        width: ${({ theme }) => theme.layout_part_size.laptop_hd.menu};
    }
`;

const MenuListWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const StyledUl = styled.ul`
    flex: 1;
    margin: 24px 0;
    padding: 24px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

const CloseModal = styled.button`
    width: 16px;
    height: 16px;
    background-image: url(${CloseIcon});
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 18px;
    right: 24px;
    border: none;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        display: none;
    }
`;

const EuropeanFunds = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 48px 0;

    img {
        width: 64px;
    }
`;

const MenuBox = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(state => state.menu.status);
  const menuRef = useRef<any>();
  const notificationCount = useAppSelector(state => state.notification.total);
  const threadUnreadCount = useAppSelector(state => state.thread.threadsUnread);
  const notificationUpdate = useAppSelector(state => state.update.notification);
  const user = useAppSelector(state => state.newUser.user);

  const dataMenu = [
    {
      icon: ArrowTrendIcon,
      route: ROUTE_HOME,
      title: 'Budowy',
    },
    {
      icon: BlankFileIcon,
      route: ROUTE_ARTICLES,
      title: 'Aktualności',
    },
    {
      icon: CoinIcon,
      route: ROUTE_PROMOTIONS,
      title: 'Promocje',
    },
    {
      icon: ArrowTrendIcon,
      route: ROUTE_POINTS,
      title: 'Punkty',
    },
    {
      icon: PrizeIcon,
      route: ROUTE_REWARDS,
      title: 'Nagrody',
    },
    {
      icon: BellIcon,
      route: ROUTE_NOTIFICATIONS,
      title: 'Powiadomienia',
      notification: notificationCount,
    },
    {
      icon: FileIcon,
      route: ROUTE_BUILDING_JOBS,
      title: 'Tablica budów',
      hide: user && user.type !== 'Paver',
    },
    {
      icon: UserIcon,
      route: ROUTE_USER,
      title: 'Twoje konto',
    },
    {
      icon: SlidersIcon,
      route: ROUTE_SETTINGS,
      title: 'Ustawienia',
      notification: notificationUpdate,
    },
    {
      icon: EnvelopeIcon,
      route: ROUTE_THREAD,
      title: 'Wiadomości',
      notification: threadUnreadCount,

    },
  ];

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => dispatch(hideMenu()),
    delta: 30,
    trackTouch: true,
    trackMouse: true,
  });

  const refPassThrough = (el: any) => {
    swipeHandlers.ref(el);
    menuRef.current = el;
  };

  useEffect(() => {
    if (status && menuRef.current) {
      menuRef.current.scrollTop = 0;
    }

    if (status) {
      disableBodyScroll(menuRef.current);
    } else {
      enableBodyScroll(menuRef.current);
      clearAllBodyScrollLocks();
    }

  }, [status]);

  return (
    <MenuListContainer {...swipeHandlers} className={status ? 'active' : ''} ref={refPassThrough}>
      <CloseModal onClick={() => dispatch(hideMenu())}/>
      <MenuUser/>
      <MenuListWrapper>
        <StyledUl>
          {
            dataMenu.map((element, i) => (
              <MenuItem key={i} element={element}/>
            ))
          }
        </StyledUl>
        <MenuLogout/>
        <EuropeanFunds>
          <img src="/eu/logo-fefr.jpeg" alt=""/>
          <img src="/eu/logo-pl.jpeg" alt=""/>
          <img src="/eu/logo-ppo.jpeg" alt=""/>
          <img src="/eu/logo-eu.jpeg" alt=""/>
        </EuropeanFunds>
      </MenuListWrapper>
    </MenuListContainer>
  );
};

export default MenuBox;
