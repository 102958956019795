import React                    from "react";
import {useNavigate}            from "react-router";
import styled                   from "styled-components";
import AddBuildingImage         from "../../assets/images/add_building.png";
import {useAppDispatch}         from "../../hooks/useAppDispatch";
import {ROUTE_HOME}             from "../../routing/routes";
import {showBuildingUpdateInfo} from "../../store/BuildingReducer";
import {fetchBuilding}          from "../../store/BuildingReducer";
import {setSubpage}             from "../../store/SubpageReducer";
import ButtonPrimaryBack        from "../buttons/ButtonPrimaryBack";
import Header                   from "../typography/Header";
import {defaultInfoHeader}      from "./defaultStyles";
import {defaultInfoWrapper}     from "./defaultStyles";
import {defaultInfoContainer}   from "./defaultStyles";


const InfoContainer = styled.div`
    ${defaultInfoContainer};
`;

const InfoWrapper = styled.div`
    ${defaultInfoWrapper};
    padding-top: 48px;
    padding-bottom: 48px;

    img {
        width: 180px;
        height: 180px;
    }
`;

const StyledHeaderInfo = styled(Header)<{ center?: boolean }>`
    ${defaultInfoHeader};
    margin-bottom: 24px;
`;


const InfoBuildingUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    dispatch(showBuildingUpdateInfo(false));
    dispatch(fetchBuilding());
    dispatch(setSubpage(false));
    navigate(ROUTE_HOME);
  };

  return (
    <InfoContainer>
      <InfoWrapper>
        <img src={AddBuildingImage} alt=""/>
        <StyledHeaderInfo>Zmiany zostały zapisane i przekazane do akceptacji.</StyledHeaderInfo>
        <ButtonPrimaryBack type="button" onClick={handleClick}>Powrót do listy</ButtonPrimaryBack>
      </InfoWrapper>
    </InfoContainer>
  );
};

export default InfoBuildingUpdate;
