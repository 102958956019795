import {removeNotificationSubscription}                                        from "../serviceWorkerRegistration";
import {createNotificationSubscription, getUserSubscription, sendNotification} from "../serviceWorkerRegistration";
import http                                                                    from "../utilities/http";


export default function usePushNotifications() {

  const checkUserSubscription = async () => {
    return await getUserSubscription();
  };

  const onClickSubscribeToPushNotification = () => {
    createNotificationSubscription()
      .then(function (subscription) {
        http
          .post("api/push/subscription", subscription)
          .then(function (response) {
          })
          .catch(err => {
            console.error(err);
          });
      })
      .catch(err => {
        console.error("Couldn't create the notification subscription", err, "name:", err.name, "message:", err.message, "code:", err.code);
      });
  };

  const onClickRemoveSubscription = () => {
    removeNotificationSubscription();
  };
  /**
   * define a click handler that request the push server to send a notification, passing the id of the saved subscription
   */
  const onClickSendNotification   = async () => {
    sendNotification();
  };

  /**
   * returns all the stuff needed by a Component
   */
  return {
    onClickSubscribeToPushNotification,
    onClickSendNotification,
    onClickRemoveSubscription,
    checkUserSubscription,
  };
}




