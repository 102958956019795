import {css}       from "styled-components";
import ChevronIcon from "../assets/icons/chevron-right-blue.svg";


export const DefaultLinkStyles = css`
    color: ${props => props.theme.colors.primary};
    text-decoration: underline !important;
    display: inline-block;
    font-size: ${props => props.theme.fonts.mobile.sm};

    &:after {
        content: '';
        display: inline-block;
        position: relative;
        margin-left: 6px;
        width: 8px;
        height: 8px;
        background-image: url(${ChevronIcon});
        background-repeat: no-repeat;
        background-size: contain;
    }
`;
