import {useField}          from "formik";
import {FunctionComponent} from "react";
import React               from "react";
import styled              from "styled-components";
import CalendarIcon        from "../../assets/icons/calendar.svg";
import FormGroup           from "../internals/FormGroup";
import Input               from "../internals/Input";
import Label               from "../internals/Label";
import InputError          from "../validation/inputError";


interface Props {
  name: string;
  label: string;
  required?: boolean;
}

const StyledInput = styled(Input)`
    padding-right: 48px;
    background-image: url(${CalendarIcon});
    background-repeat: no-repeat;
    background-color: #fff;
    background-position: calc(100% - 16px) 50%;
    background-size: 16px;
`;

const InputDate: FunctionComponent<Props> = (props) => {
  const [field, meta] = useField(props.name);
  return (
    <FormGroup className={meta.error && "validation-error"}>
      <Label required={props.required}>{props.label}</Label>
      <StyledInput type="date" name={props.name}/>
      {(meta.error) && <InputError>{meta.error}</InputError>}
    </FormGroup>
  );
};

InputDate.defaultProps = {
  required: false,
};

export default InputDate;
