import axios             from "axios";
import Entrypoint        from "../Entrypoint";
import SignUpRequest     from "../request/auth/SignUpRequest";
import {ResponsePromise} from "../ResponsePromise";


class SignUpApi {
  SingUp(data: SignUpRequest): ResponsePromise<any> {
    return axios.post(Entrypoint.Api.Auth.SignUp, data);
  }
}

export default SignUpApi;
