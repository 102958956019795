import {FunctionComponent}    from "react";
import React                  from "react";
import styled                 from "styled-components";
import Building               from "../../api/entities/Building";
import {useAppDispatch}       from "../../hooks/useAppDispatch";
import useHtmlParser          from "../../hooks/useHtmlParser";
import {showBuildingJobModal} from "../../store/BuildingJobReducer";
import BuildingStatus         from "../building-status/BuildingStatus";
import ButtonPrimaryForward   from "../buttons/ButtonPrimaryForward";
import HeaderSmall            from "../typography/HeaderSmall";
import Paragraph              from "../typography/Paragraph";


const CardWrapper = styled.div`
    border: 1px solid ${props => props.theme.colors.border};
    border-left: 4px solid ${props => props.theme.colors.primary};
    border-radius: 5px;
    padding: 16px 24px 18px 24px;
    display: block;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_big}) {
        width: calc(50% - 12px);
        margin-bottom: 24px;
        border-left: 1px solid ${props => props.theme.colors.border};
        box-shadow: 0 0 14px -8px rgb(42 183 248 / 45%);
        transition: .2s all ease;
        &:not(:nth-child(2n)) {
            margin-right: 24px;
        }

        &:hover {
            box-shadow: 0 0 24px -8px rgb(42 183 248 / 65%);
        }
    }
    @media screen and (min-width: ${({theme}) => theme.breakpoints.laptop_hd}) {
        width: calc(33.33% - 16px);

        &:not(:nth-child(2n)) {
            margin-right: 0;
        }

        &:not(:nth-child(3n)) {
            margin-right: 24px;
        }
    }
`;

const CardHeader = styled.div`
    position: relative;
    padding-right: 60px;
    margin-bottom: 12px;
`;

const CardStatusWrapper = styled.div`
    display: flex;
    padding-bottom: 8px;
`;

const CardNumber = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    font-size: ${props => props.theme.fonts.mobile.sm};
`;

const WorkScope = styled.div`
    display: flex;
    flex-wrap: wrap;

    p {
        margin-right: 6px;
    }
`;

const BuildingCardHeader = styled.div`
    margin-bottom: 16px;
`;

const BuildingCardLine = styled.div`
    &:not(:last-child) {
        margin-bottom: 6px;
    }
`;

const BuildingCardLineInline = styled(BuildingCardLine)`
    display: flex;
    justify-content: space-between;
`;


const StyledParagraph = styled(Paragraph)`
    line-height: 1.6;

    b {
        font-weight: 500;
        margin-bottom: 2px;
        display: block;
    }
`;

const BuildingAddressLine = styled.div`
    display: flex;
`;

const StyledHeaderSmall = styled(HeaderSmall)`
    margin-bottom: 2px;
`;

const ButtonWrapper = styled.div`
    margin-top: 24px;
`;

const IsWaitingInfo = styled.div`
    font-size: 12px;
    padding-top: 14px;
    color: ${({theme}) => theme.colors.secondary};
    border-top: 1px solid ${({theme}) => theme.colors.border};
    font-weight: 400;
`;

interface BuildingProps {
  data: Building;
}

const BuildingsJobCard: FunctionComponent<BuildingProps> = ({data}) => {
  const dispatch = useAppDispatch();

  return (
    <>
      {data && (
        <CardWrapper>
          <CardHeader>
            <CardStatusWrapper>
              {data.business_status && (<BuildingStatus status={data.paver_status}/>)}
              {data.is_offered && (<BuildingStatus status={data.is_offered ? "Do oddania" : null}/>)}
            </CardStatusWrapper>
            {data.project_nr && (<CardNumber>{data.project_nr}</CardNumber>)}
          </CardHeader>
          <div>
            <BuildingCardHeader>
              <StyledHeaderSmall>{data.title}</StyledHeaderSmall>
              {(data.firstname || data.lastname) && (<StyledParagraph>{data.firstname} {data.lastname}</StyledParagraph>)}
              <BuildingAddressLine>
                <Paragraph>
                  {data.street && (<>ul. {data.street} {data.building_nr}{data.apartment_nr && <>/{data.apartment_nr}</>}</>)}
                  {data.street && data.city && <>,&nbsp;</>}
                  {data.postcode && data.city && (<>{data.postcode} {data.city}</>)}
                </Paragraph>
              </BuildingAddressLine>
            </BuildingCardHeader>
            <BuildingCardLineInline>
              <StyledParagraph>
                <b>Numer budowy:</b>
              </StyledParagraph>
              <StyledParagraph>
                <b>
                  {data.construction_nr}
                </b>
              </StyledParagraph>
            </BuildingCardLineInline>
            {data.project_nr &&
              <BuildingCardLineInline>
                <StyledParagraph>
                  <b>Numer projektu:</b>
                </StyledParagraph>
                <StyledParagraph>
                  {data.project_nr}
                </StyledParagraph>
              </BuildingCardLineInline>
            }
            {
              data.scopes.length > 0 && (
                <BuildingCardLine>
                  <StyledParagraph><b>Zakres prac:</b></StyledParagraph>
                  <WorkScope>
                    {
                      data.scopes.map((scope: any, i) => {
                        return (
                          <StyledParagraph key={i}>{scope.name}{i < data.scopes.length - 1 ? "," : ""}</StyledParagraph>
                        );
                      })
                    }
                  </WorkScope>
                </BuildingCardLine>
              )
            }
            {data.note &&
              (
                <BuildingCardLine>
                  <StyledParagraph>
                    <b>Informacja:</b>
                  </StyledParagraph>
                  <StyledParagraph>
                    {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
                    {useHtmlParser(data.note)}
                  </StyledParagraph>
                </BuildingCardLine>
              )
            }
          </div>
          {
            data.is_waiting ? (
              <IsWaitingInfo>Zgłoszenie oczekuje na akceptację</IsWaitingInfo>
            ) : (
              <ButtonWrapper>
                <ButtonPrimaryForward onClick={() => dispatch(showBuildingJobModal(data.id))}>Przyjmij ogłoszenie</ButtonPrimaryForward>
              </ButtonWrapper>
            )
          }
        </CardWrapper>
      )}
    </>
  );
};

export default BuildingsJobCard;
