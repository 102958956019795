import {createGlobalStyle} from "styled-components";


const GlobalStyles = createGlobalStyle`

    *, *::before, *::after {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        font-family: 'Exo', sans-serif;

    }

    ul {
        list-style: none;
    }

    body {
        position: relative;
        margin: 0;
        padding: 0;
        color: ${props => props.theme.colors.secondary};
        overscroll-behavior-y: contain;
        overscroll-behavior-x: contain;
    }

    input, select, textarea {
        outline: none;
    }

    a {
        text-decoration: none !important;
        color: inherit;
        cursor: pointer;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    .wrapper-to-block-scroll {
        width: 100%;
    }

    .fade-parent-enter-done,
    .fade-parent-appear-done,
    .fade-children-enter-done {
        transform: translateY(48px);
    }

    .fade-parent-enter {
        transform: translateX(100%);
        transition: transform 400ms ease;
        position: fixed;
        top: 48px;
    }

    .fade-children-enter-active {
        transform: translateX(0);
        transition: transform 300ms ease;
        z-index: 10;
    }

    .fade-parent-exit {
        transform: translateX(0);
    }

    .fade-parent-exit-active {
        transform: translateX(-100%);
        transition: transform 300ms ease;
    }

    .fade-children-enter {
        transform: translateX(-100%);
        transition: transform 300ms ease;
        position: fixed;
        top: 48px;
    }

    .fade-parent-enter-active {
        transform: translateX(0);
        transition: transform 300ms ease;
    }

    .fade-children-exit {
        transform: translateX(0%);
    }

    .fade-children-exit-active {
        transform: translateX(100%);
        transition: transform 300ms ease;
    }

    .fade-enter {
        transform: translateX(100%);
    }

    .fade-exit {
        transform: translateX(0);
    }

    .fade-enter-active {
        transform: translateX(0);
    }

    .fade-exit-active {
        transform: translateX(100%);
    }

    .fade-enter-active,
    .fade-exit-active {
        transition: transform 500ms;
    }

    .modal-fade-enter {
        opacity: 0;
    }

    .modal-fade-enter .modal-wrapper {
        transform: translateY(100%);
    }

    .modal-fade-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 200ms, transform 200ms;
    }

    .modal-fade-exit {
        opacity: 1;
    }

    .modal-fade-exit-active {
        opacity: 0;
        transition: opacity 300ms, transform 300ms;
    }

    .modal-fade-exit-active .modal-wrapper {
        transform: translateY(100%);
    }

    .modal-fade-enter-done .modal-wrapper {
        transform: translateY(0);
    }
`;

export default GlobalStyles;
