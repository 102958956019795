import {useState}            from "react";
import {useEffect}           from "react";
import React                 from "react";
import InfiniteScroll        from "react-infinite-scroll-component";
import styled                from "styled-components";
import InfoEmptyContent      from "../components/infobox/InfoEmptyContent";
import LoadingData           from "../components/loading-data/LoadingData";
import ModalReward           from "../components/modal/ModalReward";
import PageHeader            from "../components/page-header/PageHeader";
import RewardCard            from "../components/rewards/RewardCard";
import Wrapper               from "../components/wrapper/Wrapper";
import {useAppDispatch}      from "../hooks/useAppDispatch";
import {useAppSelector}      from "../hooks/useAppSelector";
import {fetchRewardInfinite} from "../store/RewardReducer";
import {fetachReward}        from "../store/RewardReducer";


const StyledWrapper = styled(Wrapper)`
    display: flex;
    flex-wrap: wrap;
`;

const RewardsPage = () => {
  const dispatch              = useAppDispatch();
  const user                  = useAppSelector(state => state.newUser.user);
  const rewards               = useAppSelector(state => state.reward);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      setTimeout(() => {
        dispatch(fetachReward());
        setLoading(false);
      }, 400);
    }
    return () => {
      isSubscribed = false;
    };
  }, []);


  if (!rewards) {
    return <LoadingData/>;
  }

  return (
    <div>
      <PageHeader>Lista nagród</PageHeader>
      <StyledWrapper>
        {
          user && (
            <>
              {
                rewards.data ? (
                  <>
                    {
                      loading ? (
                        <LoadingData/>
                      ) : (
                        <InfiniteScroll
                          dataLength={rewards.data.length}
                          next={() => dispatch(fetchRewardInfinite(rewards.next_page))}
                          hasMore={true}
                          loader={null}
                          style={{display: "flex", flexWrap: "wrap"}}
                        >
                          {rewards && rewards.data.length > 0 && rewards.data.map((data, i) => (
                            <RewardCard key={i} data={data} userPoints={user.points}/>
                          ))}
                        </InfiniteScroll>
                      )
                    }
                  </>
                ) : (
                  <InfoEmptyContent>Brak nagród</InfoEmptyContent>
                )
              }
            </>
          )
        }
      </StyledWrapper>
      <ModalReward state={rewards.rewardModalStatus}/>
    </div>
  );
};

export default RewardsPage;
