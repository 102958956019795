import React, { useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import ButtonFacebook from '../buttons/ButtonFacebook';
import useApi from '../../hooks/useApi';
import { ROUTE_HOME } from '../../routing/routes';
import { useNavigate, useLocation } from 'react-router';
import { AuthError } from '../../pages/auth/LoginPage';


const FacebookLoginButton = () => {
  const Api = useApi();
  const location = useLocation();
  //@ts-ignore
  const from = location.state?.from?.pathname || ROUTE_HOME;
  const navigate = useNavigate();
  const [formError, setFormError] = useState<string>();

  return (
    <>
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID || ''}
        redirectUri="https://app.bruczek.pl/logowanie"
        disableMobileRedirect={true}
        callback={(response: any) => {
          Api.SignIn.Facebook({
            grant_type: 'socialite',
            client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
            client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
            provider: 'facebook',
            token: response.accessToken,
            scope: process.env.REACT_APP_OAUTH_SCOPE,
          }).then(({ data }) => {
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            localStorage.setItem('expires_in', data.expires_in);

            navigate(from, { replace: true });
          }).catch(error => {
            console.error(error);
            setFormError(error.response.data.error_description);
          });
        }}
        render={(props) => (
          <ButtonFacebook onClick={props.onClick}/>
        )}
      />
      {formError && (
        <>
          <br/>
          <AuthError>
            {formError}
          </AuthError>
        </>
      )}
    </>
  );
};

export default FacebookLoginButton;
