import axios from 'axios';
import React, { useState, useEffect, FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import InfoAddAppToHomeScreen from './components/infobox/InfoAddAppToHomeScreen';
import ModalToast from './components/modal/ModalToast';
import NetworkDetector from './components/network-detector/NetworkDetector';
import AuthVerification from './components/require-auth/AuthVerification';
import CheckUser from './components/require-auth/CheckUser';
import RequireAuth from './components/require-auth/RequireAuth';
import { AuthProvider } from './context/AuthProvider';
import { UserProvider } from './context/UserProvider';
import Layout from './layout/Layout';
import ArticleIndexPage from './pages/articles/ArticleIndexPage';
import ArticleShowPage from './pages/articles/ArticleShowPage';
import AccountAcceptance from './pages/auth/AccountAcceptance';
import LoginPage from './pages/auth/LoginPage';
import RegisterNotCompleted from './pages/auth/RegisterNotCompleted';
import RegisterPage from './pages/auth/RegisterPage';
import VerifyEmailPage from './pages/auth/VerifyEmailPage';
import BuildingShowPage from './pages/building/BuildingShowPage';
import BuildingsIndexPage from './pages/building/BuildingsIndexPage';
import ErrorPage from './pages/ErrorPage';
import NotificationsPage from './pages/NotificationsPage';
import PointsPage from './pages/PointsPage';
import PromotionsIndexPage from './pages/promotions/PromotionsIndexPage';
import PromotionsShowPage from './pages/promotions/PromotionsShowPage';
import RewardsPage from './pages/RewardsPage';
import SettingsPage from './pages/SettingsPage';
import ThreadIndexPage from './pages/threads/ThreadIndexPage';
import ThreadShowPage from './pages/threads/ThreadShowPage';
import UserPage from './pages/UserPage';
import * as Paths from './routing/routes';
import { useAppSelector } from './hooks/useAppSelector';
import BuildingJobsPage from './pages/BuildingJobsPage';
import AccessDenied from './pages/auth/AccessDenied';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ForgotPasswordEmailPage from './pages/auth/ForgotPasswordEmailPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import ResetPasswordSuccessPage from './pages/auth/ResetPasswordSuccessPage';


const App: FunctionComponent = () => {
  const [appOnHomeScreen, setAppOnHomeScreen] = useState<boolean>(false);
  const user = useAppSelector(state => state.newUser.user);

  const detectIos = () => {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  };

  axios.interceptors.request.use((config) => {
    if (config.url) {
      let newUrl = new URL(config.url);

      if (newUrl.pathname.startsWith('/api')) {
        //@ts-ignore
        config.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
      }
    }
    return config;
  });

  useEffect(() => {
    if (!detectIos()) {
      if (!window.matchMedia('(display-mode: standalone)').matches) {
        setAppOnHomeScreen(true);
      }
    }
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AuthProvider>
      <UserProvider>
        {
          appOnHomeScreen && <InfoAddAppToHomeScreen/>
        }
        {/*Modal with information about new update*/}
        <ModalToast/>
        <Routes>
          <Route element={<NetworkDetector/>}>
            <Route path={Paths.ROUTE_LOGIN} element={<LoginPage/>}/>
            <Route path={Paths.ROUTE_REGISTER} element={<RegisterPage/>}/>
            <Route path={Paths.ROUTE_FORGOT_PASSWORD} element={<ForgotPasswordPage/>}/>
            <Route path={Paths.ROUTE_FORGOT_PASSWORD_EMAIL} element={<ForgotPasswordEmailPage/>}/>
            <Route path={Paths.ROUTE_RESET_PASSWORD} element={<ResetPasswordPage/>}/>
            <Route path={Paths.ROUTE_RESET_PASSWORD_SUCCESS} element={<ResetPasswordSuccessPage/>}/>
            <Route element={<RequireAuth/>}>
              <Route element={<CheckUser/>}>
                <Route path={Paths.ROUTE_VERIFY_EMAIL} element={<VerifyEmailPage/>}/>
                <Route path={Paths.ROUTE_REGISTER_NOT_COMPLETED} element={<RegisterNotCompleted/>}/>
                <Route path={Paths.ROUTE_ACCOUNT_ACCEPTANCE} element={<AccountAcceptance/>}/>
                <Route path={Paths.ROUTE_ACCESS_DENIED} element={<AccessDenied/>}/>
                <Route element={<AuthVerification/>}>
                  <Route element={<Layout/>}>
                    <Route path={Paths.ROUTE_HOME} element={<BuildingsIndexPage/>}>
                      <Route path={Paths.ROUTE_BUILDING_SHOW} element={<BuildingShowPage/>}/>
                    </Route>
                    <Route path={Paths.ROUTE_THREAD} element={<ThreadIndexPage/>}/>
                    <Route path={Paths.ROUTE_THREAD_SHOW} element={<ThreadShowPage/>}/>
                    <Route path={Paths.ROUTE_ARTICLES} element={<ArticleIndexPage/>}>
                      <Route path={Paths.ROUTE_ARTICLE_SHOW} element={<ArticleShowPage/>}/>
                    </Route>
                    <Route path={Paths.ROUTE_PROMOTIONS} element={<PromotionsIndexPage/>}>
                      <Route path={Paths.ROUTE_PROMOTIONS_SHOW} element={<PromotionsShowPage/>}/>
                    </Route>
                    <Route path={Paths.ROUTE_PROMOTIONS_SHOW} element={<PromotionsShowPage/>}/>
                    {
                      (user && user.type === 'Paver') &&
                      (
                        <Route path={Paths.ROUTE_BUILDING_JOBS} element={<BuildingJobsPage/>}/>
                      )
                    }
                    <Route path={Paths.ROUTE_USER} element={<UserPage/>}/>
                    <Route path={Paths.ROUTE_SETTINGS} element={<SettingsPage/>}/>
                    <Route path={Paths.ROUTE_REWARDS} element={<RewardsPage/>}/>
                    <Route path={Paths.ROUTE_POINTS} element={<PointsPage/>}/>
                    <Route path={Paths.ROUTE_NOTIFICATIONS} element={<NotificationsPage/>}/>
                  </Route>
                </Route>
                <Route path="*" element={<ErrorPage/>}/>
              </Route>
            </Route>
          </Route>
        </Routes>
      </UserProvider>
    </AuthProvider>
  );
};

export default App;




