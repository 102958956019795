export const ROUTE_HOME = '/';
export const ROUTE_LOGIN = '/logowanie';
export const ROUTE_ARTICLES = '/artykuly';
export const ROUTE_NOTIFICATIONS = '/powiadomienia';
export const ROUTE_POINTS = '/punkty';
export const ROUTE_PROMOTIONS = '/promocje';
export const ROUTE_REGISTER = '/rejestracja';
export const ROUTE_REGISTER_NOT_COMPLETED = '/uzupelnij-dane';
export const ROUTE_REWARDS = '/nagrody';
export const ROUTE_THREAD = '/kontakt';
export const ROUTE_SETTINGS = '/ustawienia';
export const ROUTE_USER = '/twoje-konto';
export const ROUTE_VERIFY_EMAIL = '/weryfikacja';
export const ROUTE_ACCOUNT_ACCEPTANCE = '/akceptacja';
export const ROUTE_ACCESS_DENIED = '/konto-zablokowane';
export const ROUTE_BUILDING_JOBS = '/tablica-budow';
export const ROUTE_FORGOT_PASSWORD = '/zresetuj-haslo';
export const ROUTE_FORGOT_PASSWORD_EMAIL = '/resetowanie-hasla';
export const ROUTE_RESET_PASSWORD_SUCCESS = '/ustawiono-haslo';

// nested routes
export const ROUTE_BUILDING_CREATE = '/budowy/dodaj-budowe';
export const ROUTE_BUILDING_SHOW = '/budowy/:id';
export const ROUTE_ARTICLE_SHOW = '/artykuly/:id';
export const ROUTE_PROMOTIONS_SHOW = '/promocje/:id';
export const ROUTE_THREAD_SHOW = '/kontakt/:id';
export const ROUTE_RESET_PASSWORD = '/ustaw-haslo/:token/:email';
