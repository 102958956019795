import {FunctionComponent}       from "react";
import React                     from "react";
import {Link}                    from "react-router-dom";
import styled                    from "styled-components";
import {ROUTE_THREAD}            from "../../routing/routes";
import {defaultModalCloseStyles} from "../modal/defaultModalStyles";
import HeaderSmall               from "../typography/HeaderSmall";


const HeaderContainer = styled.div<{ createForm?: number }>`
    width: 100%;
    height: ${props => props.theme.layout_part_size.mobile.page_header};
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4px 18px;
    box-shadow: ${props => props.createForm ? "none" : "0 4px 20px rgba(42, 183, 248, 0.15)"};
    border-bottom: 1px solid ${props => props.createForm ? props.theme.colors.border : "transparent"};
    background-color: rgba(255,255,255, 0.8);
    backdrop-filter: blur(4px);
    
`;

const StyledLabel = styled.label`
    display: block;
    font-size: 10px;
    font-weight: 400;
    width: 100%;
    margin-bottom: 8px;
`;

const StyledHeaderSmall = styled(HeaderSmall)`
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        max-width: 100%;
    }
`;

const CloseButton = styled(Link)`
    display: none;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        ${defaultModalCloseStyles};
        display: block;
    }
`;

interface Props {
  createForm?: number;
  handleClose?: () => void;
}

const MessageHeader: FunctionComponent<Props> = ({children, createForm, handleClose}) => {
  return (
    <HeaderContainer createForm={createForm}>
      {!createForm && <CloseButton to={ROUTE_THREAD}/>}
      {createForm && (
        <StyledLabel>Tytuł wątku</StyledLabel>
      )}
      <StyledHeaderSmall>
        {children}
      </StyledHeaderSmall>
    </HeaderContainer>
  );
};

export default MessageHeader;
