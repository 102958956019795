import {useEffect}             from "react";
import {useState}              from "react";
import React                   from "react";
import {matchRoutes}           from "react-router";
import {useLocation}           from "react-router-dom";
import {useParams}             from "react-router-dom";
import Promotion               from "../../api/entities/Promotion";
import useApi                  from "../../hooks/useApi";
import {useAppDispatch}        from "../../hooks/useAppDispatch";
import ArticleLayout           from "../../layout/ArticleLayout";
import {ROUTE_PROMOTIONS}      from "../../routing/routes";
import {ROUTE_PROMOTIONS_SHOW} from "../../routing/routes";
import {showSubpage}           from "../../store/SubpageReducer";


const PromotionsShowPage = () => {
  const {id}            = useParams();
  const Api             = useApi();
  const dispatch        = useAppDispatch();
  const [data, setData] = useState<Promotion>();
  const location        = useLocation();
  const match           = matchRoutes([{path: ROUTE_PROMOTIONS_SHOW}], location.pathname);
  let subpage           = location.state as { subpage: boolean | undefined };


  useEffect(() => {
    if (id) {
      Api.Promotion.Get(id).then(({data}) => {
        setData(data);
        if (match || subpage) {
          dispatch(showSubpage());
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      Api.Promotion.Get(id).then(({data}) => {
        setData(data);
      });
    }
  }, []);

  return (
    <>
      {
        data && (<ArticleLayout data={data} parentUrl={ROUTE_PROMOTIONS}/>)
      }
    </>
  );
};

export default PromotionsShowPage;
