import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SignUpRequest from '../../api/request/auth/SignUpRequest';
import ButtonPrimaryForward from '../../components/buttons/ButtonPrimaryForward';
import NavbarAuth from '../../components/navbar/NavbarAuth';
import Header from '../../components/typography/Header';
import Wrapper from '../../components/wrapper/Wrapper';
import InputPassword from '../../form/controls/InputPassword';
import InputText from '../../form/controls/InputText';
import FormSubmit from '../../form/internals/FormSubmit';
import useApi from '../../hooks/useApi';
import { ROUTE_LOGIN, ROUTE_VERIFY_EMAIL } from '../../routing/routes';
import { DefaultPageBackButton } from '../../styles/DefaultPageBackButton';
import FacebookLoginButton from '../../components/facebook-login-button/FacebookLoginButton';
import Paragraph from '../../components/typography/Paragraph';


const StyledWrapper = styled(Wrapper)`
    max-width: 400px;
    padding: 12px 24px 48px 24px;
`;

const HeaderWrapper = styled.div`
    width: 100%;
    text-align: center;
    margin: 24px auto 36px auto;
`;

const StyledHeaderMain = styled(Header)`
    font-size: ${props => props.theme.fonts.mobile.xl};
    font-weight: ${props => props.theme.weights.semiBold};
    position: relative;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: ${props => props.theme.fonts.tablet.lg};
    }
`;

const StyledForm = styled(Form)`
    margin-top: 24px;
`;

const BackButton = styled(Link)`
    ${DefaultPageBackButton};
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
    }
`;

const StyledParagraph = styled(Paragraph)`
    width: 100%;
    text-align: center;
    margin-bottom: 18px;
`;

const LoginSocialsWrapper = styled.div`
    margin: 36px 0;
    text-align: center;
`;

const RegisterPage = () => {
  const Api = useApi();
  const navigate = useNavigate();

  return (
    <>
      <NavbarAuth/>
      <StyledWrapper>
        <HeaderWrapper>
          <StyledHeaderMain>
            <BackButton to={ROUTE_LOGIN}/>
            <span>Zarejestruj się</span>
          </StyledHeaderMain>
        </HeaderWrapper>
        <Formik
          initialValues={{
            email: '',
            password: '',
            password_confirmation: '',
          }}
          onSubmit={(values: SignUpRequest, actions) => {
            Api.SignUp.SingUp(values)
              .then(() => {
                Api.SignIn.SignIn({
                  grant_type: process.env.REACT_APP_OAUTH_GRAD_TYPE,
                  client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
                  client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
                  username: values.email,
                  password: values.password,
                  scope: process.env.REACT_APP_OAUTH_SCOPE,
                }).then(({ data }) => {
                  localStorage.setItem('access_token', data.access_token);
                  localStorage.setItem('refresh_token', data.refresh_token);
                  localStorage.setItem('expires_in', data.expires_in);
                  navigate(ROUTE_VERIFY_EMAIL);
                });
              })
              .catch(error => {
                console.error(error.response.data.errors);
                actions.setErrors(error.response.data.errors);
              });
          }}
        >
          <StyledForm>
            <InputText label="Adres email" name="email" required/>
            <InputPassword label="Hasło" name="password" required/>
            <InputPassword label="Powtórz hasło" name="password_confirmation" required/>
            <FormSubmit>
              <ButtonPrimaryForward type="submit">Przejdź dalej</ButtonPrimaryForward>
            </FormSubmit>
          </StyledForm>
        </Formik>
        <LoginSocialsWrapper>
          <StyledParagraph>
            Zarejestruj się przez:
          </StyledParagraph>
          <FacebookLoginButton/>
        </LoginSocialsWrapper>
      </StyledWrapper>
    </>
  );
};

export default RegisterPage;
