import {css}                       from "styled-components";
import {defaultPrimaryButtonStyle} from "./DefaultPrimaryButtonStyle";


export const defaultSecondaryButtonStyle = css`
    ${defaultPrimaryButtonStyle};
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};

`;
