import {FunctionComponent} from "react";
import React               from "react";
import {generatePath}      from "react-router";
import {Link}              from "react-router-dom";
import styled              from "styled-components";
import Thread              from "../../api/entities/Thread";
import useHtmlParser       from "../../hooks/useHtmlParser";
import useMomentConverter  from "../../hooks/useMomentConverter";
import {ROUTE_THREAD_SHOW} from "../../routing/routes";
import HeaderSmall         from "../typography/HeaderSmall";
import Paragraph           from "../typography/Paragraph";


const ThreadContainer = styled(Link)`
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: 5px;
    padding: 18px;
    position: relative;
    display: block;

    &.new-message {
        border-left: 4px solid ${props => props.theme.colors.primary};
    }

    &.thread-closed {
        background-color: #f9f9f9;
    }

    &:not(:last-child) {
        margin-bottom: 24px;
    }
`;

const StyledHeaderSmall = styled(HeaderSmall)`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 18px;
`;

const Date = styled.div`
    font-size: ${props => props.theme.fonts.mobile.sm};
    margin-bottom: 8px;
`;

const StyledParagraph = styled(Paragraph)`
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 4px;
`;

const Status = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 4px 16px;
    border-radius: 30px;
    background-color: rgba(3, 113, 242, 0.15);
    color: ${props => props.theme.colors.primary};
    font-size: ${props => props.theme.fonts.mobile.sm};
`;

interface Props {
  data: Thread;
}

const ThreadCard: FunctionComponent<Props> = ({data}) => {
  return (
    <ThreadContainer to={generatePath(ROUTE_THREAD_SHOW, {id: data.id})} state={{subpage: true}} className={`${data.is_unread_user ? "new-message" : ""} ${data.is_active ? "" : "thread-closed"}`}>
      <Date>{useMomentConverter(data.updated_at)}</Date>
      <Status>{data.is_active ? "Aktywny" : "Zakończony"}</Status>
      <StyledHeaderSmall>{data.title}</StyledHeaderSmall>
      {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
      {data.last_message ? (<StyledParagraph>{useHtmlParser(data.last_message.content)}</StyledParagraph>) : <StyledParagraph>Ten wątek jest pusty, napisz nową wiadomość</StyledParagraph>}
    </ThreadContainer>
  );
};

export default ThreadCard;

