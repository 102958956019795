import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ConfirmImage from '../../assets/images/confirm.png';
import ButtonPrimary from '../../components/buttons/ButtonPrimary';
import NavbarAuth from '../../components/navbar/NavbarAuth';
import Header from '../../components/typography/Header';
import Paragraph from '../../components/typography/Paragraph';
import WrapperInfo from '../../components/wrapper/WrapperInfo';
import { useAppSelector } from '../../hooks/useAppSelector';
import { ROUTE_HOME } from '../../routing/routes';

const StyledHeader = styled(Header)`
    margin: 12px 0 24px 0;
    font-size: ${({ theme }) => theme.fonts.mobile.xl};
`;

const StyledParagraph = styled(Paragraph)`
    text-align: center;
`;

const ForgotPasswordEmailPage = () => {
    const user = useAppSelector(state => state.newUser.user);
    const navigate = useNavigate();

    if (user && user.email_verified_at) {
        navigate(ROUTE_HOME);
    }

    return (
        <>
            <NavbarAuth/>
            <WrapperInfo>
                <img src={ConfirmImage} alt=""/>
                <StyledHeader>Dziękujemy!</StyledHeader>
                <StyledParagraph>Wysłaliśmy link do zresetowania hasła na Twoją skrzynkę pocztową</StyledParagraph>
                <StyledParagraph>Postępuj zgodnie z instrukcją z wiadomości e-mail w celu zresetowania hasła.</StyledParagraph>
                <br/>

                <ButtonPrimary onClick={() => navigate(ROUTE_HOME)}>Powrót do logowania</ButtonPrimary>
            </WrapperInfo>
        </>
    );
};

export default ForgotPasswordEmailPage;
