import {createSlice} from '@reduxjs/toolkit';


interface ModalBuildingMapState {
  status: boolean;
}

const initialState: ModalBuildingMapState = {
  status: false,
};

export const modalBuildingMapSlice = createSlice({
  name: 'modalBuildingMap',
  initialState,
  reducers: {
    showModalBuildingMap: state => {
      state.status = true;
    },
    hideModalBuildingMap: state => {
      state.status = false;
    },
  },
});

export const {
  showModalBuildingMap,
  hideModalBuildingMap,
} = modalBuildingMapSlice.actions;

export default modalBuildingMapSlice.reducer;
