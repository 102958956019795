import {enableBodyScroll}          from "body-scroll-lock";
import {disableBodyScroll}         from "body-scroll-lock";
import {useRef}                    from "react";
import {useEffect}                 from "react";
import {FunctionComponent}         from "react";
import React                       from "react";
import {useSwipeable}              from "react-swipeable";
import {CSSTransition}             from "react-transition-group";
import styled                      from "styled-components";
import {useAppDispatch}            from "../../hooks/useAppDispatch";
import useClickOutside             from "../../hooks/useClickOutside";
import useKeyPress                 from "../../hooks/useKeyPress";
import ThreadCreate                from "../../pages/threads/ThreadCreate";
import {enableGlobalSwipe}         from "../../store/ModalReducer";
import {blockGlobalSwipe}          from "../../store/ModalReducer";
import {hideModal}                 from "../../store/ThreadReducer";
import {defaultModalCloseStyles}   from "./defaultModalStyles";
import {defaultModalWrapperStyles} from "./defaultModalStyles";
import {defaultModalStyles}        from "./defaultModalStyles";


const Container = styled.div`
    ${defaultModalStyles};
    opacity: 1;
    visibility: visible;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        align-items: center;
    }
`;

const Wrapper = styled.div`
    ${defaultModalWrapperStyles}
`;

const CloseModal = styled.button`
    ${defaultModalCloseStyles};
`;

interface Props {
  state: boolean;
}

const ModalCreateThread: FunctionComponent<Props> = ({state}) => {
  const ref        = useRef<any>();
  const refWrapper = useRef<any>();
  const dispatch   = useAppDispatch();

  useEffect(() => {
    if (state) {
      disableBodyScroll(ref.current);
      dispatch(blockGlobalSwipe());
    }
  }, [state]);

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => {
      if (window.innerWidth < 860) {
        handleClose();
      }
    },
    delta:        30,
    trackTouch:   true,
    trackMouse:   true,
  });

  const refPassThrough = (el: any) => {
    swipeHandlers.ref(el);
    ref.current = el;
  };

  const handleClose = () => {
    dispatch(hideModal());
    dispatch(enableGlobalSwipe());
    enableBodyScroll(ref.current);
  };

  useClickOutside(refWrapper, handleClose);

  useKeyPress(handleClose);

  return (
    <CSSTransition
      in={state}
      timeout={200}
      classNames="modal-fade"
      unmountOnExit
    >
      <Container {...swipeHandlers} ref={refPassThrough}>
        <Wrapper className="modal-wrapper" ref={refWrapper}>
          <CloseModal onClick={() => handleClose()}/>
          <ThreadCreate handleClose={handleClose}/>
        </Wrapper>
      </Container>
    </CSSTransition>
  );
};

export default ModalCreateThread;
