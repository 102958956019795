import React, {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import LogoImage from '../../assets/icons/logo.svg';
import {useAppDispatch} from '../../hooks/useAppDispatch';
import {hideMenu} from '../../store/MenuReducer';


const LogoLink = styled(Link)`
    width: 130px;
    height: 40px;
    background-image: url(${LogoImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 150px;
        height: 50px;
        transform: translateX(-22px);
    }
`;

const Logo: FunctionComponent<{ to: string }> = ({to}) => {
  const dispatch = useAppDispatch();

  return (
    <LogoLink to={to} onClick={() => dispatch(hideMenu())}/>
  );
};

export default Logo;
