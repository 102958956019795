import {PayloadAction}    from "@reduxjs/toolkit";
import {createSlice}      from "@reduxjs/toolkit";
import {createAsyncThunk} from "@reduxjs/toolkit";
import Api                from "../api/Api";
import BuildingJob        from "../api/entities/Building";


interface BuildingJobState {
  current_page: number;
  next_page: number;
  last_page: number | undefined;
  data: BuildingJob[] | undefined;
  total: number;
  buildingJobModalStatus: boolean,
  buildingJobId: string | undefined;
  buildingJobSuccessInfoPage: boolean;

}

const initialState: BuildingJobState = {
  current_page:               0,
  next_page:                  0,
  last_page:                  0,
  total:                      0,
  data:                       [],
  buildingJobModalStatus:     false,
  buildingJobId:              "",
  buildingJobSuccessInfoPage: false,
};

const client = new Api();

export const fetchBuildingJobInfinite = createAsyncThunk("buildingJob/infinite", async (page: number, {getState}) => {
  const state: any = getState();
  const response   = await client.Building.OfferList({page: page});

  return {
    data:         [...state.reward.data, ...response.data.data],
    current_page: response.data.current_page,
    next_page:    response.data.current_page + 1,
  };
});

export const fetchBuildingJob = createAsyncThunk("buildingJob", async () => {
  const response = await client.Building.OfferList();

  return response.data;
});


export const buildingJobSlice = createSlice({
  name:          "buildingJob",
  initialState,
  reducers:      {
    showBuildingJobModal:   (state, action) => {
      state.buildingJobModalStatus = true;
      state.buildingJobId          = action.payload;
    },
    hideBuildingJobModal:   state => {
      state.buildingJobModalStatus = false;
      state.buildingJobId          = "";
    },
    showBuildingJobSuccess: state => {
      state.buildingJobSuccessInfoPage = true;
    },
    hideBuildingJobSuccess: state => {
      state.buildingJobSuccessInfoPage = false;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(fetchBuildingJob.fulfilled, (state: BuildingJobState, action: PayloadAction<any>) => {
      state.data         = action.payload.data;
      state.last_page    = action.payload.last_page;
      state.current_page = action.payload.current_page;
      state.next_page    = action.payload.current_page + 1;
      state.total        = action.payload.total;
    });
    builder.addCase(fetchBuildingJobInfinite.fulfilled, (state: BuildingJobState, action: PayloadAction<any>) => {
      state.data         = action.payload.data;
      state.current_page = action.payload.current_page;
      state.next_page    = action.payload.current_page + 1;
    });
  },
});

export const {
               showBuildingJobModal,
               hideBuildingJobModal,
               showBuildingJobSuccess,
               hideBuildingJobSuccess,
             } = buildingJobSlice.actions;

export default buildingJobSlice.reducer;
