import axios             from "axios";
import User              from "../entities/User";
import Entrypoint        from "../Entrypoint";
import {ResponsePromise} from "../ResponsePromise";


class ProfileAPi {
  Avatar(data: FormData) {
    return axios.post(Entrypoint.Api.Profile.Avatar, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  DeleteAvatar() {
    return axios.delete(Entrypoint.Api.Profile.Avatar);
  }

  Update(data: any): ResponsePromise<{ data: User }> {
    return axios.patch(Entrypoint.Api.Profile.Update, data);
  }
}

export default ProfileAPi;
