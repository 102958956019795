import React, { FunctionComponent } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Building from '../../api/entities/Building';
import useHtmlParser from '../../hooks/useHtmlParser';
import { ROUTE_BUILDING_SHOW } from '../../routing/routes';
import BuildingStatus from '../building-status/BuildingStatus';
import CustomLink from '../custom-link/CustomLink';
import HeaderSmall from '../typography/HeaderSmall';
import Paragraph from '../typography/Paragraph';


const CardWrapper = styled(Link)`
    border: 1px solid ${props => props.theme.colors.card};
    border-left: 4px solid ${props => props.theme.colors.primary};
    border-radius: 5px;
    padding: 16px 24px 18px 24px;
    display: block;
    width: 100%;
    background-color: ${props => props.theme.colors.card};

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_big}) {
        width: calc(50% - 12px);
        margin-bottom: 24px;
        border-left: 1px solid ${props => props.theme.colors.border};
        box-shadow: 0 0 14px -8px rgb(42 183 248 / 45%);
        transition: .2s all ease;
        &:not(:nth-child(2n)) {
            margin-right: 24px;
        }

        &:hover {
            box-shadow: 0 0 24px -8px rgb(42 183 248 / 65%);
        }
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop_hd}) {
        width: calc(33.33% - 16px);

        &:not(:nth-child(2n)) {
            margin-right: 0;
        }

        &:not(:nth-child(3n)) {
            margin-right: 24px;
        }
    }
`;

const CardHeader = styled.div`
    position: relative;
    padding-right: 60px;
    margin-bottom: 12px;
`;

const CardStatusWrapper = styled.div`
    display: flex;
    padding-bottom: 8px;
`;

const CardNumber = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    font-size: ${props => props.theme.fonts.mobile.sm};
`;

const WorkScope = styled.div`
    display: flex;
    flex-wrap: wrap;

    p {
        margin-right: 6px;
    }
`;

const BuildingCardHeader = styled.div`
    margin-bottom: 16px;
`;

const BuildingCardLine = styled.div`
    &:not(:last-child) {
        margin-bottom: 6px;
    }
`;

const BuildingCardLineInline = styled(BuildingCardLine)`
    display: flex;
    justify-content: space-between;
`;

const StyledParagraph = styled(Paragraph)`
    line-height: 1.6;

    b {
        font-weight: 500;
        margin-bottom: 2px;
        display: block;
    }
`;

const BuildingAddressLine = styled.div`
    display: flex;
`;

const StyledHeaderSmall = styled(HeaderSmall)`
    margin-bottom: 2px;
`;


interface Props {
  data: Building;
}

const BuildingsCard: FunctionComponent<Props> = ({ data }) => {

  const trimNote = (note: string) => {

    const limit = 100;

    if (note.length > limit) {
      return `${note.substring(0, limit)}...`;
    } else {
      return note;
    }
  };

  return (
    <>
      {data && (
        <CardWrapper to={generatePath(ROUTE_BUILDING_SHOW, { id: data.id })} state={{ subpage: true }}>
          <CardHeader>
            <CardStatusWrapper>
              {data.business_status && (<BuildingStatus status={data.paver_status}/>)}
              {data.is_offered_confirmed && (<BuildingStatus status={data.is_offered ? 'Do oddania' : null}/>)}
            </CardStatusWrapper>
          </CardHeader>
          <div>
            <BuildingCardHeader>
              <StyledHeaderSmall>{data.title}</StyledHeaderSmall>
              {(data.firstname || data.lastname) && (<StyledParagraph>{data.firstname} {data.lastname}</StyledParagraph>)}
              <BuildingAddressLine>
                <Paragraph>
                  {data.street && (<>ul. {data.street} {data.building_nr}{data.apartment_nr && <>/{data.apartment_nr}</>}</>)}
                  {data.street && data.city && <>,&nbsp;</>}
                  {data.postcode && data.city && (<>{data.postcode} {data.city}</>)}
                </Paragraph>
              </BuildingAddressLine>
            </BuildingCardHeader>
            <BuildingCardLineInline>
              <StyledParagraph>
                <b>Numer budowy:</b>
              </StyledParagraph>
              <StyledParagraph>
                <b>
                  {data.construction_nr}
                </b>
              </StyledParagraph>
            </BuildingCardLineInline>
            {data.project_nr &&
              <BuildingCardLineInline>
                <StyledParagraph>
                  <b>Numer projektu:</b>
                </StyledParagraph>
                <StyledParagraph>
                  {data.project_nr}
                </StyledParagraph>
              </BuildingCardLineInline>
            }
            {data.note &&
              (
                <BuildingCardLine>
                  <StyledParagraph>
                    <b>Informacja:</b>
                  </StyledParagraph>
                  <StyledParagraph>
                    {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
                    {useHtmlParser(trimNote(data.note))}
                  </StyledParagraph>
                </BuildingCardLine>
              )
            }
            <CustomLink>Zobacz</CustomLink>
          </div>
        </CardWrapper>
      )}
    </>
  );
};


export default BuildingsCard;
