import React                from "react";
import styled               from "styled-components";
import ButtonPrimaryForward from "../components/buttons/ButtonPrimaryForward";
import {ROUTE_HOME}         from "../routing/routes";


const ErrorPageWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    max-width: 100vw;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const ErrorCode = styled.div`
    font-size: 100px;
    font-weight: 700;
`;

const ErrorInfo = styled.div`
    margin-bottom: 24px;
`;

const ErrorPage = () => {
  return (
    <ErrorPageWrapper>
      <ErrorCode>
        404
      </ErrorCode>
      <ErrorInfo>
        Szukana strona nie istnieje
      </ErrorInfo>
      <ButtonPrimaryForward to={ROUTE_HOME}>Strona główna</ButtonPrimaryForward>
    </ErrorPageWrapper>
  );
};

export default ErrorPage;
