import {Field} from "formik";
import {css}   from "styled-components";
import styled  from "styled-components";


export const defaultStyles = css`
    border: 1px solid ${({theme}) => theme.colors.primary};
    width: 100%;
    font-size: ${props => props.theme.fonts.mobile.sm};
    height: 38px;
    border-radius: 5px;
    outline: none;
    padding: 2px 16px;

    &.validation-error {
        border: 1px solid ${props => props.theme.colors.danger};
    }

    &:focus {
        -webkit-appearance: none;
        -webkit-box-shadow: 0 4px 10px rgba(1, 173, 249, 0.4);
        box-shadow: 0 4px 10px rgba(1, 173, 249, 0.4);
        //border: 1px solid red;
    }

    &:disabled {
        cursor: not-allowed;
        color: #a0a0a0;
        border-color: ${({theme}) => theme.colors.gray};
    }
`;

const Input = styled(Field)`
    ${defaultStyles}
`;

export default Input;

