import {PayloadAction}    from "@reduxjs/toolkit";
import {createSlice}      from "@reduxjs/toolkit";
import {createAsyncThunk} from "@reduxjs/toolkit";
import Api                from "../api/Api";
import Reward             from "../api/entities/Reward";


interface RewardState {
  current_page: number;
  next_page: number;
  last_page: number | undefined;
  data: Reward[] | undefined;
  total: number;
  rewardModalStatus: boolean,
  rewardId: string | undefined;
  rewardSuccessInfoPage: boolean;

}

const initialState: RewardState = {
  current_page:      0,
  next_page:         0,
  last_page:         0,
  total:             0,
  data:              [],
  rewardModalStatus: false,
  rewardId:          "",
  rewardSuccessInfoPage: false,
};

const client = new Api();

export const fetchRewardInfinite = createAsyncThunk("reward/infinite", async (page: number, {getState}) => {
  const state: any = getState();
  const response   = await client.Reward.List({page: page});

  return {
    data:         [...state.reward.data, ...response.data.data],
    current_page: response.data.current_page,
    next_page:    response.data.current_page + 1,
  };
});

export const fetachReward = createAsyncThunk("reward", async () => {
  const response = await client.Reward.List();

  return response.data;
});


export const rewardSlice = createSlice({
  name:          "reward",
  initialState,
  reducers:      {
    showRewardModal: (state, action) => {
      state.rewardModalStatus = true;
      state.rewardId          = action.payload;
    },
    hideRewardModal: state => {
      state.rewardModalStatus = false;
      state.rewardId          = "";
    },
    showRewardSuccess: state => {
      state.rewardSuccessInfoPage = true
    },
    hideRewardSuccess: state => {
      state.rewardSuccessInfoPage = false;
    }
  },
  extraReducers: (builder: any) => {
    builder.addCase(fetachReward.fulfilled, (state: RewardState, action: PayloadAction<any>) => {
      state.data         = action.payload.data;
      state.last_page    = action.payload.last_page;
      state.current_page = action.payload.current_page;
      state.next_page    = action.payload.current_page + 1;
      state.total        = action.payload.total;
    });
    builder.addCase(fetchRewardInfinite.fulfilled, (state: RewardState, action: PayloadAction<any>) => {
      state.data         = action.payload.data;
      state.current_page = action.payload.current_page;
      state.next_page    = action.payload.current_page + 1;
    });
  },
});

export const {
               showRewardModal,
               hideRewardModal,
               showRewardSuccess,
               hideRewardSuccess,
             } = rewardSlice.actions;

export default rewardSlice.reducer;
