import {disableBodyScroll}         from "body-scroll-lock";
import {useRef}                    from "react";
import {useEffect}                 from "react";
import React                       from "react";
import {useSwipeable}              from "react-swipeable";
import {CSSTransition}             from "react-transition-group";
import styled                      from "styled-components";
import {useAppDispatch}            from "../../hooks/useAppDispatch";
import {useAppSelector}            from "../../hooks/useAppSelector";
import useClickOutside             from "../../hooks/useClickOutside";
import useKeyPress                 from "../../hooks/useKeyPress";
import {enableGlobalSwipe}         from "../../store/ModalReducer";
import {blockGlobalSwipe}          from "../../store/ModalReducer";
import {hideTooltip}               from "../../store/TooltipReducer";
import {defaultModalCloseStyles}   from "./defaultModalStyles";
import {defaultModalWrapperStyles} from "./defaultModalStyles";
import {defaultModalStyles}        from "./defaultModalStyles";


const Container = styled.div`
    ${defaultModalStyles};
    opacity: 1;
    visibility: visible;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        align-items: center;
    }
`;

const Wrapper = styled.div`
    ${defaultModalWrapperStyles}
`;

const CloseModal = styled.button`
    ${defaultModalCloseStyles};
`;

const TooltipTitle = styled.div`
    width: 100%;
    padding-bottom: 12px;
    margin-bottom: 18px;
    border-bottom: 1px solid ${({theme}) => theme.colors.border};
    font-size: 14px;
    font-weight: 600;
`;

const TooltipWrapper = styled.div`
    width: 100%;
    font-size: 12px;
    line-height: 1.6;
`;


const ModalTooltip = () => {
  const ref          = useRef<any>();
  const refWrapper   = useRef<any>();
  const dispatch     = useAppDispatch();
  const tooltipModal = useAppSelector(state => state.tooltip.active);
  const tooltipData  = useAppSelector(state => state.tooltip);

  useEffect(() => {
    if (tooltipModal) {
      disableBodyScroll(ref.current);
      dispatch(blockGlobalSwipe());
    }
  }, [tooltipModal]);

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => {
      if (window.innerWidth < 860) {
        handleClose();
      }
    },
    delta:        10,
    trackTouch:   true,
    trackMouse:   true,
  });

  const refPassThrough = (el: any) => {
    swipeHandlers.ref(el);
    ref.current = el;
  };

  const handleClose = () => {
    // enableBodyScroll(ref.current);
    dispatch(enableGlobalSwipe());
    dispatch(hideTooltip());
  };

  useClickOutside(refWrapper, handleClose);

  useKeyPress(handleClose);

  return (
    <CSSTransition
      in={tooltipModal}
      timeout={200}
      classNames="modal-fade"
      unmountOnExit
    >
      <Container {...swipeHandlers} ref={refPassThrough}>
        <Wrapper className="modal-wrapper" ref={refWrapper}>
          <CloseModal onClick={() => handleClose()}/>
          <TooltipTitle>
            {tooltipData.title}
          </TooltipTitle>
          <TooltipWrapper>
            {tooltipData.description}
          </TooltipWrapper>
        </Wrapper>
      </Container>
    </CSSTransition>
  );
};

export default ModalTooltip;
