import styled from "styled-components";


interface Props {
  required?: boolean;
}

const Label = styled.label<Props>`
    display: block;
    font-size: ${props => props.theme.fonts.mobile.sm};
    font-weight: ${props => props.theme.weights.semiBold};
    line-height: 1.4;
    margin-bottom: 6px;

    &:after {
        content: "*";
        display: ${({required}) => required ? "inline" : "none"};
        color: ${({theme}) => theme.colors.danger};
        margin-left: 4px;
    }
`;

Label.defaultProps = {
  required: false,
};

export default Label;
