import React            from "react";
import styled           from "styled-components";
import {useAppSelector} from "../../hooks/useAppSelector";


const MenuUserContainer = styled.div`
    display: flex;
    align-items: center;
`;

const MenuUserAvatar = styled.div`
    width: 38px;
    height: 38px;
    box-shadow: 0 0 4px 2px rgba(42, 183, 248, 0.45);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 32px;
        height: 32px;
    }
`;

const MenuUserDetails = styled.div`
    margin-left: 16px;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin-left: 12px;
    }
`;

const MenuUserInitials = styled.div`
    color: ${({theme}) => theme.colors.white};
    font-size: ${({theme}) => theme.fonts.mobile.md};
    letter-spacing: .4px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({theme}) => theme.colors.primary};
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: ${({theme}) => theme.fonts.mobile.md};
    }
`;

const MenuUserName = styled.div`
    font-size: ${({theme}) => theme.fonts.mobile.md};
    font-weight: 700;
    color: ${({theme}) => theme.colors.secondary};
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: ${({theme}) => theme.fonts.mobile.sm};

    }
`;

const MenuUserMail = styled.div`
    font-size: ${({theme}) => theme.fonts.mobile.sm};
    font-weight: 400;
    color: ${({theme}) => theme.colors.secondaryLight};
`;

const MenuUser = () => {
  const user = useAppSelector(state => state.newUser.user);

  return (
    <>
      {
        user && (
          <MenuUserContainer>
            <MenuUserAvatar>
              {
                user.photos.length ? (
                  <img src={user.photos[0].asset_path} alt=""/>
                ) : (
                  <MenuUserInitials>{user.initials}</MenuUserInitials>
                )
              }
            </MenuUserAvatar>
            <MenuUserDetails>
              <MenuUserName>{user.name}</MenuUserName>
              <MenuUserMail>{user.email}</MenuUserMail>
            </MenuUserDetails>
          </MenuUserContainer>
        )
      }
    </>

  );
};

export default MenuUser;
