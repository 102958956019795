import {createSlice} from "@reduxjs/toolkit";


interface UpdateState {
  status: boolean;
  notification: number;

}

const initialState: UpdateState = {
  status:       false,
  notification: 0,
};

export const updateSlice = createSlice({
  name:     "update",
  initialState,
  reducers: {
    showUpdate: state => {
      state.status       = true;
      state.notification = 1;
    },
    hideUpdate: state => {
      state.status       = false;
      state.notification = 0;
    },
  },
});

export const {showUpdate, hideUpdate} = updateSlice.actions;

export default updateSlice.reducer;
