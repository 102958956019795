import { Form, Formik, Field } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import UserCreateRequest from '../api/request/user/UserCreateRequest';
import ButtonPrimaryForward from '../components/buttons/ButtonPrimaryForward';
import PageHeader from '../components/page-header/PageHeader';
import UserAvatarForm from '../components/user-avatar-form/UserAvatarForm';
import Wrapper from '../components/wrapper/Wrapper';
import InputText from '../form/controls/InputText';
import FormSubmit from '../form/internals/FormSubmit';
import useApi from '../hooks/useApi';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import { fetchUser } from '../store/UserReducerNew';


const StyledWrapper = styled(Wrapper)`
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        max-width: 600px;
        border-radius: 8px;
        height: auto;
        padding: 48px 36px;
    }
`;

const UserForm = styled(Form)`
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_big}) {
        max-width: 600px;
    }
`;

const FormPartInputsWrapper = styled.div`
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_big}) {
        display: flex;
        flex-wrap: wrap;

        &:not(:last-child) {
            margin-bottom: 18px;
            padding-bottom: 18px;
            border-bottom: 1px solid ${({ theme }) => theme.colors.border};
        }

        & > div {
            width: calc(50% - 12px);

            &:not(:last-child) {
                margin-right: 12px;
            }
        }

        &.full-width {
            & > div {
                width: 100%;
            }
        }
    }
`;

const FormInfo = styled.div`
    margin-bottom: 24px;
    padding: 8px 12px;
    background-color: ${({ theme }) => theme.colors.successLight};
    color: ${({ theme }) => theme.colors.success};
    font-size: ${({ theme }) => theme.fonts.mobile.sm};
    border-radius: 5px;
`;

const UserPage = () => {
  const Api = useApi();
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const user = useAppSelector(state => state.newUser.user);
  const dispatch = useAppDispatch();

  const initialValues: UserCreateRequest = {
    name: user ? user.name : '',
    street: user ? user.street : '',
    building_nr: user ? user.building_nr : '',
    postcode: user ? user.postcode : '',
    city: user ? user.city : '',
    phone: user ? user.phone : '',
    email: user ? user.email : '',
    type: user ? user.type : '',
  };

  return (
    <div>
      <PageHeader>Twoje dane</PageHeader>
      <StyledWrapper>
        <UserAvatarForm/>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            Api.Profile.Update(values).then(() => {
              dispatch(fetchUser());
              window.scrollTo(0, 0);
              setShowInfo(true);
            }).catch(error => {
              actions.setErrors(error.response.data.errors);
            });
          }}
        >
          <UserForm>
            {showInfo && <FormInfo>Twoje dane zostały zaktualizowane!</FormInfo>}
            <FormPartInputsWrapper>
              <InputText label="Imię i nazwisko" name="name" required/>
            </FormPartInputsWrapper>
            <FormPartInputsWrapper>
              <InputText label="Ulica" name="street" required/>
              <InputText label="Nr domu/mieszkania" name="building_nr" required/>
            </FormPartInputsWrapper>
            <FormPartInputsWrapper>
              <InputText label="Kod pocztowy" name="postcode" required/>
              <InputText label="Miasto/Miejscowość" name="city" required/>
            </FormPartInputsWrapper>
            <FormPartInputsWrapper>
              <InputText label="Numer telefonu" name="phone" required/>
              <InputText label="Adres email" name="email" required/>
            </FormPartInputsWrapper>
            <Field name="type" hidden/>
            <FormSubmit>
              <ButtonPrimaryForward type="submit">Zapisz</ButtonPrimaryForward>
            </FormSubmit>
          </UserForm>
        </Formik>
      </StyledWrapper>
    </div>
  );
};

export default UserPage;
