import styled          from "styled-components";
import ChevronDownIcon from "../../assets/icons/chevron-down.svg";
import {defaultStyles} from "./Input";


const Select = styled.select`
    ${defaultStyles};
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    padding-right: 36px;
    background-image: url(${ChevronDownIcon});
    background-repeat: no-repeat;
    background-position: calc(100% - 12px) 50%;
    background-size: 10px;
    background-color: #fff;
    color: #000;
    
    &.select-multiple {
        min-height: 38px;
        height: auto;
        background-image: none;
    }
`;

export default Select;
