import React     from "react";
import styled    from "styled-components";
import LogoImage from "../../assets/icons/logo.svg";


const Container = styled.div`
    width: 100vw;
    height: ${props => props.theme.layout_part_size.mobile.navbar};
    background-color: ${props => props.theme.colors.secondary};
    padding: 2px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    z-index: 100;
    top: 0;
    left: 0;
    opacity: 1;
    visibility: visible;
    transition: .3s all ease;
`;

const Logo = styled.div`
    width: 120px;
    height: 30px;
    background-image: url(${LogoImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
`;

const NavbarAuth = () => {
  return (
    <Container>
      <Logo/>
    </Container>
  );
};

export default NavbarAuth;
