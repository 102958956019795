import {createSlice} from "@reduxjs/toolkit";


interface ModalState {
  status: boolean;
  rewardModalStatus: boolean,
  buildingModalStatus: boolean;
  buildingId: string | undefined;
  rewardId: string | undefined;
}

const initialState: ModalState = {
  status:              false,
  buildingModalStatus: false,
  rewardModalStatus:   false,
  buildingId:          "",
  rewardId:            "",
};

export const modalSlice = createSlice({
  name:     "modal",
  initialState,
  reducers: {
    blockGlobalSwipe:  state => {
      state.status = true;

    },
    enableGlobalSwipe: state => {
      state.status = false
    },
  },
});

export const {
               blockGlobalSwipe,
               enableGlobalSwipe,
             } = modalSlice.actions;

export default modalSlice.reducer;
