import React                from "react";
import styled               from "styled-components";
import {useAppDispatch}     from "../../hooks/useAppDispatch";
import {useAppSelector}     from "../../hooks/useAppSelector";
import {useServiceWorker}   from "../../hooks/useServiceWorker";
import {hideUpdate}         from "../../store/UpdateReducer";
import ButtonPrimaryForward from "../buttons/ButtonPrimaryForward";
import Paragraph            from "../typography/Paragraph";


const UpdateInfo = styled.div`
    padding-bottom: 24px;
    padding-top: 24px;
    margin-bottom: 24px;
    border-top: 1px solid ${({theme}) => theme.colors.border};
    border-bottom: 1px solid ${({theme}) => theme.colors.border};
    text-align: center;
`;

const StyledParagraph = styled(Paragraph)`
    font-size: ${({theme}) => theme.fonts.mobile.md};
    font-weight: ${({theme}) => theme.weights.medium};
    margin-bottom: 18px;
    line-height: 1.4;
`;


const InfoAboutUpdate = () => {
  const update       = useAppSelector(state => state.update.status);
  const dispatch     = useAppDispatch();
  const {reloadPage} = useServiceWorker();


  const handleCLick = () => {
    dispatch(hideUpdate());
    reloadPage();
  };

  return (
    <>
      {
        update && (
          <UpdateInfo>
            <StyledParagraph>
              Dostępna jest nowa aktualizacja, kliknij poniższy przycisk aby zaktualizować aplikacje:
            </StyledParagraph>
            <ButtonPrimaryForward type="button" onClick={() => handleCLick()}>Aktualizuj</ButtonPrimaryForward>
          </UpdateInfo>
        )
      }
    </>
  );
};

export default InfoAboutUpdate;
