import React, { useState, FormEvent } from 'react';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import InputText from '../../form/controls/InputText';
import ButtonPrimaryForward from '../../components/buttons/ButtonPrimaryForward';
import FormSubmit from '../../form/internals/FormSubmit';
import useApi from '../../hooks/useApi';
import Entrypoint from '../../api/Entrypoint';
import NavbarAuth from '../../components/navbar/NavbarAuth';
import { DefaultPageBackButton } from '../../styles/DefaultPageBackButton';
import Header from '../../components/typography/Header';
import Paragraph from '../../components/typography/Paragraph';
import Wrapper from '../../components/wrapper/Wrapper';
import { ROUTE_FORGOT_PASSWORD_EMAIL, ROUTE_LOGIN} from '../../routing/routes'; 

const StyledWrapper = styled(Wrapper)`
    max-width: 400px;
    padding: 12px 24px 48px 24px;
`;

const HeaderWrapper = styled.div`
    width: 100%;
    text-align: center;
    margin: 24px auto 36px auto;
`;

const StyledHeaderMain = styled(Header)`
    font-size: ${props => props.theme.fonts.mobile.xl};
    font-weight: ${props => props.theme.weights.semiBold};
    position: relative;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: ${props => props.theme.fonts.tablet.lg};
    }
`;

const StyledForm = styled(Form)`
    margin-top: 24px;
`;

const BackButton = styled(Link)`
    ${DefaultPageBackButton};
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
    }
`;

const StyledParagraph = styled(Paragraph)`
    width: 100%;
    text-align: center;
    margin-bottom: 18px;
`;

const LoginSocialsWrapper = styled.div`
    margin: 36px 0;
    text-align: center;
`;

const ForgotPasswordPage: React.FC = () => {
    const [emailSent, setEmailSent] = useState(false);
    const navigate = useNavigate();
    const Api = useApi();

    const handleSubmit = async (values: { email: string }) => {
        try {
            const response = await fetch(Entrypoint.Api.Auth.ForgotPassword, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });

            if (response.ok) {
                setEmailSent(true);
                navigate(ROUTE_FORGOT_PASSWORD_EMAIL);
            } else {
                const data = await response.json();
                console.error(data.errors);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <NavbarAuth/>
            <StyledWrapper>
            <HeaderWrapper>
                <StyledHeaderMain>
                <BackButton to={ROUTE_LOGIN}/>
                    <span>Zresetuj hasło</span>
                    </StyledHeaderMain>
                    </HeaderWrapper>
                    <Formik
                        initialValues={{
                            email: '',
                        }}
                        onSubmit={handleSubmit}
                    >
                        <StyledForm>
                            <InputText label="Adres email" name="email" required />
                            <FormSubmit>
                                <ButtonPrimaryForward type="submit">Przejdź dalej</ButtonPrimaryForward>
                            </FormSubmit>
                        </StyledForm>
                    </Formik>
                
            </StyledWrapper>
        </>
    );
};

export default ForgotPasswordPage;