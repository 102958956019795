import {FunctionComponent}     from "react";
import {useState}              from "react";
import React                   from "react";
import {matchRoutes}           from "react-router";
import {useLocation}           from "react-router-dom";
import {Link}                  from "react-router-dom";
import styled                  from "styled-components";
import BellFillIcon            from "../../assets/icons/bell-fill.svg";
import BellIcon                from "../../assets/icons/bell.svg";
import EnvelopeFillIcon        from "../../assets/icons/envelope-fill.svg";
import EnvelopeIcon            from "../../assets/icons/envelope.svg";
import HomeFillIcon            from "../../assets/icons/home-fill.svg";
import HomeIcon                from "../../assets/icons/home.svg";
import StartFillIcon           from "../../assets/icons/star-fill.svg";
import StartIcon               from "../../assets/icons/star.svg";
import {useAppSelector}        from "../../hooks/useAppSelector";
import {ROUTE_BUILDING_CREATE} from "../../routing/routes";
import {ROUTE_HOME}            from "../../routing/routes";
import {ROUTE_POINTS}          from "../../routing/routes";
import {ROUTE_NOTIFICATIONS}   from "../../routing/routes";
import {ROUTE_THREAD}          from "../../routing/routes";

const Container = styled.div<{ menuPadding: boolean }>`
    width: 100vw;
    height: ${props => props.menuPadding ? ({theme}) => theme.layout_part_size.mobile.menu_bottom_mobile : ({theme}) => theme.layout_part_size.mobile.menu_bottom};
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: #fff;
    border-top: 1px solid ${props => props.theme.colors.border};
    border-bottom: 1px solid ${props => props.theme.colors.border};
    transition: .3s all ease;
    box-shadow: 0px -6px 40px -24px rgba(42, 183, 248, 1);
    
    &.active {
        transform: translateX(${({theme}) => theme.layout_part_size.mobile.menu});
    }

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        &.active {
            transform: translateX(${({theme}) => theme.layout_part_size.tablet_small.menu});
        }
    }

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        display: none;
    }
`;

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    padding: 0 24px;
`;

const MenuItem = styled(Link)`
    width: 18%;
    border: 2px solid transparent;
`;

const MenuItemBox = styled.div`
    width: 24px;
    height: 36px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: flex-end;
    position: relative;
`;

const MenuItemIconBox = styled.div<{ icon: string; iconActive: string }>`
    width: 24px;
    height: 24px;
    position: relative;
    background-image: url(${p => p.icon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &.active {
        background-image: url(${p => p.iconActive});
    }

    &.has-new {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: -2px;
            right: 2px;
            background-color: ${({theme}) => theme.colors.danger};
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }
    }
`;

const NotificationCount = styled.div`
    position: absolute;
    top: 6px;
    right: -8px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
`;


interface Props {
  route: string;
  icon: string;
  iconActive: string;
  notification: number | null;
}

const Menu = () => {
  const [menuPadding, setMenuPadding] = useState<boolean>(false);
  const status                        = useAppSelector(state => state.menu.status);
  const notificationCount             = useAppSelector(state => state.notification.total);
  const threadUnreadCount             = useAppSelector(state => state.thread.threadsUnread);
  const location                      = useLocation();
  const matchBuildingCreatePage       = matchRoutes([{path: ROUTE_BUILDING_CREATE}], location.pathname);

  const menu_items = [
    {
      route:        ROUTE_HOME,
      icon:         HomeIcon,
      iconActive:   HomeFillIcon,
      notification: null,
    },
    {
      route:        ROUTE_POINTS,
      icon:         StartIcon,
      iconActive:   StartFillIcon,
      notification: null,
    },
    {
      route:        ROUTE_NOTIFICATIONS,
      icon:         BellIcon,
      iconActive:   BellFillIcon,
      notification: notificationCount,
    },
    {
      route:        ROUTE_THREAD,
      icon:         EnvelopeIcon,
      iconActive:   EnvelopeFillIcon,
      notification: threadUnreadCount,
    },
  ];

  // useEffect(() => {
  //   if (window.matchMedia("(display-mode: standalone)").matches) {
  //     setMenuPadding(true);
  //   }
  // }, []);

  const renderMenuItem: FunctionComponent<Props> = (props: Props, key: number) => {
    const match = matchRoutes([{path: props.route}], location.pathname);

    return (
      <MenuItem key={key} to={props.route}>
        <MenuItemBox>
          <MenuItemIconBox icon={props.icon} iconActive={props.iconActive} className={match ? "active" : ""}/>
          {
            props.notification ? (
              <>
                {
                  props.notification > 0 && (<NotificationCount>{props.notification > 99 ? "99+" : props.notification}</NotificationCount>)
                }
              </>
            ) : ""
          }
        </MenuItemBox>
      </MenuItem>
    );
  };
  return (
    <>
      {
        !matchBuildingCreatePage && (
          <Container menuPadding={menuPadding} className={status ? "active" : ""}>
            <Wrapper>
              {
                menu_items.map((item, i) => (
                  renderMenuItem(item, i)
                ))
              }
            </Wrapper>
          </Container>
        )
      }
    </>
  );
};

export default Menu;
