import {useState}                 from "react";
import {useEffect}                from "react";
import React                      from "react";
import InfiniteScroll             from "react-infinite-scroll-component";
import styled                     from "styled-components";
import BuildingsJobCard           from "../components/buildings-card/BuildingJobCard";
import InfoEmptyContent           from "../components/infobox/InfoEmptyContent";
import LoadingData                from "../components/loading-data/LoadingData";
import ModalBuildingJob           from "../components/modal/ModalBuildingJob";
import PageHeader                 from "../components/page-header/PageHeader";
import Wrapper                    from "../components/wrapper/Wrapper";
import {useAppDispatch}           from "../hooks/useAppDispatch";
import {useAppSelector}           from "../hooks/useAppSelector";
import {fetchBuildingJobInfinite} from "../store/BuildingJobReducer";
import {fetchBuildingJob}         from "../store/BuildingJobReducer";


const StyledWrapper = styled(Wrapper)`
    width: 100%;
`;

const BuildingJobsPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const buildingJobs          = useAppSelector(state => state.buildingJob);
  const dispatch              = useAppDispatch();

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      setTimeout(() => {
        dispatch(fetchBuildingJob());
        setLoading(false);
      }, 400);
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <>
      <PageHeader>Budowy do oddania</PageHeader>
      <StyledWrapper>
        {
          loading ? (
            <LoadingData/>
          ) : (
            <>
              {
                buildingJobs.data && (
                  <>
                    {
                      buildingJobs.data.length > 0 ? (
                        <InfiniteScroll
                          dataLength={buildingJobs.data.length}
                          next={() => dispatch(fetchBuildingJobInfinite(buildingJobs.next_page))}
                          hasMore={true}
                          loader={null}
                          style={{display: "flex", flexWrap: "wrap"}}
                        >
                          {buildingJobs && buildingJobs.data.length > 0 && buildingJobs.data.map((data, i) => (
                            <BuildingsJobCard data={data} key={i}/>
                          ))}
                        </InfiniteScroll>
                      ) : (
                        <InfoEmptyContent>Brak budów do oddania</InfoEmptyContent>
                      )
                    }
                  </>
                )
              }
            </>
          )
        }
      </StyledWrapper>
      <ModalBuildingJob state={buildingJobs.buildingJobModalStatus}/>
    </>
  );
};

export default BuildingJobsPage;
