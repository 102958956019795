import {createSlice} from "@reduxjs/toolkit";


interface BuildingFormState {
  status: boolean;
  id: string | number | undefined;
}

const initialState: BuildingFormState = {
  status: false,
  id:     0,
};

export const buildingFormSlice = createSlice({
  name:     "buildingForm",
  initialState,
  reducers: {
    showBuildingForm: (state, action) => {
      state.status = true;
      state.id     = action.payload;
    },
    hideBuildingForm: (state) => {
      state.status = false;
      state.id     = 0;
    },
  },
});

export const {showBuildingForm, hideBuildingForm} = buildingFormSlice.actions;

export default buildingFormSlice.reducer;
