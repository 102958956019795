import parse, {domToReact, Element, HTMLReactParserOptions} from 'html-react-parser';
import React from 'react';


const useHtmlParser = (description: string) => {

  const options: HTMLReactParserOptions = {
    replace: domNode => {
      if (domNode instanceof Element && domNode.attribs) {
        if (domNode.attribs && domNode.name === 'p') {
          // const props = attributesToProps(domNode.attribs);
          return <>{domToReact(domNode.children)}</>;
        }
      }
    },
  };

  return parse(description, options);
};

export default useHtmlParser;
