const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timer: NodeJS.Timeout;

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise(resolve => {
      if (timer) {
        clearTimeout(timer)
      }

      timer = setTimeout(() => resolve(func(...args)), waitFor)
    })
}

export default debounce;
