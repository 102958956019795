import {css}                         from "styled-components";
import {defaultSecondaryButtonStyle} from "./DefaultSecondaryButtonStyle";


export const defaultSecondaryButtonIconRightStyles = css`
    ${defaultSecondaryButtonStyle};
    text-align: left;
    justify-content: flex-start;
    padding-right: 56px;
    background-position: calc(100% - 12px) 50%;
    background-size: 16px;
    background-repeat: no-repeat;
    position: relative;
`;
