import {useEffect} from "react";


const useKeyPress = (method: () => void | undefined) => {

  const handleKeyPress = (event: KeyboardEvent) => {
    if (window.innerWidth > 861) {
      if (event.key === "Escape") {
        method();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress, false);

    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, []);
};

export default useKeyPress;
