import {useEffect}         from "react";
import {useState}          from "react";
import {FunctionComponent} from "react";
import React               from "react";
import {Outlet}            from "react-router-dom";
import {useAppDispatch}    from "../../hooks/useAppDispatch";
import {fetchUser}         from "../../store/UserReducerNew";
import LoadingData         from "../loading-data/LoadingData";


const CheckUser: FunctionComponent = () => {
  const [forward, setForward] = useState<boolean | undefined>();
  const dispatch              = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUser());
    setForward(true);
  }, []);

  if (forward) {
    return <Outlet/>;
  }

  return <LoadingData/>;
};

export default CheckUser;
