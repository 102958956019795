import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import PointsImage from '../assets/images/points.png';
import ButtonPrimaryForward from '../components/buttons/ButtonPrimaryForward';
import Header from '../components/typography/Header';
import WrapperInfo from '../components/wrapper/WrapperInfo';
import {useAppSelector} from '../hooks/useAppSelector';
import {ROUTE_REWARDS} from '../routing/routes';
import useApi from '../hooks/useApi';


const StyledWrapperInfo = styled(WrapperInfo)`
    background-color: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.white};
    justify-content: flex-start;
    padding-top: 48px;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        max-width: 600px;
        margin: 0 auto;
        border-radius: 8px;
        height: auto;
        padding: 48px 36px;
    }
`;

const Points = styled.div`
    font-size: 120px;
    font-weight: ${props => props.theme.weights.bold};
    height: 140px;
`;

const StyledHeader = styled(Header)`
    margin: 12px 0 24px 0;
    font-size: ${({theme}) => theme.fonts.mobile.lg};
`;

const StyledImg = styled.img`
    width: 260px;
    height: 220px;
    object-fit: contain;
`;

const Page = styled.div`
    width: 100%;
    height: 100%;
    transition: .3s all ease;

    &.active {
        transform: translateX(-100%);
    }

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        &.active {
            transform: none;
        }
    }
`;

const PointsPage = () => {
  const subpage = useAppSelector(state => state.subpage.status);
  const Api = useApi();
  const [data, setData] = useState<number | string>('');

  // api here
  useEffect(() => {
    Api.Point.Get().then(({data}) => {
      setData(data.points);
    });
  }, []);

  return (
    <Page className={subpage ? 'active' : ''}>
      <StyledWrapperInfo>
        <StyledHeader>Twoja liczba punktów:</StyledHeader>
        <Points>
          {
            data && <>{data}</>
          }
        </Points>
        <StyledImg src={PointsImage} alt=""/>
        <ButtonPrimaryForward to={ROUTE_REWARDS}>Zobacz listę nagród</ButtonPrimaryForward>
      </StyledWrapperInfo>
    </Page>
  );
};

export default PointsPage;
