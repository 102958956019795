import axios             from "axios";
import WorkScope         from "../entities/WorkScope";
import Entrypoint        from "../Entrypoint";
import {ResponsePromise} from "../ResponsePromise";


class WorkScopeApi {
  Get(): ResponsePromise< WorkScope[]> {
    return axios.get(Entrypoint.Api.WorkScope.Index);
  }
}

export default WorkScopeApi;
