import axios from 'axios';
import { generatePath } from 'react-router';
import Building from '../entities/Building';
import Entrypoint from '../Entrypoint';
import BuildingCreateRequest from '../request/building/BuildingCreateRequest';
import { ResponsePromise } from '../ResponsePromise';


class BuildingApi {
  List(params?: any): ResponsePromise<{ current_page: number, data: Building[] }> {
    return axios.get(Entrypoint.Api.Building.Index, {
      params: {
        ...params,
      },
    });
  }

  Get(construction: string): ResponsePromise<Building> {
    return axios.get(generatePath(Entrypoint.Api.Building.Show, { construction }));
  }

  Store(construction: BuildingCreateRequest) {
    return axios.post(generatePath(Entrypoint.Api.Building.Store), construction);
  }

  Update(data: BuildingCreateRequest, construction: string) {
    return axios.patch(generatePath(Entrypoint.Api.Building.Update, { construction }), { ...data });
  }

  Search(value: any) {
    return axios.get(Entrypoint.Api.Building.Index, {
      params: {
        ...value,
      },
    });
  }

  Start(construction: string | undefined) {
    return axios.patch(generatePath(Entrypoint.Api.Building.Start, { construction }));
  }

  Finish(construction: string) {
    return axios.patch(generatePath(Entrypoint.Api.Building.Finish, { construction }));
  }

  OfferList(page?: any): ResponsePromise<{ current_page: number, data: Building[] }> {
    return axios.get(Entrypoint.Api.Building.OfferShow);
  }

  OfferStore(construction: any) {
    return axios.post(generatePath(Entrypoint.Api.Building.OfferStore, { construction }));
  }

  OfferEdit(construction: any) {
    return axios.patch(generatePath(Entrypoint.Api.Building.OfferEdit, { construction }));
  }
}

export default BuildingApi;
