import React                       from "react";
import styled                      from "styled-components";
import FacebookIcon                from "../../assets/icons/facebook.svg";
import {defaultPrimaryButtonStyle} from "../../styles/DefaultPrimaryButtonStyle";


const Link = styled.button`
    ${defaultPrimaryButtonStyle};
    padding-left: 24px;
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    display: inline-flex;
    justify-content: center;
    align-items: center;

    img {
        margin-right: 12px;
    }

    span {
        display: block;
    }
`;


const ButtonFacebook = (props: any) => {
  return (
    <Link {...props}>
      <img src={FacebookIcon} alt=""/>
      Facebook
    </Link>
  );
};

export default ButtonFacebook;


