import {GoogleMap, useJsApiLoader} from '@react-google-maps/api';
import React, {useCallback, FunctionComponent} from 'react';
import Building from '../../api/entities/Building';
import {STATUS_TYPE} from '../../types/StatusType';
import mainTheme from '../../theme/MainTheme';


interface Props {
  buildings: Building[] | undefined;
}

const containerStyle = {
  width: '100%',
  height: '100%',
};

const renderMarkerColor = (status: string | null) => {
  switch (status) {
    case (STATUS_TYPE.ACCEPTED):
      return mainTheme.colors.success;
    case(STATUS_TYPE.REPORTED):
      return mainTheme.colors.warning;
    case(STATUS_TYPE.STARTED):
      return mainTheme.colors.started;
    case(STATUS_TYPE.FINISHED):
      return mainTheme.colors.gray;
    case(STATUS_TYPE.OFFERED):
      return mainTheme.colors.offered;
    default:
      return mainTheme.colors.primary;
  }
};

// bootstrapURLKeys={{key: "AIzaSyBNj0Si3dyaGAzvdP1w0pF0M-tG0Dvmz8k"}} br
// bootstrapURLKeys={{key: "AIzaSyAcFk0sEJvkWS0wttA2buigAlmiBN7gxrg"}} iv

const GoogleMapComponent: FunctionComponent<Props> = ({buildings}) => {
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBNj0Si3dyaGAzvdP1w0pF0M-tG0Dvmz8k',
  });

  const [map, setMap] = React.useState(null);

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    const geocoder = new window.google.maps.Geocoder();

    if (buildings) {
      buildings.forEach((building: Building) => {

        const infoWindow = new window.google.maps.InfoWindow({
          content: `
                  <div style="min-width: 200px">
                    <div style="background-color: ${renderMarkerColor(building.paver_status)}; margin: 8px 0; padding: 2px 6px; color: white; font-weight: 700; border-radius: 3px; text-align: center">${building.paver_status}</div>
                    <div><b>${building.title}</div></b>
                    <div>${building.firstname} ${building.lastname}</div>
                    <div>${building.street} ${building.building_nr}${building.apartment_nr ? `/${building.apartment_nr}` : ''}</div>
                    <div>${building.postcode} ${building.city}</div>
                  </div>
                `,
        });

        const svgMarker = {
          path: 'M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z',
          fillColor: renderMarkerColor(building.paver_status),
          fillOpacity: 1,
          strokeWeight: 0,
          rotation: 0,
          scale: 3,
          anchor: new window.google.maps.Point(8, 16),
        };

        if (building.city) {
          geocoder.geocode({'address': `${building.street} ${building.building_nr}${building.apartment_nr ? `/${building.apartment_nr}` : ''}, ${building.city}`}, (results, status) => {
            if (status === 'OK') {
              if (results) {
                results.forEach((result) => {
                  const marker = new window.google.maps.Marker({
                    position: result.geometry.location,
                    icon: svgMarker,
                  });

                  bounds.extend({
                    lat: result.geometry.location.lat(),
                    lng: result.geometry.location.lng(),
                  });

                  map.fitBounds(bounds);
                  marker.setMap(map);
                  marker.addListener('click', () => {
                    infoWindow.open({
                      anchor: marker,
                      map,
                      shouldFocus: false,
                    });
                  });
                });
              }
            } else {
              console.log('Geocode was not successful for the following reason: ' + status);
            }
          });
        }
      });
    }

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      onLoad={onLoad}
      zoom={10}
      onUnmount={onUnmount}
    />
  ) : <></>;
};

export default GoogleMapComponent;
