import {enableBodyScroll, disableBodyScroll} from 'body-scroll-lock';
import React, {useEffect, useRef, FunctionComponent} from 'react';
import {useNavigate} from 'react-router';
import {useSwipeable} from 'react-swipeable';
import {CSSTransition} from 'react-transition-group';
import styled from 'styled-components';
import useApi from '../../hooks/useApi';
import {useAppDispatch} from '../../hooks/useAppDispatch';
import {useAppSelector} from '../../hooks/useAppSelector';
import useClickOutside from '../../hooks/useClickOutside';
import useKeyPress from '../../hooks/useKeyPress';
import {ROUTE_HOME, ROUTE_REWARDS} from '../../routing/routes';
import {enableGlobalSwipe, blockGlobalSwipe} from '../../store/ModalReducer';
import {hideRewardSuccess, showRewardSuccess, hideRewardModal} from '../../store/RewardReducer';
import {fetchUser} from '../../store/UserReducerNew';
import ButtonPrimaryForward from '../buttons/ButtonPrimaryForward';
import ButtonSecondaryClose from '../buttons/ButtonSecondaryClose';
import InfoRewardSuccess from '../infobox/InfoRewardSuccess';
import Header from '../typography/Header';
import {defaultModalCloseStyles, defaultModalWrapperStyles, defaultModalStyles} from './defaultModalStyles';


const Container = styled.div`
    ${defaultModalStyles};
    opacity: 1;
    visibility: visible;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        align-items: center;
    }
`;

const Wrapper = styled.div`
    ${defaultModalWrapperStyles}
`;

const CloseModal = styled.button`
    ${defaultModalCloseStyles};
`;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-top: 12px;
    max-width: 300px;

    button {
        width: calc(50% - 4px);
    }

    &.centered {
        justify-content: center;
    }
`;

const StyledHeader = styled(Header)`
    width: 100%;
    text-align: center;
    margin-bottom: 18px;
    font-size: ${props => props.theme.fonts.mobile.md};
`;


interface Props {
  state: boolean;
}

const ModalReward: FunctionComponent<Props> = ({state}) => {
  const ref         = useRef<any>();
  const refWrapper  = useRef<any>();
  const dispatch    = useAppDispatch();
  const wrapperInfo = useAppSelector(state => state.reward.rewardSuccessInfoPage);
  const navigate    = useNavigate();
  const Api         = useApi();
  const rewards     = useAppSelector(state => state.reward);

  const getReward = () => {
    if (rewards.rewardId) {
      if (rewards.data) {
        const reward = rewards.data.find(reward => reward.id === rewards.rewardId);
        Api.User.Get().then(({data}) => {
          if (reward) {
            if (data.points >= reward.price) {
              Api.Reward.Store(reward.id).then(() => {
                handleClose();
                dispatch(fetchUser());
                dispatch(showRewardSuccess());
              });
            } else {
              alert('Nie masz wystarczającej liczby punktów');
              dispatch(hideRewardModal());
              handleClose();
              navigate(ROUTE_REWARDS);
            }
          }
        });
      }
    }
  };

  useEffect(() => {
    if (state) {
      disableBodyScroll(ref.current);
      dispatch(blockGlobalSwipe());
    }
  }, [state]);

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => {
      if (window.innerWidth < 860) {
        handleClose();
      }
    },
    delta:        90,
    trackTouch:   true,
    trackMouse:   true,
  });

  const refPassThrough = (el: any) => {
    swipeHandlers.ref(el);
    ref.current = el;
  };

  const handleClose = () => {
    dispatch(hideRewardModal());
    dispatch(enableGlobalSwipe());
    enableBodyScroll(ref.current);
  };

  useClickOutside(refWrapper, handleClose);

  useKeyPress(handleClose);

  const handleInfoClose = () => {
    handleClose();
    dispatch(hideRewardSuccess());
    navigate(ROUTE_HOME);
  };

  return (
    <>
      {
        !wrapperInfo ? (
          <CSSTransition
            in={state}
            timeout={200}
            classNames="modal-fade"
            unmountOnExit
          >
            <Container {...swipeHandlers} ref={refPassThrough}>
              <Wrapper className="modal-wrapper" ref={refWrapper}>
                <CloseModal onClick={() => handleClose()}/>
                <StyledHeader>
                  Czy na pewno chcesz wybrać tę nagrodę?
                </StyledHeader>
                <ButtonsWrapper>
                  <ButtonPrimaryForward type="button" onClick={getReward}>Tak</ButtonPrimaryForward>
                  <ButtonSecondaryClose type="button" onClick={() => handleClose()}>Nie</ButtonSecondaryClose>
                </ButtonsWrapper>
              </Wrapper>
            </Container>
          </CSSTransition>
        ) : (
          <InfoRewardSuccess handleClose={handleInfoClose}/>
        )
      }
    </>

  );
};

export default ModalReward;
