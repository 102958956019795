import axios             from "axios";
import {generatePath}    from "react-router";
import Entrypoint        from "../Entrypoint";
import UserCreateRequest from "../request/user/UserCreateRequest";
import UserEditRequest   from "../request/user/UserEditRequest";
import UserFillRequest   from "../request/user/UserFillRequest";


class UserApi {

  Get() {
    return axios.get(generatePath(Entrypoint.Api.User.Show));
  }

  Fill(user: UserFillRequest) {
    return axios.patch(generatePath(Entrypoint.Api.User.Fill), user);
  }

  Store(user: UserCreateRequest) {
    return axios.post(generatePath(Entrypoint.Api.User.Store), user);
  }

  Update(user: UserEditRequest) {
    return axios.post(generatePath(Entrypoint.Api.User.Update), user);
  }
}

export default UserApi;
