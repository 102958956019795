import moment from "moment";


const useMomentConverter = (date: string | undefined) => {

  const currentDay = new Date();
  let dateConvert;

  if (moment(date).isSame(currentDay, "day")) {
    dateConvert = moment(date).format("HH:mm:ss");
  } else {
    dateConvert = moment(date).format("DD.MM.YYYY");
  }

  return dateConvert;
};

export default useMomentConverter;
