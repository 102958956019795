import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import usePushNotifications from '../../hooks/usePushNotifications';
import {removeNotificationSubscription} from '../../serviceWorkerRegistration';
import HeaderSmall from '../typography/HeaderSmall';
import Paragraph from '../typography/Paragraph';
import ButtonPrimary from '../buttons/ButtonPrimary';


const CardWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #BBD4FA;
    }
`;

const TextBox = styled.div`
    padding-right: 12px;
    width: calc(100% - 60px);

`;

const PushNotificationsToggle = () => {
  const [showNotifications, setShowNotification] = useState<boolean>(false);
  const {
          checkUserSubscription,
          onClickSubscribeToPushNotification,
        }                                        = usePushNotifications();


  const setSubscription = () => {
    if ('Notification' in window) {
      console.log('pre-check');

      checkUserSubscription().then(response => {
        console.log('check');
        if (response) {
          console.log('remove');
          setShowNotification(false);
          removeNotificationSubscription();
        } else {
          console.log('add');
          onClickSubscribeToPushNotification();
          setShowNotification(true);
        }
      });
    }
  };

  useEffect(() => {
    if ('Notification' in window) {
      checkUserSubscription().then(response => {
        if (response) {
          setShowNotification(true);
        } else {
          setShowNotification(false);
        }
      });
    }
  }, []);

  const handleClick = () => {
    if ('Notification' in window) {
      if (Notification.permission !== 'granted') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            setSubscription();
          }
        });
      } else {
        setSubscription();
      }
    }
  };

  return (
    <CardWrapper>
      <TextBox>
        <HeaderSmall>Powiadomienia</HeaderSmall>
        <Paragraph>Włącz lub wyłącz powiadomienia typu push</Paragraph>
      </TextBox>

      {
        showNotifications ? (
          <ButtonPrimary onClick={handleClick}>Wyłącz</ButtonPrimary>
        ) : (
          <ButtonPrimary onClick={handleClick}>Włącz</ButtonPrimary>
        )
      }

      {/*<InputSwitch checked={showNotifications} handleChange={handleChange} name="push-notifications"/>*/}
    </CardWrapper>
  );
};

export default PushNotificationsToggle;
