import {useState}           from "react";
import {useEffect}          from "react";
import React                from "react";
import InfiniteScroll       from "react-infinite-scroller";
import {generatePath}       from "react-router-dom";
import styled               from "styled-components";
import Article              from "../../api/entities/Article";
import ArticleCard          from "../../components/article-card/ArticleCard";
import InfoEmptyContent     from "../../components/infobox/InfoEmptyContent";
import LoadingData          from "../../components/loading-data/LoadingData";
import PageHeader           from "../../components/page-header/PageHeader";
import Wrapper              from "../../components/wrapper/Wrapper";
import useApi               from "../../hooks/useApi";
import {useAppSelector}     from "../../hooks/useAppSelector";
import Subpage              from "../../layout/Subpage";
import {ROUTE_ARTICLES}     from "../../routing/routes";
import {ROUTE_ARTICLE_SHOW} from "../../routing/routes";


const StyledWrapper = styled(Wrapper)`
    display: flex;
    flex-wrap: wrap;
`;

const Page = styled.div`
    width: 100%;
    height: 100%;
    transition: .3s all ease;

    &.active {
        transform: translateX(-100%);
    }

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        &.active {
            transform: none;
        }
    }
`;

const ArticleIndexPage = () => {
  const Api                       = useApi();
  const [dataItems, setDataItems] = useState<Article[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages]         = useState(1);
  const [lastPage, setLastPage]   = useState<number | undefined>();
  const subpage                   = useAppSelector(state => state.subpage.status);
  const [loading, setLoading]     = useState<boolean>(true);

  const setArticleData = (pages: number) => {
    Api.Article.List({page: pages}).then(({data}) => {
      setLastPage(data.last_page);
      setDataItems([...dataItems, ...data.data]);
      setIsLoading(false);
    });
  };

  const loadData = () => {
    if (!isLoading) {
      if (lastPage && (pages <= lastPage)) {
        setPages((pages) => pages + 1);
        setIsLoading(true);
        setArticleData(pages + 1);
      }
    }
  };

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      setTimeout(() => {
        Api.Article.List({page: pages}).then(({data}) => {
          setLastPage(data.last_page);
          setDataItems(data.data);
          setLoading(false);
        });
      }, 400);
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  return (

    <>
      <Page className={subpage ? "active" : ""}>
        <PageHeader>Aktualności</PageHeader>
        <div>
          {
            loading ? (
              <LoadingData/>
            ) : (
              <InfiniteScroll
                loadMore={() => loadData()}
                hasMore={true}
                initialLoad={false}
              >
                <StyledWrapper>
                  {dataItems && dataItems.length > 0 ? (dataItems.map((data, i) => (
                    <ArticleCard route={generatePath(ROUTE_ARTICLE_SHOW, {id: data.id})} data={data} key={i}/>
                  ))) : (
                    <InfoEmptyContent>Brak aktualności</InfoEmptyContent>
                  )}
                </StyledWrapper>
              </InfiniteScroll>
            )
          }
        </div>
      </Page>

      {/*this is a sub-page with article details*/}
      <Subpage parentRoute={ROUTE_ARTICLES}/>
    </>

  );
};

export default ArticleIndexPage;

