import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice}   from "@reduxjs/toolkit";


interface SubpageState {
  status: boolean;
}

const initialState: SubpageState = {
  status: false,
};

export const subpageSlice = createSlice({
  name:     "subpage",
  initialState,
  reducers: {
    showSubpage:       (state) => {
      state.status = true;
    },
    hideSubpage:       (state) => {
      state.status = false;
    },
    setSubpage: (state, action: PayloadAction<boolean>) => {
      state.status = action.payload;
    },
  },
});

export const {showSubpage, hideSubpage, setSubpage} = subpageSlice.actions;

export default subpageSlice.reducer;
