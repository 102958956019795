import { useEffect } from "react";
import { useState } from "react";
import { FunctionComponent } from "react";
import React from "react";
import { css } from "styled-components";
import styled from "styled-components";
import ThreadMessage from "../../api/entities/ThreadMessage";
import FileIcon from "../../assets/icons/file-icon.svg";
import PdfIcon from "../../assets/icons/icon-pdf.svg";
import ZipIcon from "../../assets/icons/icon-zip.svg";
import useApi from "../../hooks/useApi";
import useHtmlParser from "../../hooks/useHtmlParser";
import useMomentConverter from "../../hooks/useMomentConverter";
import { AttachmentType } from "../../types/AttachmentType";
import Paragraph from "../typography/Paragraph";

const MessageWrapper = styled.div`
  max-width: 88%;
  min-width: 240px;
  padding: 8px 18px;
  border-radius: 8px;
  box-shadow: 0 0 8px -4px rgba(0, 0, 0, 0.4);
  background-color: #fff;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    max-width: 60%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_small}) {
    background-color: #f6f6f6;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
  position: relative;

  &.user-message {
    justify-content: flex-end;

    ${MessageWrapper} {
      background-color: ${(props) => props.theme.colors.border};
    }
  }
`;

const MessageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const AttachmentsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 12px;

  &.user-message {
    justify-content: flex-end;
  }
`;

const AttachmentItem = styled.div`
  width: 50px;
  height: 42px;
  display: flex;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:not(:nth-child(5n)) {
    margin-right: 10px;
  }
`;

const defaultFileStyle = css`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  background-size: 24px 36px;
  background-position: center 4px;
  display: flex;
  align-items: flex-end;
  padding: 8px;

  span {
    font-size: 10px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const FakePdf = styled.div`
  ${defaultFileStyle};
  background-image: url(${PdfIcon});
`;

const FakeZip = styled.div`
  ${defaultFileStyle};
  background-image: url(${ZipIcon});
`;

const FakeFile = styled.div`
  ${defaultFileStyle};
  background-image: url(${FileIcon});
`;

interface Props {
  thread: string;
  data: ThreadMessage;
  showImageDetail: (src: string) => void;
}

const MessageCard: FunctionComponent<Props> = ({ thread, data, showImageDetail }) => {
  const Api = useApi();
  const [base64, setBase64] = useState<any>({});

  useEffect(() => {
    data.attachments.forEach((attachment) => {
      if (attachment.file_type.includes("image")) {
        Api.Thread.GetFile(thread, data.id, attachment.id).then((response: any) => {
          const blob = URL.createObjectURL(response.data);
          setBase64((prevState: any) => ({
            ...prevState,
            [attachment.file_name]: blob,
          }));
        });
      }
    });
  }, []);

  const handleFileDownload = (id: string, attachment: any) => {
    Api.Thread.GetFile(thread, data.id, id).then((response: any) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new File([response.data], attachment.file_name));
      link.download = attachment.file_name;

      document.body.appendChild(link);

      link.click();

      setTimeout(function () {
        window.URL.revokeObjectURL(link.href);
      }, 200);
    });
  };

  const attachmentFileRender = (attachment: AttachmentType) => {
    if (attachment.file_type.includes("image")) {
      return (
        <img
          src={base64[attachment.file_name]}
          alt=""
          onClick={() => showImageDetail(base64[attachment.file_name])}
          id="img-3"
        />
      );
    } else if (attachment.file_type.includes("pdf")) {
      return <FakePdf onClick={() => handleFileDownload(attachment.id, attachment)} />;
    } else if (attachment.file_type.includes("zip")) {
      return <FakeZip onClick={() => handleFileDownload(attachment.id, attachment)} />;
    } else {
      return <FakeFile onClick={() => handleFileDownload(attachment.id, attachment)} />;
    }
  };

  return (
    <MessageContainer className={data.user_id && "user-message"}>
      <MessageWrapper>
        <MessageHeader>
          <Paragraph>
            <b>{data.administrator ? `${data.administrator.name}:` : "Ja:"}</b>
          </Paragraph>
          <Paragraph>{useMomentConverter(data.created_at)}</Paragraph>
        </MessageHeader>
        {data.content && (
          <Paragraph>
            {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
            {useHtmlParser(data.content)}
          </Paragraph>
        )}
      </MessageWrapper>
      {data.attachments.length > 0 && (
        <AttachmentsWrapper className={data.user_id && "user-message"}>
          {data.attachments.map((attachment, index) => (
            <AttachmentItem key={index}>{attachmentFileRender(attachment)}</AttachmentItem>
          ))}
        </AttachmentsWrapper>
      )}
    </MessageContainer>
  );
};

export default MessageCard;
