import styled from "styled-components";


const FormGroup = styled.div`
    display: block;
    margin-bottom: 14px;
    
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }

    &.validation-error {

        label {
            color: ${({theme}) => theme.colors.danger}
        }

        input, textarea, span:before {
            border-color: ${({theme}) => theme.colors.danger};
        }

        span {
            color: ${({theme}) => theme.colors.danger};

            a {
                color: ${({theme}) => theme.colors.danger};
            }
        }
    }
`;
export default FormGroup;
