import { clearAllBodyScrollLocks, enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { Form, Field, Formik } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Building from '../../api/entities/Building';
import BuildingCreateRequest from '../../api/request/building/BuildingCreateRequest';
import InputDate from '../../form/controls/InputDate';
import InputSelect from '../../form/controls/InputSelect';
import InputText from '../../form/controls/InputText';
import FormSubmit from '../../form/internals/FormSubmit';
import useApi from '../../hooks/useApi';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { hideBuildingForm } from '../../store/BuildingForm';
import { showBuildingUpdateInfo, showBuildingCreateInfo, showBuildingDuplicateInfo } from '../../store/BuildingReducer';
import ButtonPrimaryAdd from '../buttons/ButtonPrimaryAdd';
import { defaultModalCloseStyles } from '../modal/defaultModalStyles';
import HeaderSmall from '../typography/HeaderSmall';
import Wrapper from '../wrapper/Wrapper';
import InputEditor from '../../form/controls/InputEditor';


const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    max-width: 100vw;
    width: 100%;
    height: 100vh;
    z-index: 1000000;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    padding-top: 24px;
`;

const StyledWrapper = styled(Wrapper)`
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    padding: 24px 18px;
    max-width: 650px;
    position: relative;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_small}) {
        margin-left: auto;
        margin-right: auto;
        padding: 24px 24px 48px 24px;
    }
`;

const BuildingCreateForm = styled(Form)`
    margin-bottom: 64px;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_big}) {
        max-width: 600px;
        margin-bottom: 0;
    }
`;

const FormPartInputsWrapper = styled.div`
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_big}) {
        display: flex;
        flex-wrap: wrap;
        &:not(:last-child) {
            margin-bottom: 18px;
            padding-bottom: 18px;
            border-bottom: 1px solid ${({ theme }) => theme.colors.border};
        }

        & > div {
            width: calc(50% - 12px);

            &:not(:last-child) {
                margin-right: 12px;
            }
        }

        &.full-width {
            & > div {
                width: 100%;
            }
        }
    }
`;

const StyledHeaderSmall = styled(HeaderSmall)`
    margin: 12px 0 24px 0;
`;

const FormPart = styled.div`
    &:not(:last-child) {
        margin-bottom: 24px;
    }
`;

const FormHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    margin-bottom: 24px;
    padding-bottom: 12px;
`;

const BuildingTypeWrapper = styled.div`
    margin-bottom: 18px;
    border: 1px solid #2AB7F8;
    width: 100%;
    font-size: 12px;
    height: 38px;
    border-radius: 5px;
    display: flex;
`;


const BuildingTypeItem = styled.label`
    width: 50%;
    height: 100%;
    position: relative;

    input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-weight: 500;
    }

    input:checked + span {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
    }
`;

const CustomLabel = styled.label`
    display: flex;
    align-items: center;
    font-size: ${props => props.theme.fonts.mobile.sm};
    font-weight: ${props => props.theme.weights.semiBold};
    line-height: 1.4;
    margin-bottom: 6px;

    div {
        margin-left: 4px;
    }
`;

const CloseButton = styled.button`
    ${defaultModalCloseStyles};
    position: relative;
    top: inherit;
    right: inherit;
`;

const BuildingForm = () => {
  const Api = useApi();
  const formContainerRef = useRef<any>();
  const dispatch = useAppDispatch();
  const [scope, setScope] = useState<{ value: string, label: string }[]>();
  const userIsDesigner = useAppSelector(state => state.newUser.user?.type);
  const buildingFormState = useAppSelector(state => state.buildingForm);
  const [data, setData] = useState<Building>();
  const [scopeData, setScopeData] = useState<any>();

  const initialValues: BuildingCreateRequest = {
    project_nr: data ? data.project_nr : '',
    title: data ? data.title : '',
    firstname: data ? data.firstname : '',
    lastname: data ? data.lastname : '',
    street: data ? data.street : '',
    building_nr: data ? data.building_nr : '',
    apartment_nr: data ? data.apartment_nr : '',
    postcode: data ? data.postcode : '',
    city: data ? data.city : '',
    phone: data ? data.phone : '',
    email: data ? data.email : '',
    note: data ? data.note : '',
    note_designer: data ? data.note_designer : '',
    note_paver: data ? data.note_paver : '',
    start_date: data ? data.start_date : '',
    end_date: data ? data.end_date : '',
    scope: scopeData,
    is_offered: data ? data.is_offered : '0',
    project: data ? data.project : '',
  };

  useEffect(() => {
    if (buildingFormState.status) {
      disableBodyScroll(formContainerRef.current);
    }
  }, [buildingFormState]);

  useEffect(() => {
    if (buildingFormState.id) {
      const id = buildingFormState.id.toString();
      Api.Building.Get(id).then(({ data }) => {
        setData(data);
        setScopeData(data.scopes.map((element: any) => element.id));
      });
    }
  }, [buildingFormState.id]);

  useEffect(() => {
    Api.Scope.Get().then(({ data }) => {
      if (data) {
        setScope(data.map((data) => ({ value: data.id, label: data.name })));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    enableBodyScroll(formContainerRef.current);
    clearAllBodyScrollLocks();
    dispatch(hideBuildingForm());
  };

  return (
    <Container ref={formContainerRef}>
      <StyledWrapper>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values: BuildingCreateRequest, actions) => {

            if (!buildingFormState.id) {
              Api.Building.Store(values)
                .then(({ data }) => {
                  dispatch(showBuildingDuplicateInfo(data.is_warning));
                  dispatch(showBuildingCreateInfo(true));
                  handleClose();
                })
                .catch(error => {
                  console.error(error);
                  formContainerRef.current.scroll(0, 0);
                  actions.setErrors(error.response.data.errors);
                });
            } else {
              Api.Building.Update(values, buildingFormState.id.toString())
                .then(({ data }) => {
                  dispatch(showBuildingDuplicateInfo(data.is_warning));
                  dispatch(showBuildingUpdateInfo(true));
                  handleClose();
                })
                .catch(error => {
                  console.error(error);
                  formContainerRef.current.scroll(0, 0);
                  actions.setErrors(error.response.data.errors);
                });
            }
          }}
        >
          <BuildingCreateForm autoComplete="off">
            <FormPart>
              <FormHeader>
                <HeaderSmall>
                  {
                    buildingFormState.id ? 'Edytuj budowę' : 'Dodaj budowę'
                  }
                </HeaderSmall>
                <CloseButton onClick={handleClose}/>
              </FormHeader>
              {
                !buildingFormState.id && (
                  <div>
                    <CustomLabel>
                      Rodzaj budowy
                      {/*<div>*/}
                      {/*  <TooltipButton type={TOOLTIP_BUILDING_CHOOSE}/>*/}
                      {/*</div>*/}
                    </CustomLabel>
                    <BuildingTypeWrapper>
                      <BuildingTypeItem>
                        <Field type="radio" id="is_offered" name="is_offered" value="0"/>
                        <span>Własna</span>
                      </BuildingTypeItem>
                      <BuildingTypeItem>
                        <Field type="radio" id="is_offered" name="is_offered" value="1"/>
                        <span>Budowa do oddania</span>
                      </BuildingTypeItem>
                    </BuildingTypeWrapper>
                  </div>
                )
              }
              <FormPartInputsWrapper>
                <InputText label="Nazwa budowy / Tytuł" name="title" required/>
              </FormPartInputsWrapper>
              <FormPartInputsWrapper>
                <InputText label="Imię" name="firstname"/>
                <InputText label="Nazwisko" name="lastname"/>
              </FormPartInputsWrapper>
              <FormPartInputsWrapper>
                <InputText label="Telefon" name="phone" required/>
                <InputText label="E-mail" name="email"/>
              </FormPartInputsWrapper>
              <FormPartInputsWrapper>
                <InputText label="Ulica" name="street"/>
                <InputText label="Numer domu" name="building_nr"/>
                <InputText label="Numer mieszkania" name="apartment_nr"/>
                <InputText label="Kod pocztowy" name="postcode"/>
                <InputText label="Miasto/Miejscowość" name="city"/>
              </FormPartInputsWrapper>
            </FormPart>
            <FormPart>
              <StyledHeaderSmall>Szacunkowe informacje o budowie</StyledHeaderSmall>
              <FormPartInputsWrapper>
                {
                  scope && (
                    <InputSelect label="Zakres robót (wielokrotny wybór)" name="scope" options={scope} placeholder="Wybierz z listy" multiple/>
                  )
                }
              </FormPartInputsWrapper>
              <FormPartInputsWrapper>
                <InputText label="Szacunkowa ilość m2" name="square_meter"/>
                <InputText label="Szacunkowa ilość mb" name="running_meter"/>
              </FormPartInputsWrapper>
              <FormPartInputsWrapper>
                <InputDate label="Początek" name="start_date"/>
                <InputDate label="Koniec" name="end_date"/>
              </FormPartInputsWrapper>
              <FormPartInputsWrapper>
                <InputText label="Numer projektu" name="project_nr"/>
              </FormPartInputsWrapper>
              <FormPartInputsWrapper>
                <InputText label="Projekt w Google" name="project"/>
              </FormPartInputsWrapper>
              <FormPartInputsWrapper className="full-width">
                <InputEditor label="Informacje dodatkowe" name="note"/>
              </FormPartInputsWrapper>
              <FormPartInputsWrapper className="full-width">
                {
                  userIsDesigner === 'Paver' ? (
                    <InputEditor label="Notatka prywatna" name="note_paver"/>

                  ) : (
                    <InputEditor label="Notatka prywatna" name="note_designer"/>
                  )
                }
              </FormPartInputsWrapper>
              <FormSubmit>
                <ButtonPrimaryAdd type="submit">
                  {
                    buildingFormState.id ? 'Zapisz zmiany' : 'Dodaj budowę'
                  }
                </ButtonPrimaryAdd>
              </FormSubmit>
            </FormPart>
          </BuildingCreateForm>
        </Formik>
      </StyledWrapper>
    </Container>
  );
};

export default BuildingForm;
