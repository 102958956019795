import {createAsyncThunk} from "@reduxjs/toolkit";
import {PayloadAction}    from "@reduxjs/toolkit";
import {createSlice}      from "@reduxjs/toolkit";
import Api                from "../api/Api";
import ThreadMessage      from "../api/entities/ThreadMessage";


interface MessageState {
  id: string | undefined;
  current_page: number;
  next_page: number;
  last_page: number | undefined;
  data: ThreadMessage[] | undefined;
  thead_title: string | null;
  total: number;
  is_active: number;
}

const initialState: MessageState = {
  id:           undefined,
  current_page: 0,
  next_page:    0,
  last_page:    0,
  thead_title:  null,
  total:        0,
  data:         [],
  is_active:    1,
};

const client = new Api();

export const fetchMessageInfinite = createAsyncThunk("messages/infinite", async (page: any, {getState}) => {
  const state: any = getState();

  const response = await client.Thread.Get(state.message.id, page);

  return {
    data:         [...state.message.data, ...response.data.messages.data],
    current_page: response.data.messages.current_page,
    next_page:    response.data.messages.current_page + 1,
  };
});

export const fetachMessage = createAsyncThunk("messages", async (id: string) => {
  const response = await client.Thread.Get(id);
  return response.data;
});


export const messageSlice = createSlice({
  name:          "message",
  initialState,
  reducers:      {
    clearMessage: (state: any) => {
      state.data        = [];
      state.thead_title = "";
    },
  },
  extraReducers: ((builder: any) => {
    builder.addCase(fetachMessage.fulfilled, (state: MessageState, action: PayloadAction<any>) => {
      state.id           = action.payload.thread.id;
      state.data         = action.payload.messages.data.reverse();
      state.last_page    = action.payload.messages.last_page;
      state.current_page = action.payload.messages.current_page;
      state.next_page    = action.payload.messages.current_page + 1;
      state.total        = action.payload.messages.total;
      state.thead_title  = action.payload.thread.title;
      state.is_active    = action.payload.thread.is_active;
    });
  }),
});

export const {clearMessage} = messageSlice.actions;


export default messageSlice.reducer;
