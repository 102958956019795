import { createSlice } from '@reduxjs/toolkit';


interface ModalBuildingState {
  statusStart: boolean;
  statusFinish: boolean;
  offeredBuildingModal: boolean;
  buildingModalStatus: boolean;
  buildingId: string | undefined;
  showInfoAboutStart: boolean;
  showInfoAboutFinish: boolean;
}

const initialState: ModalBuildingState = {
  statusStart: false,
  statusFinish: false,
  offeredBuildingModal: false,
  buildingModalStatus: false,
  buildingId: '',
  showInfoAboutStart: false,
  showInfoAboutFinish: false,
};


export const modalBuildingSlice = createSlice({
  name: 'modalBuilding',
  initialState,
  reducers: {
    showStartModal: (state, action) => {
      state.statusStart = true;
      state.buildingId = action.payload;
    },
    hideStartModal: (state) => {
      state.statusStart = false;
      state.buildingId = '';
    },
    showFinishModal: (state, action) => {
      state.statusFinish = true;
      state.buildingId = action.payload;
    },
    hideFinishModal: (state) => {
      state.statusFinish = false;
      state.buildingId = '';
    },
    hideOfferedBuildingModal: (state) => {
      state.offeredBuildingModal = false;
    },
    showOfferedBuildingModal: (state) => {
      state.offeredBuildingModal = true;
    },
    showInfoAboutStart: (state, action) => {
      state.showInfoAboutStart = action.payload;
    },
    showInfoAboutFinish: (state, action) => {
      state.showInfoAboutFinish = action.payload;
    },
  },
});

export const { showStartModal, hideStartModal, showFinishModal, hideFinishModal, hideOfferedBuildingModal, showOfferedBuildingModal, showInfoAboutStart, showInfoAboutFinish } = modalBuildingSlice.actions;

export default modalBuildingSlice.reducer;
