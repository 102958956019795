import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import useClickOutside from '../../hooks/useClickOutside';
import useKeyPress from '../../hooks/useKeyPress';
import { hideOfferedBuildingModal } from '../../store/ModalBuildingReducer';
import { enableGlobalSwipe, blockGlobalSwipe } from '../../store/ModalReducer';
import Header from '../typography/Header';
import { defaultModalCloseStyles, defaultModalWrapperStyles, defaultModalStyles } from './defaultModalStyles';
import { useNavigate } from 'react-router';
import { ROUTE_HOME } from '../../routing/routes';
import ButtonPrimaryForward from '../buttons/ButtonPrimaryForward';
import { fetchBuilding } from '../../store/BuildingReducer';


const Container = styled.div`
    ${defaultModalStyles};
    opacity: 1;
    visibility: visible;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_small}) {
        align-items: center;
    }
`;

const Wrapper = styled.div`
    ${defaultModalWrapperStyles}
`;

const CloseModal = styled.button`
    ${defaultModalCloseStyles};
`;

const StyledHeader = styled(Header)`
    width: 100%;
    margin: 12px 0 18px 0;
    font-size: ${props => props.theme.fonts.mobile.lg};
    font-weight: ${props => props.theme.weights.medium};
    text-align: center;
`;

const ModalButtonsWrapper = styled.div`
    display: flex;
    padding-top: 12px;
    width: 100%;
    justify-content: center;

    button {
        width: 140px;
        margin: 0 4px;
    }
`;


interface Props {
  state: boolean;
}

const ModalBuildingOffered: FunctionComponent<Props> = ({ state }) => {
  const ref = useRef<any>();
  const refWrapper = useRef<any>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (state) {
      disableBodyScroll(ref.current);
      dispatch(blockGlobalSwipe());
    }
  }, [state]);

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => {
      if (window.innerWidth < 860) {
        handleClose();
      }
    },
    delta: 30,
    trackTouch: true,
    trackMouse: true,
  });

  const refPassThrough = (el: any) => {
    swipeHandlers.ref(el);
    ref.current = el;
  };

  const handleClose = () => {
    enableBodyScroll(ref.current);
    dispatch(enableGlobalSwipe());
    dispatch(hideOfferedBuildingModal());
    dispatch(fetchBuilding());
    navigate(ROUTE_HOME);
  };

  useClickOutside(refWrapper, handleClose);

  useKeyPress(handleClose);

  return (
    <>
      <CSSTransition
        in={state}
        timeout={200}
        classNames="modal-fade"
        unmountOnExit
      >
        <Container {...swipeHandlers} ref={refPassThrough}>
          <Wrapper className="modal-wrapper" ref={refWrapper}>
            <CloseModal onClick={handleClose}/>
            <StyledHeader>
              Twoje zgłoszenie zostało przekazane do weryfikacji przez administratora.
            </StyledHeader>
            <ModalButtonsWrapper>
              <ButtonPrimaryForward onClick={handleClose}>Zamknij</ButtonPrimaryForward>
            </ModalButtonsWrapper>
          </Wrapper>
        </Container>
      </CSSTransition>
    </>
  );
};

export default ModalBuildingOffered;
