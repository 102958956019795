import {useEffect} from "react";


const useClickOutside = (ref: any, method: () => void | undefined) => {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (window.innerWidth > 861) {

        if (ref.current && !ref.current.contains(event.target)) {
          method();
        }
      }
    }

    document.addEventListener("mousedown", (event) => handleClickOutside(event));
    return () => {
      document.removeEventListener("mousedown", (event) => handleClickOutside(event));
    };
  }, [ref]);
};

export default useClickOutside;
