export const TOOLTIP_BUILDING_CHOOSE = "TOOLTIP_BUILDING_CHOOSE";
export const TOOLTIP_BUILDING_SCOPE  = "TOOLTIP_BUILDING_SCOPE";

export default {
  TOOLTIP_BUILDING_CHOOSE,
  TOOLTIP_BUILDING_SCOPE,
};

export const DataTooltips = [
  {
    type: "TOOLTIP_BUILDING_CHOOSE",
    data: {
      title:       "Rodzaj budowy",
      description: "          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum laboriosam odio optio quasi quos? Assumenda dolorem ducimus earum eum facere sed sunt unde ut vitae. A alias aperiam architecto, culpa dolore earum excepturi harum inventore ipsam neque non nostrum officiis omnis optio placeat quis, quo repudiandae sequi veritatis vitae voluptas.",
    },
  },
  {
    type: "TOOLTIP_BUILDING_SCOPE",
    data: {
      title:       "Zakres budowy",
      description: "Wybierz prace jakie wchodzą w zakres budowy",
    },
  },
];

