import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Article from '../../api/entities/Article';
import useHtmlParser from '../../hooks/useHtmlParser';
import useMomentConverter from '../../hooks/useMomentConverter';
import CustomLink from '../custom-link/CustomLink';
import Date from '../typography/Date';
import Header from '../typography/Header';
import Paragraph from '../typography/Paragraph';


const ListItem = styled(Link)`
    display: block;
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: 5px;
    overflow: hidden;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 24px;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_big}) {
        width: calc(50% - 12px);
        &:not(:nth-child(2n)) {
            margin-right: 24px;
        }
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop_hd}) {
        width: calc(33% - 18px);
        &:not(:nth-child(2n)) {
            margin-right: 0;
        }

        &:not(:nth-child(3n)) {
            margin-right: 24px;
        }
    }
`;

const StyledHeader = styled(Header)`
    margin-bottom: 8px;
`;


const ImageBox = styled.div`
    width: 100%;
    height: 126px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_small}) {
        height: 160px;
    }
`;

const ListItemWrapper = styled.div`
    padding: 12px 24px 24px 24px;
`;

interface Props {
  route: string;
  data: Article;
}

const ArticleCard: FunctionComponent<Props> = ({ route, data }) => {

  return (
    <ListItem to={route} state={{ subpage: true }}>
      {
        data.photos && data.photos?.length > 0 && (
          <ImageBox>
            <picture>
              <source srcSet={data.photos[0].asset_path} type="image/webp"/>
              <img src={data.photos[0].asset_path} alt=""/>
            </picture>
          </ImageBox>
        )
      }
      <ListItemWrapper>
        {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
        <Date> {useMomentConverter(data.created_at)}</Date>
        <StyledHeader>{data.name}</StyledHeader>
        <Paragraph>
          {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
          {useHtmlParser(data.short_description)}
        </Paragraph>
        <CustomLink>Zobacz</CustomLink>
      </ListItemWrapper>
    </ListItem>
  );
};

export default ArticleCard;
