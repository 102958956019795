import {createAsyncThunk} from "@reduxjs/toolkit";
import {PayloadAction}    from "@reduxjs/toolkit";
import {createSlice}      from "@reduxjs/toolkit";
import Api                from "../api/Api";
import Thread             from "../api/entities/Thread";


interface ThreadState {
  modalCreate: boolean;
  name: string;
  threadsUnread: number | null;
  current_page: number;
  next_page: number;
  last_page: number | undefined;
  data: Thread[] | undefined;
  total: number;
  parameters?: string | null;
}

const initialState: ThreadState = {
  modalCreate:   false,
  name:          "",
  threadsUnread: null,
  current_page:  0,
  next_page:     0,
  last_page:     0,
  total:         0,
  data:          [],
  parameters:    "",
};

const client = new Api();


export const fetchThreadInfinite = createAsyncThunk("thread/infinite", async (page: number, {getState}) => {
  const state: any = getState();

  const params = () => {
    if (state.thread.parameters) {
      return {...state.thread.parameters, page: page};
    }

    return {page: page};
  };

  const response = await client.Thread.List(params());

  return {
    data:         [...state.thread.data, ...response.data.data],
    current_page: response.data.current_page,
    next_page:    response.data.current_page + 1,
  };

});

export const fetchThreadUnread = createAsyncThunk("thread-unread", async () => {
  const client   = new Api();
  const response = await client.Thread.Unread();

  return response.data;
});

export const fetchThreadSearch = createAsyncThunk("thread/search", async (parameters: any) => {
  const response = await client.Thread.Search(parameters);

  return {...response.data, parameters};
});

export const fetchThread = createAsyncThunk("thread", async () => {
  const client   = new Api();
  const response = await client.Thread.List();

  return response.data;

});

export const threadSlice = createSlice({
  name:          "thread",
  initialState,
  reducers:      {
    set:       (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    unSet:     state => {
      state.name = "";
    },
    showModal: (state) => {
      state.modalCreate = true;
    },
    hideModal: (state) => {
      state.modalCreate = false;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(fetchThread.fulfilled, (state: ThreadState, action: PayloadAction<any>) => {
      state.data         = action.payload.data;
      state.last_page    = action.payload.last_page;
      state.current_page = action.payload.current_page;
      state.next_page    = action.payload.current_page + 1;
      state.total        = action.payload.total;
      state.parameters   = null;
    });
    builder.addCase(fetchThreadSearch.fulfilled, (state: ThreadState, action: PayloadAction<any>) => {
      state.data       = action.payload.data;
      state.last_page  = action.payload.last_page;
      state.next_page  = action.payload.current_page + 1;
      state.parameters = action.payload.parameters;
    });
    builder.addCase(fetchThreadInfinite.fulfilled, (state: ThreadState, action: PayloadAction<any>) => {
      state.data         = action.payload.data;
      state.current_page = action.payload.current_page;
      state.next_page    = action.payload.current_page + 1;
    });
    builder.addCase(fetchThreadUnread.fulfilled, (state: ThreadState, action: PayloadAction<number>) => {
      state.threadsUnread = action.payload;
    });
  },
});

export const {set, unSet, showModal, hideModal} = threadSlice.actions;

export default threadSlice.reducer;
