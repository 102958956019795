import { useRef } from "react";
import { FunctionComponent } from "react";
import { useState } from "react";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";
import CloseIcon from "../../assets/icons/close.svg";
import MessageCard from "../../components/message/MessageCard";
import MessageForm from "../../components/message/MessageForm";
import MessageHeader from "../../components/message/MessageHeader";
import Paragraph from "../../components/typography/Paragraph";
import Wrapper from "../../components/wrapper/Wrapper";
import { useAppSelector } from "../../hooks/useAppSelector";
import { ROUTE_THREAD } from "../../routing/routes";
import { fetchMessageInfinite } from "../../store/MessageReducer";
import { fetachMessage } from "../../store/MessageReducer";
import LoadingData from "../../components/loading-data/LoadingData";

const StyledWrapper = styled(Wrapper)<{ form?: number }>`
  width: 100%;
  height: ${(props) =>
    props.form
      ? "200px"
      : `calc(100vh - ${props.theme.layout_part_size.mobile.message_form} - ${props.theme.layout_part_size.mobile.navbar} - ${props.theme.layout_part_size.mobile.page_header})`};
  overflow: auto;
  background-color: transparent;
  box-shadow: none;
  margin: 0 !important;
  padding: 0 !important;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 60vh;
    min-height: 400px;
  }
`;

const StyledParagraph = styled(Paragraph)`
  width: 100%;
  text-align: center;
  margin-top: 18px;
`;

const FixedModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  max-width: 100vw;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: #000;
  padding: 48px 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const CloseModal = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 2;
  width: 18px;
  height: 18px;
  background-image: url(${CloseIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;
  background-size: contain;
`;

const StyledInfoEmptyContent = styled.div`
  font-size: 12px;
  text-align: center;
  padding: 36px 0;
`;

const MessageWrapperBox = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  padding: 24px 24px 12px 24px;
`;

const Loader = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100px;
`;

interface Props {
  id?: string;
  createForm?: number;
  handleClose?: () => void;
}

const ThreadForm: FunctionComponent<Props> = ({ id, createForm, handleClose }) => {
  const dispatch = useDispatch();
  const [activeModal, setImageModal] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string>();
  const threadName = useAppSelector((state) => state.thread.name);
  const message = useAppSelector((state) => state.message);
  const navigate = useNavigate();
  const messageWrapperBoxRef = useRef<any>();
  const lastMessage = useRef<HTMLDivElement>(null);
  const [loader, setLoader] = useState<boolean>(true);

  const updateMessageWrapperBoxHeight = () => {
    let scrollTo = 0;

    if (lastMessage.current) {
      scrollTo = lastMessage.current?.offsetHeight;
    }

    if (messageWrapperBoxRef.current.scrollHeight === 0) {
      scrollTo = 0;
    }

    messageWrapperBoxRef.current.scrollTo(0, -scrollTo);
  };

  useEffect(() => {
    setTimeout(() => {
      updateMessageWrapperBoxHeight();
      setLoader(false);
    }, 1000);
  }, []);

  const setDataHandler = () => {
    if (id && id !== "0") {
      dispatch(fetachMessage(id));
      updateMessageWrapperBoxHeight();
    }
  };

  const swipeSubpageHandlers = useSwipeable({
    onSwipedRight: () => {
      if (window.innerWidth < 860) {
        navigate(ROUTE_THREAD);
      }
    },
    delta: 60,
  });

  // check for new messages
  useEffect(() => {
    let isMount = true;
    setInterval(() => {
      if (isMount) {
        setDataHandler();
      }
    }, 10000);

    setDataHandler();

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // modal with gallery detail - open
  const showImageDetail = (file: string) => {
    setImageSrc(file);
    setImageModal(true);
  };

  // modal with gallery detail - close
  const handleCloseModal = () => {
    setImageSrc(undefined);
    setImageModal(false);
  };

  return (
    <>
      <MessageHeader createForm={createForm}>
        {message.thead_title ? message.thead_title : threadName}
      </MessageHeader>
      <StyledWrapper form={createForm} {...swipeSubpageHandlers}>
        {createForm ? (
          <StyledInfoEmptyContent>
            Witaj w nowym wątku,
            <br /> napisz wiadomość do administratora serwisu!
          </StyledInfoEmptyContent>
        ) : (
          <>
            {message.data && (
              <MessageWrapperBox id="messenger-area" ref={messageWrapperBoxRef}>
                {loader && (
                  <Loader id="loader">
                    <LoadingData />
                  </Loader>
                )}
                <InfiniteScroll
                  dataLength={message.data.length}
                  next={() => dispatch(fetchMessageInfinite(message.next_page))}
                  hasMore={true}
                  loader={null}
                  inverse={true}
                  scrollableTarget="messenger-area"
                  style={{ opacity: loader ? 0 : 1 }}>
                  {message.data &&
                    message.data.map((data, i) => {
                      if (message.data) {
                        if (i === message.data.length - 1) {
                          return (
                            <div ref={lastMessage} key={i}>
                              <MessageCard
                                thread={id || "0"}
                                data={data}
                                key={i}
                                showImageDetail={showImageDetail}
                              />
                            </div>
                          );
                        }
                        return (
                          <MessageCard
                            thread={id || "0"}
                            data={data}
                            key={i}
                            showImageDetail={showImageDetail}
                          />
                        );
                      }
                    })}
                  {message && !message.is_active && (
                    <StyledParagraph>Wątek zamknięty</StyledParagraph>
                  )}
                </InfiniteScroll>
              </MessageWrapperBox>
            )}
          </>
        )}
      </StyledWrapper>
      <>
        {id && message && (
          <MessageForm
            handleClose={handleClose}
            createForm={createForm}
            threadId={id}
            setDataHandler={setDataHandler}
          />
        )}
      </>
      {activeModal && (
        <FixedModal>
          <CloseModal onClick={handleCloseModal} />
          <img src={imageSrc} alt="" />
        </FixedModal>
      )}
    </>
  );
};

export default ThreadForm;
