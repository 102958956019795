import ArticleApi from './modules/ArticleApi';
import BuildingApi from './modules/BuildingApi';
import NotificationApi from './modules/NotificationApi';
import PointApi from './modules/PointModule';
import ProfileAPi from './modules/ProfileApi';
import PromotionApi from './modules/PromotionApi';
import RewardApi from './modules/RewardApi';
import ScopeApi from './modules/ScopeApi';
import SignInApi from './modules/SignInApi';
import SignOutApi from './modules/SignOutApi';
import SignUpApi from './modules/SignUpApi';
import ThreadApi from './modules/ThreadApi';
import UserApi from './modules/UserApi';
import UserSourceApi from './modules/UserSourceApi';
import WorkScopeApi from './modules/WorkScopeApi';
import HistoryApi from './modules/HistoryModule';
import axios from "axios";

// TODO: fix this better #DEV-203
axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error && error.response && error.response.status === 404) {
        //window.location.href = '/not-found';
      } else if (error && error.response && (error.response.status === 403 || error.response.status === 401)) {
        localStorage.clear();
        window.location.href = '/';
      }
    }
);

export default class Api {

  get Article() {
    return new ArticleApi();
  }

  get Building() {
    return new BuildingApi();
  }

  get History() {
    return new HistoryApi();
  }

  get Notification() {
    return new NotificationApi();
  }

  get Point() {
    return new PointApi();
  }

  get Profile() {
    return new ProfileAPi();
  }

  get Promotion() {
    return new PromotionApi();
  }

  get Reward() {
    return new RewardApi();
  }

  get Scope() {
    return new ScopeApi();
  }

  get SignIn() {
    return new SignInApi();
  }

  get SignUp() {
    return new SignUpApi();
  }

  get SignOut() {
    return new SignOutApi();
  }

  get Thread() {
    return new ThreadApi();
  }

  get User() {
    return new UserApi();
  }

  get UserSource() {
    return new UserSourceApi();
  }

  get WorkScope() {
    return new WorkScopeApi();
  }
}
