import axios             from "axios";
import Scope             from "../entities/Scope";
import Entrypoint        from "../Entrypoint";
import {ResponsePromise} from "../ResponsePromise";


class ScopeApi {
  Get(): ResponsePromise<Scope[]> {
    return axios.get(Entrypoint.Api.Scope.Index);
  }
}

export default ScopeApi;
