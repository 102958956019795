import React                    from "react";
import {useNavigate}            from "react-router";
import styled                   from "styled-components";
import AddBuildingImage         from "../../assets/images/add_building.png";
import {useAppDispatch}         from "../../hooks/useAppDispatch";
import {useAppSelector}         from "../../hooks/useAppSelector";
import {ROUTE_HOME}             from "../../routing/routes";
import {showBuildingCreateInfo} from "../../store/BuildingReducer";
import {fetchBuilding}          from "../../store/BuildingReducer";
import {setSubpage}             from "../../store/SubpageReducer";
import ButtonPrimaryBack        from "../buttons/ButtonPrimaryBack";
import Header                   from "../typography/Header";
import {defaultInfoHeader}      from "./defaultStyles";
import {defaultInfoContainer}   from "./defaultStyles";
import {defaultInfoWrapper}     from "./defaultStyles";


const InfoContainer = styled.div`
    ${defaultInfoContainer};
`;

const InfoWrapper = styled.div`
    ${defaultInfoWrapper};
    padding-top: 48px;
    padding-bottom: 48px;

    img {
        width: 180px;
        height: 180px;
    }
`;

const StyledHeaderInfo = styled(Header)<{ center?: boolean }>`
    ${defaultInfoHeader};
    margin-bottom: 24px;
`;

const DangerInfo = styled.div`
    font-style: italic;
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: 24px;
    padding: 18px 0;
    border-top: 1px solid ${({theme}) => theme.colors.border};
    border-bottom: 1px solid ${({theme}) => theme.colors.border};
`;

const InfoBuildingCreated = () => {
  const duplicateInfo = useAppSelector(state => state.building.buildingDuplicateInfo);
  const dispatch      = useAppDispatch();
  const navigate      = useNavigate();

  const handleClick = () => {
    dispatch(showBuildingCreateInfo(false));
    dispatch(fetchBuilding());
    dispatch(setSubpage(false));
    navigate(ROUTE_HOME);
  };

  return (
    <InfoContainer>
      <InfoWrapper>
        <img src={AddBuildingImage} alt=""/>
        <StyledHeaderInfo>Budowa została dodana</StyledHeaderInfo>
        {
          duplicateInfo && (
            <DangerInfo>
              UWAGA! Zgłoszona budowa znajduje się już w naszym systemie, jednak nie wszystko stracone! Przekażemy Twoje zgłoszenie do ręcznej akceptacji.
            </DangerInfo>
          )
        }
        <ButtonPrimaryBack type="button" onClick={handleClick}>Powrót do listy</ButtonPrimaryBack>
      </InfoWrapper>
    </InfoContainer>
  );
};

export default InfoBuildingCreated;
