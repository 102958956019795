const bearer = `Bearer ${localStorage.getItem("access_token")}`;

function post(path: any, body: any) {
  return fetch("https://portal.bruczek.pl/api/push/subscription", {
    headers: {
      "Accept":           "application/json",
      "content-type":     "application/json;charset=UTF-8",
      "X-Requested-With": "XMLHttpRequest",
      "Authorization":    bearer,
      "sec-fetch-mode":   "cors",
    },
    body:    JSON.stringify(body),
    method:  "POST",
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      return data;
    });
}

function destroy(body: any) {
  return fetch("https://portal.bruczek.pl/api/push/subscription", {
    headers: {
      "Accept":           "application/json",
      "content-type":     "application/json;charset=UTF-8",
      "X-Requested-With": "XMLHttpRequest",
      "Authorization":    bearer,
      "sec-fetch-mode":   "cors",
    },
    body:    JSON.stringify(body),
    method:  "DELETE",
  });
}

const http = {
  post:    post,
  destroy: destroy,
};

export default http;
