import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice}   from "@reduxjs/toolkit";
import {DataTooltips}  from "../data/DataTooltips";


interface TooltipState {
  active: false;
  title: string;
  description: string;
}

interface TooltipData {
  type: string;
  data: {
    title: string;
    description: string;
  };
}

export const initialState: TooltipState = {
  active:      false,
  title:       "",
  description: "",
};

export const tooltipSlice = createSlice({
  name:     "tooltip",
  initialState,
  reducers: {
    showTooltip: (state: any, action: PayloadAction<string>) => {
      const currentTooltip: any = DataTooltips.find((data: TooltipData) => data.type === action.payload);

      state.active      = true;
      state.title       = currentTooltip.data.title;
      state.description = currentTooltip.data.description;
    },

    hideTooltip: (state: TooltipState) => {
      state.active      = false;
      state.title       = "";
      state.description = "";
    },
  },
});

export const {showTooltip, hideTooltip} = tooltipSlice.actions;

export default tooltipSlice.reducer;
