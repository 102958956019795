import React                from "react";
import styled               from "styled-components";
import {useAppSelector}     from "../../hooks/useAppSelector";
import UserAvatarDeleteForm from "./UserAvatarDeleteForm";
import UserAvatarInput      from "./UserAvatarInput";


const InputWrapperContainer = styled.div`
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid ${({theme}) => theme.colors.border};

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_big}) {
        max-width: 600px;
    }
`;

const InputWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    padding-top: 10px;
    margin-top: -10px;

    img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
    }
`;

const UserAvatarBox = styled.span`
    margin-left: auto;
    margin-right: auto;
    position: relative;
`;

const UserInitials = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: ${({theme}) => theme.colors.primary};
    box-shadow: 0 0 8px 2px rgb(42 183 248 / 45%);
    color: ${({theme}) => theme.colors.white};
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const UserImageWrapper = styled.div`
    width: 80px;
    height: 80px;
    box-shadow: 0 0 8px 2px rgb(42 183 248 / 45%);
    border-radius: 50%;
`;
const UserImage        = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
`;

const UserAvatarDeleteFormWrapper = styled.div`
    position: absolute;
    top: 4px;
    right: -6px;
    z-index: 2;
`;


const UserAvatarForm = () => {
  const user = useAppSelector(state => state.newUser.user);

  return (
    <>
      {user && (
        <InputWrapperContainer>
          <InputWrapper>
            <UserAvatarBox>
              {
                user.photos.length > 0 ? (
                  <>
                    <UserImageWrapper>
                      <UserImage src={user.photos[0].asset_path}/>
                    </UserImageWrapper>
                    <UserAvatarDeleteFormWrapper>
                      <UserAvatarDeleteForm/>
                    </UserAvatarDeleteFormWrapper>
                  </>
                ) : (
                  <>
                    <UserInitials>{user.initials}</UserInitials>
                  </>
                )
              }
            </UserAvatarBox>
            <UserAvatarInput isAvatar={user.photos.length > 0}/>
          </InputWrapper>
        </InputWrapperContainer>
      )}
    </>
  );
};

export default UserAvatarForm;
