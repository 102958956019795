import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useAppSelector';
import { ROUTE_ACCOUNT_ACCEPTANCE, ROUTE_ACCESS_DENIED, ROUTE_REGISTER_NOT_COMPLETED, ROUTE_VERIFY_EMAIL } from '../../routing/routes';
import LoadingData from '../loading-data/LoadingData';


const AuthVerification = () => {
  const user = useAppSelector(state => state.newUser.user);

  const renderView = () => {
    if (user) {
      if (!user.email_verified_at) {
        return <Navigate to={ROUTE_VERIFY_EMAIL}/>;
      }
      if (!user.filled_at) {
        return <Navigate to={ROUTE_REGISTER_NOT_COMPLETED}/>;
      }
      if (!user.activated_at) {
        return <Navigate to={ROUTE_ACCOUNT_ACCEPTANCE}/>;
      }
      if (user.is_banned) {
        return <Navigate to={ROUTE_ACCESS_DENIED}/>;
      }

      return (
        <Outlet/>
      );
    } else {
      return <LoadingData/>;
    }
  };

  return (
    <>
      {renderView()}
    </>
  );

};

export default AuthVerification;
