import styled from "styled-components";


const Wrapper = styled.div`
    padding: 24px;
    max-width: ${({theme}) => theme.layout_part_size.mobile.wrapper};
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        margin-top: 36px;
        margin-bottom: 24px;
        padding: 36px 24px 48px 24px;
        border-radius: 7px;
    }
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        padding: 24px 36px 48px 36px;
        box-shadow: 0 0 13px -6px rgba(66, 68, 90, 0.4);
        max-width: 1920px;
    }
`;

export default Wrapper;
