import React from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import ConfirmImage from '../../assets/images/confirm.png';
import ButtonPrimary from '../../components/buttons/ButtonPrimary';
import NavbarAuth from '../../components/navbar/NavbarAuth';
import Header from '../../components/typography/Header';
import Paragraph from '../../components/typography/Paragraph';
import WrapperInfo from '../../components/wrapper/WrapperInfo';
import { useAppSelector } from '../../hooks/useAppSelector';
import { ROUTE_HOME } from '../../routing/routes';


const StyledHeader = styled(Header)`
    margin: 12px 0 24px 0;
    font-size: ${({ theme }) => theme.fonts.mobile.xl};
`;

const StyledParagraph = styled(Paragraph)`
    text-align: center;
`;

const VerifyEmailPage = () => {
  const user = useAppSelector(state => state.newUser.user);

  if (user && user.email_verified_at) {
    return <Navigate to={ROUTE_HOME}/>;
  }

  return (
    <>
      <NavbarAuth/>
      <WrapperInfo>
        <img src={ConfirmImage} alt=""/>
        <StyledHeader>Dziękujemy!</StyledHeader>
        <StyledParagraph>Wysłaliśmy link aktywacyjny na Twoją skrzynkę pocztową</StyledParagraph>
        <StyledParagraph>Zweryfikuj swoje konto.</StyledParagraph>
        <br/>
        <ButtonPrimary onClick={() => window.location.reload()}>Odśwież aplikację</ButtonPrimary>
      </WrapperInfo>
    </>
  );
};

export default VerifyEmailPage;
