import {FunctionComponent}  from "react";
import {useState}           from "react";
import {useEffect}          from "react";
import React                from "react";
import styled               from "styled-components";
import ButtonPrimary        from "../../components/buttons/ButtonPrimary";
import ButtonSecondaryClose from "../../components/buttons/ButtonSecondaryClose";
import Input                from "../../form/internals/Input";
import Label                from "../../form/internals/Label";
import {useAppDispatch}     from "../../hooks/useAppDispatch";
import {unSet}              from "../../store/ThreadReducer";
import {set}                from "../../store/ThreadReducer";
import ThreadForm           from "./ThreadForm";


const ButtonWrapper = styled.div`
    padding-top: 24px;
    display: flex;
    justify-content: center;

    & > * {
        margin: 0 4px;
    }
`;

interface Props {
  handleClose: () => void;
}

const ThreadCreate: FunctionComponent<Props> = ({handleClose}) => {
  const [name, setName]         = useState<string>("");
  const dispatch                = useAppDispatch();
  const [showForm, setShowForm] = useState<boolean>(false);

  const handleThreadSubmit = () => {
    setShowForm(true);
    dispatch(set(name));
  };

  useEffect(() => {
    dispatch(unSet());
  }, [dispatch]);

  return (
    <>
      {!showForm ? (
        <div>
          <Label required>Podaj tytuł wątku</Label>
          <Input name="title" as="input" onChange={(event: any) => setName(event.target.value)}/>
          <ButtonWrapper>
            <ButtonPrimary type="button" disabled={name === ""} onClick={handleThreadSubmit}>Rozpocznij wątek</ButtonPrimary>
            <ButtonSecondaryClose type="button" onClick={() => handleClose()}>Anuluj</ButtonSecondaryClose>
          </ButtonWrapper>
        </div>
      ) : (
        <ThreadForm id="0" createForm={1} handleClose={handleClose}/>
      )}
    </>
  );
};

export default ThreadCreate;
