import {useState}                  from "react";
import {useRef}                    from "react";
import {useEffect}                 from "react";
import React                       from "react";
import {CSSTransition}             from "react-transition-group";
import styled                      from "styled-components";
import usePushNotifications        from "../../hooks/usePushNotifications";
import ButtonPrimaryForward        from "../buttons/ButtonPrimaryForward";
import ButtonSecondaryClose        from "../buttons/ButtonSecondaryClose";
import Header                      from "../typography/Header";
import Paragraph                   from "../typography/Paragraph";
import {defaultModalWrapperStyles} from "./defaultModalStyles";
import {defaultModalStyles}        from "./defaultModalStyles";


const Container = styled.div`
    ${defaultModalStyles};
    z-index: 99999998;
    opacity: 1;
    visibility: visible;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        align-items: center;
    }
`;

const Wrapper = styled.div`
    ${defaultModalWrapperStyles}
`;

const ModalButtonsWrapper = styled.div`
    display: flex;
    padding-top: 18px;
    width: 100%;
    justify-content: center;

    button {
        width: 140px;
        margin: 0 4px;
    }
`;

const StyledParagraph = styled(Paragraph)`
    width: 100%;
    text-align: center;
    line-height: 1.6;
`;

const StyledHeader = styled(Header)`
    width: 100%;
    margin: 12px 0 18px 0;
    font-size: ${props => props.theme.fonts.mobile.lg};
    font-weight: ${props => props.theme.weights.medium};
    text-align: center;
`;


const ModalNotificationsPermission = () => {
  const ref                       = useRef<any>();
  const refWrapper                = useRef<any>();
  const [showModal, setShowModal] = useState<boolean>(false);

  const {
          checkUserSubscription,
          onClickSubscribeToPushNotification,
        } = usePushNotifications();


  useEffect(() => {
    if ("Notification" in window) {
      if (Notification.permission !== "denied") {
        checkUserSubscription().then(response => {
          if (response) {
            setShowModal(false);
          } else {
            setShowModal(true);
          }
        });
      }
    }
  }, []);


  const saveSubscription = () => {
    onClickSubscribeToPushNotification();
    setShowModal(false);
  };

  const handleAcceptNotification = () => {

    if ("Notification" in window) {
      if (Notification.permission === "default") {
        Notification.requestPermission().then(permission => {
          if (permission === "granted") {
            saveSubscription();
          } else {
            setShowModal(false);
          }
        });
      } else if (Notification.permission === "granted") {
        saveSubscription();
        setShowModal(false);
      } else {
        setShowModal(false);
      }
    } else {
      console.log("Notification is not in window, message from modal");
    }
  };

  const handleDismissNotification = () => {
    setShowModal(false);
  };

  return (
    <CSSTransition
      in={showModal}
      timeout={200}
      classNames="modal-fade"
      unmountOnExit
    >
      <Container ref={ref}>
        <Wrapper className="modal-wrapper" ref={refWrapper}>
          <StyledHeader>
            Bruczek chce wysyłać Ci powiadomienia!
          </StyledHeader>
          <StyledParagraph>
            Pozwól Bruczkowi na wysyłanie Ci powiadomień z najważniejszymi informacjami.
          </StyledParagraph>
          <ModalButtonsWrapper>
            <ButtonPrimaryForward onClick={() => handleAcceptNotification()}>Zezwól</ButtonPrimaryForward>
            <ButtonSecondaryClose onClick={() => handleDismissNotification()}>Później</ButtonSecondaryClose>
          </ModalButtonsWrapper>
        </Wrapper>
      </Container>
    </CSSTransition>
  );
};

export default ModalNotificationsPermission;
