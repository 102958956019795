import React, { useEffect, useRef, FunctionComponent } from 'react';
import { matchRoutes, useNavigate } from 'react-router';
import { useLocation, Outlet } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import { defaultModalStyles } from '../components/modal/defaultModalStyles';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import useClickOutside from '../hooks/useClickOutside';
import { ROUTE_THREAD, ROUTE_THREAD_SHOW } from '../routing/routes';
import { saveRoute } from '../store/ParentRoute';


const SubpageContainer = styled.div`
    width: 100vw;
    height: calc(100vh - ${({ theme }) => theme.layout_part_size.mobile.navbar});
    background-color: #fff;
    position: fixed;
    top: ${({ theme }) => theme.layout_part_size.mobile.navbar};
    left: 100%;
    transition: .3s all ease;
    overflow: auto;
    z-index: 10;

    &.active {
        transform: translate3d(-100%, 0, 0);
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        ${defaultModalStyles};
        display: none;
        padding-left: 24px;
        padding-right: 24px;
        width: 100%;
        z-index: 1000000;
        height: 100%;
        opacity: 1;
        visibility: visible;
        padding-top: 64px;
        overflow: auto;
        &.active {
            transform: none;
            display: block;
        }
    }
`;

const SubpageWrapper = styled.div`
    max-width: 600px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
`;


const Subpage: FunctionComponent<{ parentRoute: string; }> = ({ parentRoute }) => {
  const navigate = useNavigate();
  const subpage = useAppSelector(state => state.subpage.status);
  const subpageRef = useRef<any>();
  const refWrapper = useRef<any>();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const threadMatch = matchRoutes([{ path: ROUTE_THREAD_SHOW }], location.pathname);
  let subpageLocationState = location.state as { subpage: boolean | undefined };


  useEffect(() => {
    if (subpageLocationState) {
      dispatch(saveRoute(parentRoute));
    } else {
      dispatch(saveRoute(''));
    }
  }, [location]);


  const swipeSubpageHandlers = useSwipeable({
    onSwipedRight: () => {
      if (window.innerWidth < 860) {
        navigate(threadMatch ? ROUTE_THREAD : parentRoute);
      }
    },
    delta: 50,
  });

  const refPassThrough = (el: any) => {
    swipeSubpageHandlers.ref(el);
    subpageRef.current = el;
  };

  useClickOutside(refWrapper, () => {
    if (window.innerWidth > 860) {
      navigate(threadMatch ? ROUTE_THREAD : parentRoute);
    }
  });

  return (
    <SubpageContainer id="subpage-content" className={subpage ? 'active' : ''} ref={refPassThrough}>
      <SubpageWrapper>
        <Outlet/>
      </SubpageWrapper>
    </SubpageContainer>
  );
};

export default Subpage;
