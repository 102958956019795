import axios             from "axios";
import {generatePath}    from "react-router";
import Article           from "../entities/Article";
import Entrypoint        from "../Entrypoint";
import {ResponsePromise} from "../ResponsePromise";
import AbstractModule    from "./AbstractModule";


class PromotionApi extends AbstractModule {

  List(page: any): ResponsePromise<{ last_page: number; data: Article[] }> {
    return axios.get(Entrypoint.Api.Promotion.Index, {
      params: {
        ...page,
      },
    });
  }

  Get(promotion: string) {
    return axios.get(generatePath(Entrypoint.Api.Promotion.Show, {promotion}));
  }
}

export default PromotionApi;
