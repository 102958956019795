import { clearAllBodyScrollLocks, enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import React, { useEffect, FunctionComponent } from 'react';
import { matchRoutes, useLocation } from 'react-router';
import { Outlet } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import ActionButton from '../components/action-button/ActionButton';
import Menu from '../components/menu/Menu';
import MenuBox from '../components/menu/MenuBox';
import ModalNotificationsPermission from '../components/modal/ModalNotificationsPermission';
import ModalTooltip from '../components/modal/ModalTooltip';
import ModalWithAppError from '../components/modal/ModalWithAppError';
import Navbar from '../components/navbar/Navbar';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import { ROUTE_THREAD_SHOW } from '../routing/routes';
import { showMenu } from '../store/MenuReducer';
import { fetchNotificationTotal } from '../store/NotificationReducer';
import { hideSubpage } from '../store/SubpageReducer';
import { fetchThreadUnread } from '../store/ThreadReducer';
import ModalHistory from '../components/modal/ModalHistory';
import { showHistoryModal } from '../store/ModalHistoryReducer';
import HistoryBlue from '../assets/icons/hisotry-blue.svg';
import { fetchUser } from '../store/UserReducerNew';


const LayoutWrapper = styled.div`
    position: relative;
    overflow: hidden;

    &.mask {
        max-width: 100vw;
        width: 100%;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(4px);
            z-index: 120;
        }
    }
`;

const Container = styled.div<{ threadMatch: boolean }>`
    width: 100vw;
    min-height: 100vh;
    padding-top: ${({ theme }) => theme.layout_part_size.mobile.navbar};
    padding-bottom: ${props => props.threadMatch ? '0' : props.theme.layout_part_size.mobile.menu_bottom};
    transition: .3s transform ease;
    overflow: hidden;

    &.active {
        transform: translate3d(${({ theme }) => theme.layout_part_size.mobile.menu}, 0, 0);
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_small}) {
        background-color: #f5f5f5;
        &.active {
            transform: translateX(${({ theme }) => theme.layout_part_size.tablet_small.menu});
        }
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        transform: none !important;
        background-color: #F0F0F0;
        width: calc(100% - ${({ theme }) => theme.layout_part_size.tablet.menu});
        margin-left: ${({ theme }) => theme.layout_part_size.tablet.menu};
        padding: ${({ theme }) => theme.layout_part_size.tablet.navbar} 36px ${({ theme }) => theme.layout_part_size.tablet.menu_bottom} 36px;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop_hd}) {
        width: calc(100% - ${({ theme }) => theme.layout_part_size.laptop_hd.menu});
        margin-left: ${({ theme }) => theme.layout_part_size.laptop_hd.menu};
    }
`;

const HistoryButton = styled.div`
    width: 24px;
    height: 24px;
    position: fixed;
    top: 18px;
    right: 24px;
    z-index: 1000000;
    display: none;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        display: block;
    }
`;

const Layout: FunctionComponent = () => {
  const status = useAppSelector(state => state.menu.status);
  const statusSubpage = useAppSelector(state => state.subpage.status);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.newUser.user);
  const threadMatch = matchRoutes([{ path: ROUTE_THREAD_SHOW }], location.pathname);
  const modalErrorApp = useAppSelector(state => state.modalErrorApp.status);
  const target = document.querySelector('#subpage-content');
  let subpageLocationState = location.state as { subpage: boolean | undefined };

  useEffect(() => {
    if (target) {
      if (subpageLocationState && !threadMatch) {
        disableBodyScroll(target);
      } else {
        enableBodyScroll(target);
        clearAllBodyScrollLocks();
      }
    }

    dispatch(fetchUser());

  }, [location]);

  useEffect(() => {
    if (!subpageLocationState) {
      dispatch(hideSubpage());
    }
  }, [location]);

  useEffect(() => {
    const dispatcher = () => {
      if (localStorage.getItem('access_token') && user?.activated_at) {
        dispatch(fetchNotificationTotal());
        dispatch(fetchThreadUnread());
      }
    };

    dispatcher();

    if ('Notification' in window) {
      const broadcast = new BroadcastChannel('notifications-chanel');
      broadcast.onmessage = (event) => {
        if (event.data && event.data.type === 'PUSH_NOTIFICATION') {
          dispatcher();
        }
      };
    }

    const interval = setInterval(dispatcher, 10000);

    return () => {
      clearInterval(interval);
    };

  }, [user]);


  const swipeHandlers = useSwipeable({
    onSwipedRight: () => {
      if (window.innerWidth < 860) {
        dispatch(showMenu());
      }
    },
    //@ts-ignore
    preventScrollOnSwipe: true,
    delta: 50,
    trackTouch: !statusSubpage,
    trackMouse: true,
  });

  return (
    <LayoutWrapper className={status ? 'mask' : ''}>
      <Navbar/>
      <Container {...swipeHandlers} className={status ? 'active' : ''} threadMatch={!!threadMatch}>
        <Outlet/>
      </Container>
      {
        !threadMatch && <Menu/>
      }
      <MenuBox/>

      <ActionButton/>

      <HistoryButton onClick={() => dispatch(showHistoryModal())}>
        <img src={HistoryBlue} alt=""/>
      </HistoryButton>

      {/* modal with tooltip description */}
      <ModalTooltip/>

      {/* modal with user decision about push notifications */}
      <ModalNotificationsPermission/>

      {/*modal with points history*/}
      <ModalHistory/>

      {/* modal with error from requests */}
      {modalErrorApp && <ModalWithAppError/>}
    </LayoutWrapper>
  );
};

export default Layout;
