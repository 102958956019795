import {css}                from "styled-components";
import {defaultModalStyles} from "../modal/defaultModalStyles";


export const defaultInfoContainer = css`
    ${defaultModalStyles};
    opacity: 1;
    visibility: visible;
    align-items: center;
    padding: 24px;
`;

export const defaultInfoWrapper = css`
    background-color: ${props => props.theme.colors.white};
    padding: 36px 24px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
        display: block;
    }
`;

export const defaultInfoHeader = css`
    width: 100%;
    text-align: center;
    margin: 0 0 24px 0;
    font-size: ${props => props.theme.fonts.mobile.lg};
    font-weight: ${props => props.theme.weights.medium};
`;
