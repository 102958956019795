import axios               from "axios";
import {generatePath}      from "react-router";
import Thread              from "../entities/Thread";
import ThreadMessage       from "../entities/ThreadMessage";
import ThreadUnread        from "../entities/ThreadUnread";
import Entrypoint          from "../Entrypoint";
import ThreadCreateRequest from "../request/thread/ThreadCreateRequest";
import {ResponsePromise}   from "../ResponsePromise";


class ThreadApi {
  List(params?: any): ResponsePromise<{ current_page: number, data: Thread[] }> {
    return axios.get(Entrypoint.Api.Thread.Index, {
      params: {
        ...params,
      },
    });
  }

  Search(value: any) {
    return axios.get(Entrypoint.Api.Thread.Index, {
      params: {
        ...value,
      },
    });
  }

  Get(thread: string, page?: any): ResponsePromise<{ thread: Thread, messages: { current_page: number, data: ThreadMessage[] } }> {
    return axios.get(generatePath(Entrypoint.Api.Thread.Show, {thread}), {params: {page: page}});
  }

  Store(thread: ThreadCreateRequest): ResponsePromise<{ data: Thread }> {
    return axios.post(generatePath(Entrypoint.Api.Thread.Store), thread);
  }

  StoreMessage(thread: string, threadMessage: FormData): ResponsePromise<{ data: ThreadMessage[] }> {
    return axios.post(generatePath(Entrypoint.Api.Thread.StoreMessage, {thread}), threadMessage, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  GetFile(thread: string, message: string, attachment: string): ResponsePromise<{ data: any }> {
    return axios.get(generatePath(Entrypoint.Api.Thread.GetFile, {thread, message, attachment}), {
      responseType: "blob",
    });
  }

  Unread(): ResponsePromise<{ data: ThreadUnread }> {
    return axios.get(Entrypoint.Api.Thread.Unread);
  }
}

export default ThreadApi;
