import {FunctionComponent} from "react";
import React               from "react";
import {useNavigate}       from "react-router";
import styled              from "styled-components";
import Notification        from "../../api/entities/Notification";
import TrashIcon           from "../../assets/icons/trash.svg";
import useMomentConverter  from "../../hooks/useMomentConverter";
import Paragraph           from "../typography/Paragraph";


const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 32px 12px 18px;
    position: relative;

    &:before {
        content: '';
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background-color: ${props => props.theme.colors.primary};
        z-index: 2;
    }
`;

const Container = styled.div<{ new: boolean }>`
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: 5px;
    background-color: ${props => props.new ? "#e9f7fe" : "transparent"};
    border-left-color: ${props => props.new ? props.theme.colors.primary : props.theme.colors.border};
    position: relative;

    ${Wrapper} {
        &:before {
            display: ${props => props.new ? "block" : "none"};
        }
    }

    &:not(:last-child) {
        margin-bottom: 12px;
    }
`;

const StyledParagraph = styled(Paragraph)`
    font-weight: ${props => props.theme.weights.medium};
    line-height: 1.6;
`;

const Remove = styled.div`
    width: 16px;
    height: 16px;
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 3;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
    }
`;

const Date = styled.div`
    margin-top: 2px;
    font-size: ${props => props.theme.fonts.mobile.xs};
    font-weight: ${props => props.theme.weights.light};
    display: block;
`;

interface Props {
  to?: string;
  notification: Notification;
  handleNotificationRemove: (id: string) => void;
  handleNotificationCheck: (id: string) => void;
  route?: string;
}

const NotificationsCard: FunctionComponent<Props> = ({route, notification, handleNotificationRemove, handleNotificationCheck}) => {
  const navigate = useNavigate();

  const handleClick = (route: string, id: string) => {
    handleNotificationCheck(id);
    navigate(route);
  };

  return (
    <>
      {
        route ? (
          <Container new={!notification.read_at}>
            <div onClick={() => handleClick(route, notification.id)}>
              <Wrapper>
                <StyledParagraph>
                  {notification.data.title}
                </StyledParagraph>
                <Date>
                  {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
                  {useMomentConverter(notification.created_at)}
                </Date>
              </Wrapper>
            </div>
            <Remove onClick={() => handleNotificationRemove(notification.id)}>
              <img src={TrashIcon} alt=""/>
            </Remove>
          </Container>
        ) : (
          <Container new={!notification.read_at}>
            <Wrapper>
              <StyledParagraph>
                {notification.data.title}
              </StyledParagraph>
              <Date>
                {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
                {useMomentConverter(notification.created_at)}
              </Date>
            </Wrapper>
            <Remove onClick={() => handleNotificationRemove(notification.id)}>
              <img src={TrashIcon} alt=""/>
            </Remove>
          </Container>
        )
      }
    </>
  );
};

export default NotificationsCard;
