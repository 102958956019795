import React                       from "react";
import {useNavigate}               from "react-router";
import styled                      from "styled-components";
import {useServiceWorker}          from "../../hooks/useServiceWorker";
import {ROUTE_HOME}                from "../../routing/routes";
import ButtonPrimary               from "../buttons/ButtonPrimary";
import Header                      from "../typography/Header";
import Paragraph                   from "../typography/Paragraph";
import {defaultModalWrapperStyles} from "./defaultModalStyles";
import {defaultModalStyles}        from "./defaultModalStyles";


const Container = styled.div`
    ${defaultModalStyles};
    opacity: 1;
    visibility: visible;
    padding: 24px;
    align-items: center;

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        align-items: center;
    }
`;

const Wrapper = styled.div`
    ${defaultModalWrapperStyles};
    text-align: center;
    border-radius: 5px;
    padding: 36px 24px;
    min-height: 0;

    &:before {
        display: none;
    }
`;

const StyledParagraph = styled(Paragraph)`
    line-height: 1.6;
`;

const TextWrapper = styled.div`
    margin: 6px auto 18px auto;
`;

const ModalWithAppError = () => {
  const {reloadPage} = useServiceWorker();
  const navigate     = useNavigate();

  const handeClick = () => {
    navigate(ROUTE_HOME);
    reloadPage();
  };

  return (
    <Container>
      <Wrapper className="modal-wrapper">
        <Header>
          Ups, coś poszło nie tak..
        </Header>
        <TextWrapper>
          <StyledParagraph>Wystąpił nieoczekiwany błąd, przeładuj aplikację w celu rozwiązania problemu</StyledParagraph>
        </TextWrapper>
        <ButtonPrimary onClick={handeClick}>Przeładuj</ButtonPrimary>
      </Wrapper>
    </Container>
  );
};

export default ModalWithAppError;
