import { useField } from "formik";
import { Field } from "formik";
import { FunctionComponent } from "react";
import React from "react";
import FormGroup from "../internals/FormGroup";
import Label from "../internals/Label";
import Select from "../internals/Select";
import InputError from "../validation/inputError";

interface Props {
  name: string;
  label?: string;
  required?: boolean;
  multiple?: boolean;
  placeholder?: string;
  options: {
    value: string;
    label: string;
  }[];
}

const InputSelect: FunctionComponent<Props> = (props) => {
  const [field, meta] = useField(props.name);

  return (
    <FormGroup className={meta.error && "validation-error"}>
      {props.label && <Label required={props.required}>{props.label}</Label>}
      <Field
        as={Select}
        name={props.name}
        multiple={props.multiple ?? false}
        className={props.multiple ? "select-multiple" : ""}>
        {!props.multiple && (
          <option value={[]} disabled selected>
            Wybierz z listy
          </option>
        )}
        {props.options.map((option, i) => (
          <option key={i} value={option.value}>
            {option.label}
          </option>
        ))}
      </Field>

      {meta.error && <InputError>{meta.error}</InputError>}
    </FormGroup>
  );
};

export default InputSelect;
