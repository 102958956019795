import React, {FunctionComponent} from 'react';
import {useMatch, useResolvedPath, Link} from 'react-router-dom';
import styled from 'styled-components';
import {useAppDispatch} from '../../hooks/useAppDispatch';
import {hideMenu} from '../../store/MenuReducer';


const MenuItemLi = styled.li`
    position: relative;
    padding-right: 24px;

    &:not(:last-child) {
        margin-bottom: 28px;
    }

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        &:not(:last-child) {
            margin-bottom: 18px;
        }
    }
`;

const MenuLink = styled(Link)`
    color: ${props => props.theme.colors.secondary};
    font-weight: ${props => props.theme.weights.medium};
    font-size: ${props => props.theme.fonts.mobile.lg};
    display: inline-flex;
    align-items: center;

    &.link-active {
        color: ${props => props.theme.colors.primary};
    }


    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: ${props => props.theme.fonts.mobile.md};
        transition: .3s color ease;
        &:hover {
            color: ${props => props.theme.colors.primary};
        }
    }
`;

const MenuIcon = styled.img`
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 12px;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
`;

const NotificationCount = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    margin-left: 4px;
`;



interface Props {
  element: {
    route: string;
    icon: string;
    title: string;
    hide?: any;
    notification?: number | null;
  };
}

const MenuItem: FunctionComponent<Props> = ({element}) => {
  const resolved = useResolvedPath(element.route);
  const match    = useMatch({path: resolved.pathname, end: true});
  const dispatch = useAppDispatch();


  return (
    <>
      {
        !element.hide && (
          <MenuItemLi>
            <MenuLink to={element.route} className={match ? 'link-active' : ''} onClick={() => dispatch(hideMenu())}>
              <MenuIcon src={element.icon}/>
              {element.title}
            </MenuLink>
            {
              element.notification ? (
                <>
                  {
                    element.notification > 0 && (<NotificationCount>{element.notification > 99 ? '99+' : element.notification}</NotificationCount>)
                  }
                </>
              ) : null
            }
          </MenuItemLi>
        )
      }
    </>
  );
};

export default MenuItem;
