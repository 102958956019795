import React, {useState, useEffect} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {generatePath} from 'react-router';
import styled from 'styled-components';
import Notification from '../api/entities/Notification';
import InfoEmptyContent from '../components/infobox/InfoEmptyContent';
import LoadingData from '../components/loading-data/LoadingData';
import NotificationsCard from '../components/notifications-card/NotificationsCard';
import Wrapper from '../components/wrapper/Wrapper';
import {DataNotificationsTypes} from '../data/DataNotificationsTypes';
import useApi from '../hooks/useApi';
import {useAppDispatch} from '../hooks/useAppDispatch';
import {useAppSelector} from '../hooks/useAppSelector';
import {ROUTE_USER, ROUTE_POINTS, ROUTE_BUILDING_SHOW} from '../routing/routes';
import {fetchNotificationTotal, fetchNotificationInfinite, fetchNotification} from '../store/NotificationReducer';


const CustomPageHeader = styled.div`
    max-width: 100vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 24px;
    height: ${props => props.theme.layout_part_size.mobile.page_header};
    position: relative;
    background-color: ${props => props.theme.colors.white};

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        position: fixed;
        top: 0;
        left: ${({theme}) => theme.layout_part_size.tablet.menu};
        width: calc(100vw - ${({theme}) => theme.layout_part_size.tablet.menu});
        height: ${props => props.theme.layout_part_size.tablet.page_header};
        align-items: flex-start;
        z-index: 10;
        padding: 4px 56px 4px 56px;
        box-shadow: 0 -4px 24px -8px rgb(42, 183, 248, 0.54);
    }
    @media screen and (min-width: ${({theme}) => theme.breakpoints.laptop_hd}) {
        width: calc(100vw - ${({theme}) => theme.layout_part_size.laptop_hd.menu});
        left: ${({theme}) => theme.layout_part_size.laptop_hd.menu};
    }
`;

const PageHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const PageTitle = styled.h1`
    font-size: ${props => props.theme.fonts.mobile.xl};
    font-weight: ${props => props.theme.weights.semiBold};
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: ${props => props.theme.fonts.tablet.md};
    }
`;

const CustomLink = styled.div`
    font-size: ${props => props.theme.fonts.mobile.sm};
    color: ${props => props.theme.colors.primary};
    text-decoration: underline;
    cursor: pointer;

    &.disabled {
        color: #ccc;
        pointer-events: none;
    }
`;


const NotificationsPage = () => {
  const Api = useApi();
  const notifications = useAppSelector(state => state.notification);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);


  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      setTimeout(() => {
        setLoading(false);
        dispatch(fetchNotification());
      }, 400);
    }
    return () => {
      isSubscribed = false;
    };

  }, [fetchNotification]);


  const checkAllNotification = () => {
    Api.Notification.Patch().then(() => {
      dispatch(fetchNotification());
      dispatch(fetchNotificationTotal());
    }).catch(error => console.error(error));
  };

  const handleNotificationRemove = (id: string) => {
    Api.Notification.Delete(id).then(() => {
      dispatch(fetchNotification());
      dispatch(fetchNotificationTotal());
    }).catch(error => console.error(error));
  };

  const handleNotificationCheck = (id: string) => {
    Api.Notification.Get(id).then(() => {
      dispatch(fetchNotification());
      dispatch(fetchNotificationTotal());
    }).catch(error => console.error(error));
  };

  const renderNotification = (notification: Notification, i: number) => {
    const type = notification.type;

    switch (true) {
      case (type.includes(DataNotificationsTypes.TYPE_CONSTRUCTION)):
        return <NotificationsCard route={generatePath(ROUTE_BUILDING_SHOW, {id: notification.data.construction})} notification={notification} handleNotificationRemove={handleNotificationRemove} handleNotificationCheck={handleNotificationCheck} key={i}/>;
      case (type.includes(DataNotificationsTypes.TYPE_EXTRAPOINTS) || type.includes(DataNotificationsTypes.TYPE_POINTS)):
        return <NotificationsCard route={ROUTE_POINTS} notification={notification} handleNotificationRemove={handleNotificationRemove} handleNotificationCheck={handleNotificationCheck} key={i}/>;
      case (type.includes(DataNotificationsTypes.TYPE_PROFILE)):
        return <NotificationsCard route={ROUTE_USER} notification={notification} handleNotificationRemove={handleNotificationRemove} handleNotificationCheck={handleNotificationCheck} key={i}/>;
      default:
        return <NotificationsCard notification={notification} handleNotificationRemove={handleNotificationRemove} handleNotificationCheck={handleNotificationCheck} key={i}/>;
    }
  };

  return (
    <div>
      <CustomPageHeader>
        <PageHeaderWrapper>
          <PageTitle>Powiadomienia</PageTitle>
          <CustomLink
            onClick={checkAllNotification}
            className={`${!(notifications.data && notifications.data.length > 0) && 'disabled'}`}
          >Oznacz jako przeczytane</CustomLink>
        </PageHeaderWrapper>
      </CustomPageHeader>

      <Wrapper>
        {
          loading ? (
            <LoadingData/>
          ) : (
            <>
              {
                notifications.data && (
                  <InfiniteScroll
                    dataLength={notifications.data.length}
                    next={() => dispatch(fetchNotificationInfinite(notifications.next_page))}
                    hasMore={true}
                    loader={null}
                  >
                    {
                      notifications.data.length > 0 ? notifications.data.map((notification: Notification, i: number) => renderNotification(notification, i)) : (
                        <InfoEmptyContent>Brak nowych powiadomień</InfoEmptyContent>
                      )
                    }
                  </InfiniteScroll>
                )
              }
            </>
          )
        }
      </Wrapper>
    </div>
  );
};

export default NotificationsPage;
