import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice}   from "@reduxjs/toolkit";


interface ParentRouteState {
  route: string;
}

const initialState: ParentRouteState = {
  route: "",
};

export const parentRouteSlice = createSlice({
  name:     "parentRoute",
  initialState,
  reducers: {
    saveRoute: (state, action: PayloadAction<string>) => {
      state.route = action.payload;
    },
  },
});

export const {saveRoute} = parentRouteSlice.actions;

export default parentRouteSlice.reducer;
