import {createSlice} from '@reduxjs/toolkit';


interface ModalHistoryState {
  status: boolean;
}

const initialState: ModalHistoryState = {
  status: false,
};

export const modalHistorySlice = createSlice({
  name: 'modalHistory',
  initialState,
  reducers: {
    showHistoryModal: state => {
      state.status = true;
    },
    hideHistoryModal: state => {
      state.status = false;
    },
  },
});

export const {
  showHistoryModal,
  hideHistoryModal,
} = modalHistorySlice.actions;

export default modalHistorySlice.reducer;
