import {FunctionComponent} from "react";
import React               from "react";
import styled              from "styled-components";
import EmptyIcon           from "../../assets/icons/empty.svg";
import Paragraph           from "../typography/Paragraph";


const InfoWrapper = styled.div`
    width: 100%;
    padding: 18px;
    text-align: center;
`;

const StyledParagraph = styled(Paragraph)`
    font-size: ${({theme}) => theme.fonts.mobile.md};
`;

const EmptyContentInfo = styled.img`
    width: 60px;
    height: 40px;
    margin-bottom: 12px;
`;

const InfoEmptyContent: FunctionComponent = ({children}) => {
  return (
    <InfoWrapper>
      <EmptyContentInfo src={EmptyIcon} alt=""/>
      <StyledParagraph>
        {
          children ? (
            <>{children}</>
          ) : (
            <>Tablica jest pusta</>
          )
        }
      </StyledParagraph>
    </InfoWrapper>
  );
};

export default InfoEmptyContent;
