import styled from "styled-components";


const HeaderSmall = styled.h3`
    font-size: ${props => props.theme.fonts.mobile.md};
    font-weight: ${props => props.theme.weights.semiBold};
    line-height: 1.2;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: ${props => props.theme.fonts.desktop.md};

    }
`;

export default HeaderSmall;
