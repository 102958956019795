import React, {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import Reward from '../../api/entities/Reward';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import useHtmlParser from '../../hooks/useHtmlParser';
import { showRewardModal } from '../../store/RewardReducer';
import ButtonPrimaryForward from '../buttons/ButtonPrimaryForward';
import Header from '../typography/Header';
import Paragraph from '../typography/Paragraph';


const CardContainer = styled.div`
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: 5px;
    overflow: hidden;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: calc(50% - 12px);
        &:not(:nth-child(2n)) {
            margin-right: 24px;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
        width: calc(33.33% - 8px);
        &:not(:nth-child(2n)) {
            margin-right: 0;
        }

        &:not(:nth-child(3n)) {
            margin-right: 12px;
        }
    }
`;

const ImageBox = styled.div`
    width: 100%;
    height: 240px;
    cursor: pointer;

    picture {
        width: 100%;
        height: 100%;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
`;

const CardWrapper = styled.div`
    padding: 12px 24px 18px 24px;
`;

const StyledParagraph = styled(Paragraph)`
    margin: 6px 0 6px 0;
    max-height: 0;
    transition: max-height 0.3s ease-out;
    overflow: hidden;
`;

const CardFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
`;

const PointsWrapper = styled.div`
    display: flex;
    align-items: center;

    span {
        display: block;
        margin-left: 8px;
        font-size: ${props => props.theme.fonts.mobile.sm};
        font-weight: ${props => props.theme.weights.light};
        max-width: 60px;

    }
`;

const StyledHeader = styled(Header)`
    margin: 12px 8px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
`;

const PointsBox = styled.div`
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.secondary};
    flex-shrink: 0;
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fonts.mobile.sm};
    display: flex;
    justify-content: center;
    align-items: center;
`;


interface Props {
  data: Reward;
  userPoints: string;
}

const RewardCard: FunctionComponent<Props> = ({ data, userPoints }) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <CardContainer>
      <StyledHeader onClick={() => setIsOpen(!isOpen)}>
        {data.name}
      </StyledHeader>

      {
        data.photos && data.photos.length > 0 && (
          <ImageBox onClick={() => setIsOpen(!isOpen)}>
            <picture>
              <img src={data.photos[0].asset_path} alt=""/>
            </picture>
          </ImageBox>
        )
      }
      <CardWrapper>

        <StyledParagraph style={isOpen ? {maxHeight: '500px'} : {}}>{useHtmlParser(data.description)}</StyledParagraph>
        <span className={"button"} style={{display: "block", fontSize: "14px", cursor: "pointer", margin: "6px auto 6px", textAlign: "center"}} onClick={() => setIsOpen(!isOpen)}
              dangerouslySetInnerHTML={{__html: isOpen ? '&laquo; zwiń opis' : 'pokaż opis &raquo;'}}></span>
        <CardFooter>
          <PointsWrapper>
            <PointsBox>{data.price}</PointsBox>
            <span>ilość punktów</span>
          </PointsWrapper>
          {
              (data.price <= userPoints) &&
              <ButtonPrimaryForward onClick={() => dispatch(showRewardModal(data.id))}>Wybierz</ButtonPrimaryForward>
          }
        </CardFooter>
      </CardWrapper>
    </CardContainer>
  );
};

export default RewardCard;
