import {createSlice} from "@reduxjs/toolkit";


interface LocationState {
  location: boolean;
}

const initialState: LocationState = {
  location: false,
};

export const locationSlice = createSlice({
  name:     "location",
  initialState,
  reducers: {
    set:   state => {
      state.location = true;
    },
    unSet: state => {
      state.location = false;
    },
  },
});

export const {set, unSet} = locationSlice.actions;

export default locationSlice.reducer;
