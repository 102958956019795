import {css}     from "styled-components";
import CloseIcon from "../../assets/icons/close.svg";


export const defaultModalStyles = css`
    position: fixed;
    top: 0;
    left: 0;
    max-width: 100vw;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    z-index: 100000000;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    opacity: 0;
    visibility: hidden;
    transition: .2s all ease;
`;

export const defaultModalWrapperStyles = css`
    background-color: ${props => props.theme.colors.white};
    padding: 48px 24px 36px 24px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
    width: 100%;
    min-height: 240px;
    transition: .2s all ease;

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        min-height: 0;
        border-radius: 5px;
        transform: none !important;
        overflow: auto;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 18px;
        left: 50%;
        transform: translateX(-50%);
        width: 30px;
        height: 5px;
        background-color: #cfcfcf;
        border-radius: 20px;
        z-index: 2;
        transition: .3s all ease;
    }

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        align-items: center;
        min-height: 0;
        border-radius: 5px;
        max-width: 600px;
        width: auto;
        padding: 48px;
        transform: translateY(100px);

        &:before {
            display: none;
        }
    }
`;

export const defaultModalCloseStyles = css`
    width: 14px;
    height: 14px;
    background-image: url(${CloseIcon});
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 18px;
    right: 18px;
    border: none;
    cursor: pointer;
`;
