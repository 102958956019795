import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import InputPassword from '../../form/controls/InputPassword';
import ButtonPrimaryForward from '../../components/buttons/ButtonPrimaryForward';
import FormSubmit from '../../form/internals/FormSubmit';
import useApi from '../../hooks/useApi';
import Entrypoint from '../../api/Entrypoint';
import NavbarAuth from '../../components/navbar/NavbarAuth';
import { DefaultPageBackButton } from '../../styles/DefaultPageBackButton';
import Header from '../../components/typography/Header';
import Wrapper from '../../components/wrapper/Wrapper';
import { ROUTE_LOGIN, ROUTE_RESET_PASSWORD_SUCCESS } from '../../routing/routes';

const StyledWrapper = styled(Wrapper)`
  max-width: 400px;
  padding: 12px 24px 48px 24px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 24px auto 36px auto;
`;

const StyledHeaderMain = styled(Header)`
  font-size: ${(props) => props.theme.fonts.mobile.xl};
  font-weight: ${(props) => props.theme.weights.semiBold};
  position: relative;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.tablet.lg};
  }
`;

const StyledForm = styled(Form)`
  margin-top: 24px;
`;

const BackButton = styled(Link)`
    ${DefaultPageBackButton};
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
    }
`;

const ResetPasswordPage: React.FC = () => {
    const { token: initialToken = '', email: initialEmail = '' } = useParams<{ token?: string; email?: string }>();
    const [email, setEmail] = useState<string>(initialEmail);
    const [token, setToken] = useState<string>(initialToken);
    const [emailSent, setEmailSent] = useState(false);
    const navigate = useNavigate();
    const Api = useApi();
  
    
    const handleSubmit = async (values: { email: string; token: string; password: string; password_confirmation: string }) => {
      try {
        const response = await fetch(Entrypoint.Api.Auth.ResetPassword, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });
  
        if (response.ok) {
          setEmailSent(true);
          navigate(ROUTE_RESET_PASSWORD_SUCCESS);
        } else {
          const data = await response.json();
          console.error(data.errors);
        }
      } catch (error) {
        console.error(error);
      }
    };

  return (
    <>
      <NavbarAuth />
      <StyledWrapper>
        <HeaderWrapper>
          <StyledHeaderMain>
            <BackButton to={ROUTE_LOGIN} />
            <span>Ustaw nowe hasło</span>
          </StyledHeaderMain>
        </HeaderWrapper>
        <Formik initialValues={{ email: email, token: token, password: '', password_confirmation: '' }} onSubmit={handleSubmit}>
          <StyledForm>
            <input type="hidden" name="email" value={email} />
            <input type="hidden" name="token" value={token} />
            <InputPassword label="Nowe hasło" name="password" required />
            <InputPassword label="Powtórz hasło" name="password_confirmation" required />
            <FormSubmit>
              <ButtonPrimaryForward type="submit">Przejdź dalej</ButtonPrimaryForward>
            </FormSubmit>
          </StyledForm>
        </Formik>
      </StyledWrapper>
    </>
  );
};

export default ResetPasswordPage;
