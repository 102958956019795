import {FunctionComponent} from "react";
import React               from "react";
import {Link}              from "react-router-dom";
import styled              from "styled-components";
import {DefaultLinkStyles} from "../../styles/DefaultLinkStyles";


const StyledLink = styled(Link)`
    ${DefaultLinkStyles};
`;

const StyledHref = styled.a`
    ${DefaultLinkStyles};
`;

const StyledSpan = styled.span`
    ${DefaultLinkStyles};

`;

interface Props {
  to?: string;
  href?: string;
}

const CustomLink: FunctionComponent<Props> = (props) => {

  const renderLink = (props: any) => {
    if (props.to) {
      return <StyledLink to={props.to} {...props}>{props.children}</StyledLink>;
    } else if (props.href) {
      return <StyledHref to={props.href} {...props}>{props.children}</StyledHref>;
    } else {
      return <StyledSpan>{props.children}</StyledSpan>;
    }
  };

  return (
    renderLink(props)
  );
};

export default CustomLink;
