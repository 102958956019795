import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import UserFillRequest from '../../api/request/user/UserFillRequest';
import ButtonPrimaryForward from '../../components/buttons/ButtonPrimaryForward';
import NavbarAuth from '../../components/navbar/NavbarAuth';
import Header from '../../components/typography/Header';
import Wrapper from '../../components/wrapper/Wrapper';
import InputText from '../../form/controls/InputText';
import FormSubmit from '../../form/internals/FormSubmit';
import useApi from '../../hooks/useApi';
import { useAppSelector } from '../../hooks/useAppSelector';
import { ROUTE_ACCOUNT_ACCEPTANCE, ROUTE_HOME } from '../../routing/routes';
import InputSelect from '../../form/controls/InputSelect';


const UserWrapper = styled(Wrapper)`
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_small}) {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
`;

const FormPartInputsWrapper = styled.div`
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_big}) {
        display: flex;
        flex-wrap: wrap;

        &:not(:last-child) {
            margin-bottom: 18px;
            padding-bottom: 18px;
            border-bottom: 1px solid ${({ theme }) => theme.colors.border};
        }

        & > div {
            width: calc(50% - 12px);

            &:not(:last-child) {
                margin-right: 12px;
            }
        }

        &.full-width {
            & > div {
                width: 100%;
            }
        }
    }
`;

const HeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin: 24px auto 36px auto;
`;

const StyledHeader = styled(Header)`
    font-size: ${props => props.theme.fonts.mobile.xl};
    font-weight: ${props => props.theme.weights.semiBold};
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: ${props => props.theme.fonts.tablet.lg};
    }
`;


const RegisterNotCompleted = () => {
  const Api = useApi();
  const user = useAppSelector(state => state.newUser.user);
  const navigate = useNavigate();

  if (user && user.filled_at) {
    return <Navigate to={ROUTE_HOME}/>;
  }

  const roles = [
    {
      label: 'Brukarz',
      value: 'Paver',
    },
    {
      label: 'Projektant',
      value: 'Designer',
    },
    {
      label: 'Inny',
      value: 'Other',
    },
  ];

  const initialValues: UserFillRequest = {
    name: user?.name || '',
    street: '',
    apartment_nr: '',
    building_nr: '',
    postcode: '',
    city: '',
    phone: '',
    type: '',
  };

  return (
    <>
      <NavbarAuth/>
      <UserWrapper>
        <HeaderWrapper>
          <StyledHeader>Uzupełnij swoje dane</StyledHeader>
        </HeaderWrapper>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values: UserFillRequest, actions) => {
            Api.User.Fill(
              values,
            ).then(() => {
              navigate(ROUTE_ACCOUNT_ACCEPTANCE);
            }).catch(error => {
              actions.setErrors(error.response.data.errors);
            });
          }}
        >
          <Form>
            <FormPartInputsWrapper>
              <InputSelect label="Zarejestruj się jako(wybierz z listy):" name="type" options={roles} required/>
            </FormPartInputsWrapper>
            <FormPartInputsWrapper>
              <InputText
                label="Imię i nazwisko"
                name="name"
                required
              />
            </FormPartInputsWrapper>
            <FormPartInputsWrapper>
              <InputText label="Ulica" name="street" required/>
              <InputText label="Numer domu" name="building_nr" required/>
              <InputText label="Numer mieszkania" name="apartment_nr"/>
            </FormPartInputsWrapper>
            <FormPartInputsWrapper>
              <InputText label="Kod pocztowy" name="postcode" required/>
              <InputText label="Miasto/Miejscowość" name="city" required/>
            </FormPartInputsWrapper>
            <FormPartInputsWrapper>
              <InputText label="Numer telefonu" name="phone" required/>
            </FormPartInputsWrapper>
            <FormSubmit>
              <ButtonPrimaryForward type="submit">Zarejestruj się</ButtonPrimaryForward>
            </FormSubmit>
          </Form>
        </Formik>
      </UserWrapper>
    </>
  );
};

export default RegisterNotCompleted;
