import {Navigate, Outlet, useLocation} from "react-router-dom";
import {ROUTE_LOGIN}                   from "../../routing/routes";


const RequireAuth = () => {
  const location = useLocation();

  return (
    localStorage.getItem("access_token") ? (
      <Outlet/>
    ) : (
      <Navigate to={ROUTE_LOGIN} state={{from: location}} replace/>
    )
  );
};

export default RequireAuth;
