import { enableBodyScroll } from "body-scroll-lock";
import { Form, Formik } from "formik";
import { useRef } from "react";
import { useEffect } from "react";
import { FunctionComponent } from "react";
import React from "react";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";
import InputDate from "../../form/controls/InputDate";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import useClickOutside from "../../hooks/useClickOutside";
import useKeyPress from "../../hooks/useKeyPress";
import { setFiltersInfo } from "../../store/BuildingReducer";
import { fetchBuildingSearch } from "../../store/BuildingReducer";
import { fetchBuilding } from "../../store/BuildingReducer";
import { enableGlobalSwipe } from "../../store/ModalReducer";
import { blockGlobalSwipe } from "../../store/ModalReducer";
import ButtonPrimaryForward from "../buttons/ButtonPrimaryForward";
import ButtonSecondaryClose from "../buttons/ButtonSecondaryClose";
import HeaderSmall from "../typography/HeaderSmall";
import { defaultModalCloseStyles } from "./defaultModalStyles";
import { defaultModalStyles } from "./defaultModalStyles";
import { defaultModalWrapperStyles } from "./defaultModalStyles";
import InputSelect from "../../form/controls/InputSelect";
import { STATUS_TYPE } from "../../types/StatusType";

const ModalWrapper = styled.div`
  ${defaultModalWrapperStyles};
`;

const ModalContainer = styled.div`
  ${defaultModalStyles}
  &.active {
    opacity: 1;
    visibility: visible;

    ${ModalWrapper} {
      transform: translateY(0);
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_small}) {
    align-items: center;
    padding: 48px;
  }
`;

const CloseModal = styled.button`
  ${defaultModalCloseStyles};
`;

const FiltersHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 12px;
  text-align: center;
  margin-top: 24px;
  display: flex;
  justify-content: center;

  button {
    height: 42px;
    margin: 0 4px;
  }
`;

const StyledHeaderSmall = styled(HeaderSmall)`
  font-size: ${({ theme }) => theme.fonts.mobile.lg};
  margin-bottom: 24px;
`;

const DateInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DateInputItem = styled.div`
  width: calc(50% - 8px);
  margin-bottom: 8px;
`;

interface Props {
  activeClass?: boolean;
  closeModal: () => void;
}

const ModalBuildingFilter: FunctionComponent<Props> = ({ activeClass, closeModal }) => {
  const ref = useRef<any>();
  const refWrapper = useRef<any>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (activeClass) {
      // disableBodyScroll(ref.current);
      dispatch(blockGlobalSwipe());
    }
  }, [activeClass, dispatch]);

  const refPassThrough = (el: any) => {
    swipeHandlers.ref(el);
    ref.current = el;
  };

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => {
      if (window.innerWidth < 576) {
        handleClose();
      }
    },
  });

  const handleClose = () => {
    closeModal();
    enableBodyScroll(ref.current);
    dispatch(enableGlobalSwipe());
  };

  useClickOutside(refWrapper, handleClose);

  useKeyPress(handleClose);

  const initialValues = {
    start_date_min: "",
    start_date_max: "",
    end_date_min: "",
    end_date_max: "",
    statuses: [],
  };

  return (
    <ModalContainer {...swipeHandlers} ref={refPassThrough} className={activeClass ? "active" : ""}>
      <ModalWrapper ref={refWrapper}>
        <CloseModal onClick={handleClose} />
        <FiltersHeader>
          <StyledHeaderSmall>Filtry</StyledHeaderSmall>
        </FiltersHeader>

        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            handleClose();
            let filters = {
              "filters[start_date][min]": values.start_date_min,
              "filters[start_date][max]": values.start_date_max,
              "filters[end_date][min]": values.end_date_min,
              "filters[end_date][max]": values.end_date_max,
              statuses: values.statuses.map(
                (value: string) => value[0] + value.slice(1).toLowerCase()
              ),
            };
            dispatch(setFiltersInfo(true));
            dispatch(fetchBuildingSearch(filters));
          }}>
          {(formProps) => {
            return (
              <Form>
                <InputSelect
                  multiple
                  name="statuses"
                  label="Status budowy"
                  options={Object.keys(STATUS_TYPE)
                    .filter((status) => STATUS_TYPE[status] !== STATUS_TYPE.OFFERED)
                    .map((key: string) => {
                      return {
                        value: key,
                        label: STATUS_TYPE[key],
                      };
                    })}
                />
                <DateInputWrapper>
                  <DateInputItem>
                    <InputDate name="start_date_min" label="Data rozpoczęcia od:" />
                  </DateInputItem>
                  <DateInputItem>
                    <InputDate name="start_date_max" label="Data rozpoczęcia do:" />
                  </DateInputItem>
                </DateInputWrapper>
                <DateInputWrapper>
                  <DateInputItem>
                    <InputDate name="end_date_min" label="Data zakończenia od:" />
                  </DateInputItem>
                  <DateInputItem>
                    <InputDate name="end_date_max" label="Data zakończenia do:" />
                  </DateInputItem>
                </DateInputWrapper>
                <ButtonWrapper>
                  <ButtonSecondaryClose
                    type="button"
                    onClick={() => {
                      dispatch(fetchBuilding()).then(() => {
                        handleClose();
                        formProps.resetForm();
                        dispatch(setFiltersInfo(false));
                      });
                    }}>
                    Wyczyść filtry
                  </ButtonSecondaryClose>
                  <ButtonPrimaryForward type="submit">Filtruj wyniki</ButtonPrimaryForward>
                </ButtonWrapper>
              </Form>
            );
          }}
        </Formik>
      </ModalWrapper>
    </ModalContainer>
  );
};

export default ModalBuildingFilter;
