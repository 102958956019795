import styled   from "styled-components";
import SendIcon from "../../assets/icons/send.svg";


export const ButtonSend = styled.button`
    width: 80px;
    height: 30px;
    background-image: url(${SendIcon});
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 30px;
    background-color: ${props => props.theme.colors.primary};
    border: none;
`;


ButtonSend.defaultProps = {
  type: "button",
};
