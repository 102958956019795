import React            from "react";
import {useNavigate}    from "react-router";
import styled           from "styled-components";
import LogoutIcon       from "../../assets/icons/logout.svg";
import useApi           from "../../hooks/useApi";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {ROUTE_LOGIN}    from "../../routing/routes";
import {hideMenu}       from "../../store/MenuReducer";


const Button = styled.button`
    color: ${props => props.theme.colors.secondary};
    font-weight: ${props => props.theme.weights.medium};
    font-size: ${props => props.theme.fonts.mobile.lg};
    display: inline-flex;
    align-items: center;
    background-color: transparent;
    border: none;

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: ${props => props.theme.fonts.mobile.md};
    }
`;

const MenuIcon = styled.img`
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 12px;
    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
`;

const MenuLogout = () => {
  const Api      = useApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const element  = {
    route: "/logout",
    icon:  LogoutIcon,
    title: "Wyloguj",
  };

  const logout = () => {
    Api.SignOut.SignOut().then(() => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("expires_in");
      dispatch(hideMenu());

      navigate(ROUTE_LOGIN);
    }).catch(error => console.error(error));
  };

  return (
    <ul>
      <MenuIcon src={element.icon}/>
      <Button type="button" onClick={() => logout()}>Wyloguj</Button>
    </ul>
  );
};

export default MenuLogout;
