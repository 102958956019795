import React, {useState, useEffect} from 'react';
import {matchRoutes} from 'react-router';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import AddIcon from '../../assets/icons/add.svg';
import EnvelopeAddIcon from '../../assets/icons/envelope-add.svg';
import MapIcon from '../../assets/icons/map.svg';
import {useAppDispatch} from '../../hooks/useAppDispatch';
import {ROUTE_THREAD, ROUTE_HOME} from '../../routing/routes';
import {showBuildingForm} from '../../store/BuildingForm';
import {clearMessage} from '../../store/MessageReducer';
import {showModal} from '../../store/ThreadReducer';
import {showModalBuildingMap} from '../../store/ModalBuildingMapReducer';


const ButtonWrapper = styled.button<{ isMobile: boolean }>`
    width: 52px;
    height: 52px;
    background-color: ${({theme}) => theme.colors.primary};
    border-radius: 100%;
    position: fixed;
    bottom: ${props => props.isMobile ? '100px' : '84px'};
    right: 18px;
    z-index: 10;
    border: none;
    box-shadow: 0 0 10px 0 rgba(1, 173, 249, 0.55);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s all ease;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;

    &.active {
        opacity: 1;
        visibility: visible;
    }

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 50px;
        height: 50px;
    }
`;

const IconWrapper = styled.div`
    width: 26px;
    height: 26px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        opacity: 0;
        transition: .2s all ease;
    }

    &.button-new-building {
        z-index: 2;

        .new-building-image {
            opacity: 1;
        }
    }

    &.button-new-thread {
        .new-thread-image {
            opacity: 1;
            transform: translate(-50%, -50%) rotate(0deg);
        }
    }
`;

const MapButton = styled.button<{ isMobile: boolean }>`
    width: 52px;
    height: 52px;
    background-color: #fff;
    border-radius: 100%;
    position: fixed;
    bottom: ${props => props.isMobile ? '164px' : '148px'};
    right: 18px;
    z-index: 10;
    border: none;
    box-shadow: 0 0 10px 0 rgba(1, 173, 249, 0.55);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s all ease;
    opacity: 1;
    visibility: visible;
    cursor: pointer;

    img {
        width: 22px;
        height: 22px;
    }
`;

const ActionButton = () => {
  const location = useLocation();
  const match = matchRoutes([{path: ROUTE_HOME}, {path: ROUTE_THREAD}], location.pathname);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsMobile(true);
    }
  }, []);

  const handleClick = () => {
    if (match && match[0].pathname === ROUTE_THREAD) {
      dispatch(showModal());
      dispatch(clearMessage());
    } else {
      dispatch(showBuildingForm(0));
    }
  };

  return (
    <>
      {
        match && match[0].pathname === ROUTE_HOME && (
          <MapButton isMobile={isMobile} onClick={() => dispatch(showModalBuildingMap())}>
            <img src={MapIcon} alt=""/>
          </MapButton>
        )
      }
      <ButtonWrapper isMobile={isMobile} className={match ? 'active' : ''}>

        <IconWrapper
          onClick={handleClick}
          className={(match && match[0].pathname === ROUTE_THREAD) ? 'button-new-thread' : 'button-new-building'}
        >
          <img className="new-building-image" src={AddIcon} alt=""/>
          <img className="new-thread-image " src={EnvelopeAddIcon} alt=""/>
        </IconWrapper>
      </ButtonWrapper>
    </>

  );
};

export default ActionButton;
