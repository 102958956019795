import {useEffect}   from "react";
import {useState}    from "react";
import React         from "react";
import {Outlet}      from "react-router-dom";
import styled        from "styled-components";
import ButtonPrimary from "../buttons/ButtonPrimary";
import HeaderSmall   from "../typography/HeaderSmall";


const OfflineModal = styled.div`
    width: 100vw;
    height: 100vh;
    z-index: 100000;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
`;

const OfflineWrapper = styled.div`
    background-color: #fff;
    max-width: 500px;
    width: 100%;
    padding: 24px;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;

    span {

    }
`;

const Description = styled.div`
    margin: 12px 0 18px 0;
    display: block;
    width: 100%;
    margin-top: 4px;
    font-size: 12px;
    color: #ccc;
`;

const NetworkDetector = () => {
  const [isOffline, setIsOffline] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("offline", () => setIsOffline(true));
    return () => {
      window.removeEventListener("offline", () => setIsOffline(true));
    };
  }, []);

  const handleClick = () => {
    window.addEventListener("online", () => setIsOffline(false));
  };

  return (
    <>
      {
        isOffline && (
          <OfflineModal>
            <OfflineWrapper>
              <HeaderSmall>Brak dostępu do internetu!</HeaderSmall>
              <Description>Spróbuj ponownie później</Description>
              <ButtonPrimary onClick={handleClick}>Odśwież aplikację</ButtonPrimary>
            </OfflineWrapper>
          </OfflineModal>
        )
      }
      <Outlet/>
    </>
  );
};

export default NetworkDetector;

