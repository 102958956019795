import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';
import SignInRequest from '../../api/request/auth/SignInRequest';
import HeroImage from '../../assets/icons/bruczek.svg';
import ButtonPrimaryForward from '../../components/buttons/ButtonPrimaryForward';
import NavbarAuth from '../../components/navbar/NavbarAuth';
import Header from '../../components/typography/Header';
import Paragraph from '../../components/typography/Paragraph';
import Wrapper from '../../components/wrapper/Wrapper';
import InputPassword from '../../form/controls/InputPassword';
import FormSubmit from '../../form/internals/FormSubmit';
import useApi from '../../hooks/useApi';
import { ROUTE_FORGOT_PASSWORD, ROUTE_HOME, ROUTE_REGISTER } from '../../routing/routes';
import InputEmail from '../../form/controls/InputEmial';
import FacebookLoginButton from '../../components/facebook-login-button/FacebookLoginButton';


const LoginSocialsWrapper = styled.div`
    margin: 36px 0;
    text-align: center;
`;

const HeaderWrapper = styled.div`
    width: 100%;
    text-align: center;
    margin: 24px auto 36px auto;
`;

const StyledHeader = styled(Header)`
    font-size: ${props => props.theme.fonts.mobile.xl};
    font-weight: ${props => props.theme.weights.semiBold};
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: ${props => props.theme.fonts.tablet.lg};
    }
`;

const StyledWrapper = styled(Wrapper)`
    max-width: 400px;
    padding: 12px 24px 48px 24px;
`;

const ImageWrapper = styled.div`
    width: 70px;
    height: 70px;
    margin: 12px auto 18px auto;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @media screen and (min-width: 360px) {
        width: 100px;
        height: 100px;
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;

    & > * {
        margin: 0 4px;
    }
`;

const StyledParagraph = styled(Paragraph)`
    width: 100%;
    text-align: center;
    margin-bottom: 18px;
`;

const ForgotPasswordLinkWrapper = styled.div`
    text-align: center;
    margin: 36px 0;
`;

const RegisterLinkWrapper = styled.div`
    text-align: center;
`;

export const AuthError = styled.div`
    margin: 24px 0;
    font-size: ${({ theme }) => theme.fonts.mobile.sm};
    color: ${({ theme }) => theme.colors.danger};
    background-color: ${({ theme }) => theme.colors.dangerLight};
    padding: 12px;
    border-radius: 5px;
`;

const LoginPage: React.FunctionComponent = () => {
  const Api = useApi();
  const [formError, setFormError] = useState<string>();
  const navigate = useNavigate();
  const location = useLocation();
  //@ts-ignore
  const from = location.state?.from?.pathname || ROUTE_HOME;

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      navigate(ROUTE_HOME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues: SignInRequest = {
    email: '',
    password: '',
  };

  return (
    <>
      <NavbarAuth/>
      <StyledWrapper>
        <HeaderWrapper>
          <StyledHeader>Zaloguj się</StyledHeader>
        </HeaderWrapper>
        <ImageWrapper>
          <img src={HeroImage} alt=""/>
        </ImageWrapper>
        {formError && (
          <AuthError>
            {formError}
          </AuthError>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={(values: SignInRequest) => {
            Api.SignIn.SignIn({
              grant_type: process.env.REACT_APP_OAUTH_GRAD_TYPE,
              client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
              client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
              username: values.email,
              password: values.password,
              scope: process.env.REACT_APP_OAUTH_SCOPE,
            }).then(({ data }) => {
              localStorage.setItem('access_token', data.access_token);
              localStorage.setItem('refresh_token', data.refresh_token);
              localStorage.setItem('expires_in', data.expires_in);

              navigate(from, { replace: true });

            }).catch(error => {
              console.error(error);
              setFormError(error.response.data.error_description);
            });
          }}
        >
          <Form>
            <InputEmail label="Adres email" name="email" required/>
            <InputPassword label="Hasło" name="password" required/>
            <FormSubmit>
              <ButtonPrimaryForward type="submit">Zaloguj się</ButtonPrimaryForward>
            </FormSubmit>
          </Form>
        </Formik>
        <LoginSocialsWrapper>
          <StyledParagraph>
            Zaloguj się poprzez:
          </StyledParagraph>
          <ButtonsWrapper>
            <FacebookLoginButton/>
          </ButtonsWrapper>
        </LoginSocialsWrapper>
        <ForgotPasswordLinkWrapper>
          <StyledParagraph>
            Zapomniałeś hasła? Ustaw nowe:
          </StyledParagraph>
          <ButtonPrimaryForward to={ROUTE_FORGOT_PASSWORD}>Resetuj hasło</ButtonPrimaryForward>
        </ForgotPasswordLinkWrapper>
        <RegisterLinkWrapper>
          <StyledParagraph>
            Nie masz konta? Zarejestruj się:
          </StyledParagraph>
          <ButtonPrimaryForward to={ROUTE_REGISTER}>Zarejestruj się</ButtonPrimaryForward>
        </RegisterLinkWrapper>
      </StyledWrapper>
    </>
  );
};

export default LoginPage;
