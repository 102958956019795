import styled from "styled-components";


const WrapperInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - ${({theme}) => theme.layout_part_size.mobile.navbar} - ${({theme}) => theme.layout_part_size.mobile.menu_bottom});
    padding: 24px;
`;

export default WrapperInfo;
