import {FunctionComponent} from "react";
import React               from "react";
import styled              from "styled-components";
import PenImage            from "../../assets/images/pen.png";
import ButtonPrimary       from "../buttons/ButtonPrimary";
import Header              from "../typography/Header";
import Paragraph           from "../typography/Paragraph";
import {defaultInfoHeader}    from "./defaultStyles";
import {defaultInfoWrapper}   from "./defaultStyles";
import {defaultInfoContainer} from "./defaultStyles";


const Container = styled.div`
    ${defaultInfoContainer}
`;

const Wrapper = styled.div`
    ${defaultInfoWrapper};
`;

const StyledHeader = styled(Header)`
    ${defaultInfoHeader}
`;

const StyledParagraph = styled(Paragraph)`
    margin: 12px 0 18px 0;
`;


interface Props {
  handleClose: () => void;
}

const InfoRewardSuccess: FunctionComponent<Props> = ({handleClose}) => {

  return (
    <Container>
      <Wrapper>
        <img src={PenImage} alt=""/>
        <StyledHeader>Gratulacje</StyledHeader>
        <StyledParagraph>Nagroda została wybrana, nasz zespół skontaktuje się z Tobą w&nbsp;sprawie jej przekazania.</StyledParagraph>
        <ButtonPrimary onClick={() => handleClose()}>Zamknij</ButtonPrimary>
      </Wrapper>
    </Container>
  );
};

export default InfoRewardSuccess;
