import React            from "react";
import styled           from "styled-components";
import CloseIcon        from "../../assets/icons/close-white.svg";
import useApi           from "../../hooks/useApi";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {fetchUser}      from "../../store/UserReducerNew";


const DeleteAvatarButton = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #ccc;
    border: 2px solid #fff;
    background-image: url(${CloseIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px;
    cursor: pointer;
`;

const UserAvatarDeleteForm = () => {
  const Api      = useApi();
  const dispatch = useAppDispatch();

  return (
    <DeleteAvatarButton onClick={() => Api.Profile.DeleteAvatar().then(() => {
      Api.User.Get().then(() => {
        dispatch(fetchUser());
      });
    }).catch(error => console.error(error))}/>
  );
};

export default UserAvatarDeleteForm;
