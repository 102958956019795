import {createSlice} from "@reduxjs/toolkit";


interface ModalErrorAppState {
  status: boolean;
}

const initialState: ModalErrorAppState = {
  status: false,
};

export const modalErrorAppSlice = createSlice({
  name:     "modalErrorApp",
  initialState,
  reducers: {
    showErrorAppModal: state => {
      state.status = true;
    },
  },
});

export const {
               showErrorAppModal,
             } = modalErrorAppSlice.actions;

export default modalErrorAppSlice.reducer;
